<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <b-row>
          <b-col md="12" class="space-top-30">
            <h1 class="font-weight-bold">Privacy Policy</h1>
          </b-col>
          <b-col md="12">
            <p>
              At CypherMines Corporation(“the Company”), one of our main priorities is the privacy of our LikeWallet
              (“Service”) users. This privacy policy together with our
              <router-link to="/terms-and-conditions">User's Terms and Conditions</router-link>
              of this application and any other documents we mention in this policy is meant to inform you on which of
              your personal data we collect, use, disclose, share or otherwise process when you are using this
              application. Please read this policy carefully to understand our views and practices on how we protect
              your personal data.
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">Personal Data</h5>
          </b-col>

          <b-col md="12">
            <p>
              Personal data is the information related to a natural person and which can be used to identify that person
              such as name, email address, telephone number, social media usernames, your device information, and
              transaction.
              This means that when you use our services, we will collect and/or access certain information about you
              which can identify you directly (name and telephone number for this regards) or may collect your indirect
              data.
              Information and/or Personal Data which may be collected, stored, processed, transferred, used and
              disclosed by LikeWallet includes but is not limited to information and/or data that can be used on its own
              or with other information to, directly or indirectly, identify, contact, or locate a single person, or to
              identify an individual in context.
            </p>
          </b-col>

          <b-col md="12">
            <p>
              By using the Services, it is considered that you have read and accepted this Privacy Policy.
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">Basis of the Collection, Use and Disclosure of your Personal Data and the
              Purposes of such Processing</h5>
          </b-col>

          <b-col md="12">
            <p>
              Type of Personal Data Collected,
            </p>
          </b-col>

          <b-col md="12">
            <p class="space-left-30">• name, surname;</p>
            <p class="space-left-30">• telephone number;</p>
            <p class="space-left-30">• official identification document (at KYC procedure);</p>
            <p class="space-left-30">• chat channels ID (optional);</p>
            <p class="space-left-30">• email address (optional);</p>
            <p class="space-left-30">• bank account information (optional).</p>
          </b-col>


          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">Use of Data</h5>
          </b-col>

          <b-col md="12">
            <p>
              The Company may use your Personal Data to supply you with the Company Services required, to verify you and
              to contact you when required, in this regards- name, surname and telephone number are to be utilized.
            </p>
            <p>
              The Company may just access your phone data to accommodate you with more convenient service. The Company
              also may utilize the Personal Data provided to help in administering, supporting and improving the
              business, as per the following ways:
            </p>
          </b-col>

          <b-col md="12">
            <p>
              1. to provide, maintain, deliver and improve the Company Services and obtaining your views of the Company
              Services
            </p>
            <p>
              2. to send information about products and services
            </p>
            <p>
              3. to carry out any other purpose for which the information was collected
            </p>
            <p>
              4. to comply with the laws, regulations, rules, codes of practice or guidelines issued by authorities or
              regulatory agencies
              If you want to stop receiving information as per the above, you can opt out at any time by contacting us
              at the email address indicated in this Privacy Policy. At all times, access to the User’s Personal Data by
              the Company’s own staff and third-party intermediaries, will be restricted to the bare minimum required
              for the Company to perform our business and the provision of the Company Services
            </p>
          </b-col>


          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">Permission to data operating and Data processing</h5>
          </b-col>

          <b-col md="12">
            <p class="space-left-30">
              • We are asking for CAMERA and STORAGE permissions to capture user's images and pick images from device
              gallery for better user experience.
            </p>
            <p class="space-left-30">
              • We are using the phone number that user registers, for the purpose of user verification via one-time
              password (security code). (One-time password will be sent by Nexmo Inc.)
            </p>
            <p class="space-left-30">
              • We are storing your information in Firebase, cloud storage.
            </p>
            <p class="space-left-30">
              • Our KYC service provider; Sumsub (Sum and Substance Ltd.), collects and processes data processes
              personal data under Article 28 of the EU GDPR and the UK GDPR.
            </p>
            <p class="space-left-30">
              • In the Profile section, Facebook ID and Line ID are the optional data which we may use as optional
              contact channel(s) with the user.
              We may send you LikeWallet activity information that might interest you to your registered messaging channel(s) . You can opt-out of receiving future marketing materials at any time. You can unsubscribe by following the instructions contained in the messages you receive. In cast that you opt-out of receiving these messages, we reserve the right to send you certain notification necessary to the services we provide. We strongly not recommend to opt-out of receiving those communications as they are important for a user to know.
            </p>


            <p class="space-left-30">
              • On certain Operating System(s), LikeWallet may access user's device contact list upon user's permission.
              We do not store/collect data from your device contact list. This function is to show phone number of the
              registered LikeWallet users stored in your device contact list for user's convenience.
            </p>
            <p class="space-left-30">
              • Finally, we never use or share the personally identifiable information provided to us online in ways
              unrelated to the ones described above, we also providing you an opportunity to opt out or otherwise
              prohibit such unrelated uses.
            </p>
          </b-col>


          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">The Disclosure of Your Personal Data to Third Parties</h5>
          </b-col>

          <b-col md="12">
            <p>
              For the processing pursuant to the purposes under abovementioned legal basis. The Company may collect from
              and/or use, send, transfer, process and/or disclose the Personal Data to the following persons:
            </p>
            <p class="space-left-30">
              • Juristic persons having control over the Company or under the control of the Company including other
              companies under the same control of the Company, or other juristic persons or persons in which the Company
              is a contractual party, or having the legal relationship with the Company, including other service
              providers having power in making decision to take any action related to your Personal Data both within and
              outside the country such as external service providers, cloud service providers or other business partners
              of the Company. In this regard, the Company shall ensure that those service providers will process your
              Personal Data in accordance with this Privacy Policy and the applicable laws.
            </p>
            <p class="space-left-30">
              • The auditors, external examiners of the Company, government authorities, transferee of the right of
              claim, and any other persons or juristic persons that the Company is required to transfer the Personal
              Data as necessary to comply with the laws.
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">Consent</h5>
          </b-col>

          <b-col md="12">
            <p>
              Your Personal Data may be processed only if you have unambiguously given your consent or, the processing
              is necessary for the performance of the Company Services you request or, for compliance with legal
              obligations as in the case of Anti Money Laundering and Terrorist Financing Regulations
              (AML/TF) or, pursuant the public interest or, a third party with whom the Personal Data is disclosed
              except where such interest is overridden by the interest to protect the fundamental rights and freedoms
              that arise from the right of privacy.
              As an individual you may exercise your right to access your Personal Data held about you by the company by
              contacting us at the address below.
            </p>
          </b-col>


          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">Right to be forgotten</h5>
          </b-col>

          <b-col md="12">
            <p>
              Upon request, you have the right to have your Personal Data erased by the Company. The Company acting
              reasonably will take all necessary actions , including to inform third-party data Processors to comply
              with the request unless your Personal Data needs to be retained to comply with legal obligations or court
              orders.
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">Security</h5>
          </b-col>

          <b-col md="12">
            <p>
              The Company acknowledges and realizes the importance of your Personal Data. Thus, the Company has improved
              and developed the security systems for your Personal Data in corresponding to the laws, and up-to-date
              with safety in accordance with the international standard at all time. The Company will proceed at its
              full effort to comply with this Privacy Policy, and will urge the Company’s personnel, including data
              processors, who are entitled to access the Personal Data or has legal duty, to keep and respect your
              Personal Data security.
              In case the Company is necessary to transfer any of your Personal Data to foreign country, the Company
              will apply the standard as prescribed by the laws on personal data protection. Additionally, the Company
              will not transfer your Personal Data to the destination country that has no personal data protection
              standard sufficiently in accordance with the criteria as prescribed by the laws, except that you consent
              the Company to send or transfer your Personal Data to such destination country. However, in case of
              computer espionage resulting that your Personal Data has been stolen, whether by ways of hacking,
              stealing, copying or destroying database, destroying personal code (password mining) or any other methods,
              without the Company’s fault, in such circumstance, the Company will have the right to disclaim any
              liability resulting from such act.
            </p>
          </b-col>


          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">Children’s Privacy</h5>
          </b-col>

          <b-col md="12">
            <p>
              LikeWallet is not intended for children under the age of 20. We do not knowingly or intentionally collect
              information about children who are under 20 years of age.
              At under the age of 20, you may not use the app in any way.
            </p>
          </b-col>


          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">Language</h5>
          </b-col>

          <b-col md="12">
            <p>
              The translation of this Privacy Policy, whether in any language whatsoever, is for your convenience only,
              without any intention to change this Privacy Policy. In case of any discrepancy between English version
              and any other languages version, the English version shall prevail.
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">Data Subject’s Support</h5>
          </b-col>

          <b-col md="12">
            <p>
              The Company shall provide its Users with user support through an In-App chat with an agent. To get into
              user’s account and chat channel, user must log in into LikeWallet with registered telephone number which
              collected on our database storage.
            </p>
          </b-col>


          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">Data Retention</h5>
          </b-col>

          <b-col md="12">
            <p>
              The Company will retain your Personal Data and that of beneficiaries of the Company Services for a
              reasonable period or as long as the law requires. The Company will retain your data as long as needed for
              the provision of the Company Services.
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">Response to Personal Data Breaches</h5>
          </b-col>

          <b-col md="12">
            <p>
              When the Company learns of a suspected or actual Personal Data breach, the Company shall perform an
              internal investigation and take appropriate remedial measures in a timely manner. Where there is any risk
              to the rights and freedoms of Data Subjects, the Company will notify the relevant Supervisory Authorities
              without undue delay and, when possible, within 72 hours from when it learns of such breach. Data Subjects
              will be informed when the Personal Data breach is of a “high” level.
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">Governing Law</h5>
          </b-col>

          <b-col md="12">
            <p>
              By using the Services, or otherwise providing information to the Company, you consent to the processing
              and transfer of information in accordance with Cayman Islands laws.
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">Validity</h5>
          </b-col>

          <b-col md="12">
            <p>
              This document was updated on 16 June 2022 and is effective from that date.
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">Your rights</h5>
          </b-col>

          <b-col md="12">
            <p>
              You have the right to make a request to access or delete any of your personal data that we might possess.
              You can make a request by contacting our Data Protection Officer at the address below. You may be required
              to provide additional information to authenticate your request.
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">Final Provisions</h5>
          </b-col>

          <b-col md="12">
            <p>
              You may request the Company to access your information and Personal Data at any time. The Company shall be
              responsible for and be able to demonstrate compliance with the principles outlined in this Privacy Policy.
              The Company may at its own discretion assign or transfer this Privacy Policy and your related information
              to any person or entity that acquires or is merged with us without restriction.
              Any claims, questions, comments and requests regarding this Privacy Policy should be addressed to the
              Company by contacting our Officer at the address below.
              Changes to the Privacy Policy
              The Company may update this Privacy Policy from time to time at its own discretion and in response to
              changing legal, technical and/or business development. The revised Privacy Policy will take effect
              immediately upon publication by the Company and we will replace this page with an updated version. When we
              update our Privacy Policy, we shall take appropriate action and inform you accordingly by a Privacy Notice
              consistent with the significant changes we would have made.
              However, you acknowledge that it is your sole responsibility to check the “Privacy Policy” page from time
              to any time so as to be aware of any changes which may have occurred from time to time.
            </p>
          </b-col>


          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">Final Provisions</h5>
          </b-col>

          <b-col md="12">
            <p>
              You may request the Company to access your information and Personal Data at any time. The Company shall be responsible for and be able to demonstrate compliance with the principles outlined in this Privacy Policy. The Company may at its own discretion assign or transfer this Privacy Policy and your related information to any person or entity that acquires or is merged with us without restriction.
              Any claims, questions, comments and requests regarding this Privacy Policy should be addressed to the Company by contacting our Officer at the address below.
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">Contact</h5>
          </b-col>

          <b-col md="12">
            <p>
              Should you have any question, complaint, request to access, or query regarding this Privacy Policy and/or
              would like to examine any of your Personal Data in possession of the Company. You may contact LikeWallet
              Customer Support throughout 24 hours via LikeWallet In-App chat or at info@cyphermines.io .
            </p>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<style>
.space-top-30 {
  margin-top: 30px;
}

.space-left-30 {
  margin-left: 30px;
}

</style>

<script>
export default {
  name: "PrivacyPolicyEN"
}
</script>