import Client from "../provider/provider";
import abi from "../abi/abi";
import contract from "../contract/contract";
import { ethers } from "ethers";
import config from "../config/configwallet";

const callApprove = function(mnemonic, target) {
return new Promise((resolve, reject) => {
	Client(mnemonic).then(wallet => {
		const contract_call = new ethers.Contract(contract.erc20_addr, abi.abiContract, wallet);
			contract_call.approve(target, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff').then(hash =>{
				console.log(hash);
				const explorerURL = config.explorer+'txs/'+hash.hash; 
				resolve(explorerURL);
			}).catch(e =>{
				reject(e);
			});			
	
	});
});
}

export default callApprove