<template>

  <div class="container">
    <b-modal
      id="bv-modal-loading"
      centered
      :hide-footer="true"
      :hide-header="true"
    >
      <p class="my-4" style="text-align: center">
        <img
          src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1624075920logo.png"
          class="rotate"
          width="100"
          height="100"
        />
      </p>
      <p style="text-align: center" v-html="messageLoading"></p>
    </b-modal>
    <div v-if="pagePermission === 'OPEN'">
     <marquee><h2 style="color:red">ยกเลิกกิจกรรมLikeCompoundตั้งแต่วันที่ 15 ก.พ. 65 เป็นต้นไป กรุณาปลดล็อคLIKE ออกจากกิจกรรมนี้ทั้งหมดภายในวันที่ 14ก.พ.65</h2></marquee>
    <div class="row justify-content-center">
      <div class="above-top">
        <b-card
          no-body
          class="overflow-hidden radius-curve"
          style="max-width: 540px"
        >
          <b-row no-gutters>
            <b-col md="12">
              <b-card-body
                class="align-items-center d-flex justify-content-center"
              >
                <b-card-text>
                  <b-row>
                    <b-col md="12">
                      <div class="rewardtitle">LIKE Compound Dashboard</div>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col md="12">
                      <div class="rewards-show">
                        LIKE ที่ใช้ได้ :
                        {{ Number(walletBalance).toLocaleString() }}
                      </div></b-col
                    >
                  </b-row>

                  <b-row>
                    <b-col md="12">
                      <div class="rewards-show">
                        รางวัลวันนี้ : ~ {{ Number(apr).toLocaleString() }} %
                      </div></b-col
                    >
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <div class="rewards-show">
                        รางวัลสะสม : ~ {{ Number(apy).toLocaleString() }} %
                      </div></b-col
                    >
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <div class="rewards-show">
                        LCMP : {{ Number(LCMP).toLocaleString() }}
                      </div></b-col
                    >
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <div class="rewards-show">
                        LIKE compound ของคุณ :
                        {{ Number(Vault).toLocaleString() }} LIKE
                      </div></b-col
                    >
                  </b-row>
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="above-top">
        <b-card
          no-body
          class="overflow-hidden radius-curve"
          style="max-width: 540px"
        >
          <b-row no-gutters>
            <b-col md="12">
              <b-card-body>
                <b-card-text>
                  <b-row>
                    <b-col sm="4"
                      ><img
                        style="height: 1.2em; margin-right: 0.5em"
                        src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1624000804icon1603270944icon--1-.png"
                      /><b style="font-size: 14px"
                        >APR Today ~ {{ Number(apr).toLocaleString() }} %</b
                      >
                    </b-col>
                    <br />
                    <b-col md="12"
                      ><div class="rewards-show">
                        <b style="font-size: 14px">
                          Rate 1 LCMP : {{ Number(rateLike) }} LIKE</b
                        >
                      </div></b-col
                    >
                  </b-row>

                  <b-row class="margin-topMini">
                    <b-col>
                      <div>
                        <b-input-group class="mycustom">
                          <b-form-input
                            class="radius-curve-input"
                            v-model="stakingAmount"
                            placeholder="ใส่จำนวนที่ต้องการฝาก"
                          ></b-form-input>
                          <b-input-group-append class="input-group-prepend">
                            <b-button
                              size="sm"
                              class="maxButton"
                              text="Button"
                              @click="allLike()"
                              >ทั้งหมด</b-button
                            >
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row class="margin-topMini">
                    <b-col
                      class="align-items-center d-flex justify-content-center"
                    >
                      <b-button
                        v-if="!depositApprove"
                        class="button-size"
                        pill
                        variant="primary"
                        @click="approve()"
                        >อนุมัติ</b-button
                      >
                      <b-button
                        v-if="depositApprove"
                        class="button-size"
                        pill
                        variant="primary"
                        @click="deposit()"
                        >ฝาก</b-button
                      >
                    </b-col>
                  </b-row>

                  <!-- 
                              ถอนแบบระบุ LCMP
                <b-row class="margin-topMini">
                  <b-col>
                    <div>
                     <b-input-group class="mycustom">
                      <b-form-input class="radius-curve-input" v-model="withdrawAmount"  @input="calculateHandle" placeholder="ใส่จำนวน LCMP ที่ต้องการถอน"></b-form-input>
                   
                      <b-input-group-append class="input-group-prepend ">
                        <b-button size="sm" class="maxButton" text="Button" @click="allLock()">ทั้งหมด</b-button>
                      </b-input-group-append>                       
                     </b-input-group>
                    </div>
                  </b-col>                  
                </b-row>   

                <b-row class="margin-topMini">
                  <b-col class="align-items-center d-flex justify-content-center">
                    <b-button class="button-size" pill variant="primary" @click="withdraw()">ถอน</b-button>
                  </b-col>
                </b-row>
                <b-row v-if="ifwithdraw" class="margin-topMini">
                  <b-col class="align-items-center d-flex justify-content-center">
                       <p >คุณจะได้รับ {{ totalGetLike }}LIKE</p>
                  </b-col>
                </b-row>
 -->
                  <b-row class="margin-topMini">
                    <b-col>
                      <div>
                        <b-input-group class="mycustom">
                          <b-form-input
                            class="radius-curve-input"
                            v-model="withdrawAmountLike"
                            @input="calculateHandleLike"
                            placeholder="ใส่จำนวน LIKE ที่ต้องการถอน"
                          ></b-form-input>

                          <b-input-group-append class="input-group-prepend">
                            <b-button
                              size="sm"
                              class="maxButton"
                              text="Button"
                              @click="allLockLike()"
                              >ทั้งหมด</b-button
                            >
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </b-col>
                  </b-row>

                  <b-row class="margin-topMini">
                    <b-col
                      class="align-items-center d-flex justify-content-center"
                    >
                      <b-button
                        class="button-size"
                        pill
                        variant="primary"
                        @click="withdrawLike()"
                        >ถอน</b-button
                      >
                    </b-col>
                  </b-row>

                  <b-row v-if="ifwithdraw" class="margin-topMini">
                    <b-col
                      class="align-items-center d-flex justify-content-center"
                    >
                      <p>คุณจะได้รับ {{ totalGetLike }} LIKE</p>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="above-top">
        <b-card
          no-body
          class="overflow-hidden radius-curve"
          style="max-width: 540px"
        >
          <b-row no-gutters>
            <b-col md="12">
              <b-card-body>
                <b-card-text>
                  <b-table
                    small
                    :fields="fields"
                    :items="historyIncome"
                    responsive="sm"
                  >
                    <!-- A custom formatted column -->
                    <template #cell(date)="data">
                      <b class="text-info">{{ data.value }}</b
                      >&nbsp; &nbsp; &nbsp;
                    </template>

                    <!-- A virtual composite column -->
                    <template #cell(income)="data">
                      {{ data.value.toLocaleString() }} LIKE
                    </template>
                  </b-table>
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
    </div>
    <div v-else-if="pagePermission === 'CLOSE'">
    <h2 class="call-button-container">ปิดระบบเเล้ว</h2>
  </div>
  </div>


</template>

<style>
.mycustom {
  position: relative;
}
.mycustom input[type="text"] {
  border: none;
  width: 100%;
  padding-right: 123px;
}
.mycustom .input-group-prepend {
  position: absolute;
  right: 4px;
  top: 4px;
  bottom: 4px;
  z-index: 9;
}
.maxButton {
  border-radius: 20px !important;
  background-color: #d8dada !important;
  border: none !important;
}

.button-size {
  width: 150px;
}
.margin-topMini {
  margin-top: 1.5em;
}
.above-top {
  margin-top: 1.5em;
  padding-right: 1em;
  padding-left: 1em;
  width: 100%;
}
.rewards-show {
  margin-top: 0.1em;
  font-weight: 400;
  font-size: 14px;
}
.rewardtitle {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-weight: bold;
  font-size: 20px;
}
.radius-curve {
  border-radius: 25px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: none !important;
}
.radius-curve-input {
  border-radius: 20px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.rotate {
  animation: rotation 8s infinite linear;
}
.call-button-container {
  margin: auto;
  width: 233px;
  height: 74px;
  vertical-align: middle;
  padding: 10px;
  background: #29A3D8;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-family: lato-bold;
  font-size: 17px;
  color: white;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>


<script>
import { ethers } from "ethers";
import firebase from "firebase/app";
import abi from "../abi/abi";
import contractAddr from "../contract/contract";
import getBalance from "../wallet/balance";
import getTotalLock from "../utils/getTotalLock";
import getReward from "../utils/getRewardsToday";
import getLCMP from "../utils/getLCMP";
import getVault from "../utils/getVault";
import callDeposit from "../utils/callDeposit";
import checkApprove from "../utils/checkApprove";
import callApprove from "../utils/callApprove";
import callWithdraw from "../utils/callWithdraw";
import getLatestLock from "../utils/getLatestLock";
import getRate from "../utils/getRate";
import getHistoryAPRPercent from "../utils/getHistoryAPRPercent";
import getAllLock from "../utils/getAllLock";
import getAddress from "../utils/getAddress";

export default {
  data() {
    return {
      mnemonic: "",
      walletBalance: "0",
      totalLockReward: "0",
      rewardToday: "0",
      apr: "",
      apy: "",
      messageLoading: "",
      Vault: "",
      LCMP: "",
      depositApprove: false,
      // url: 'https://eth-kovan.alchemyapi.io/v2/ffcj9cKD-j_9HUgbtrCWclsHn0lip8Jo',
      // hdPath: "m/44'/60'/0'/0/0",
      // contractLottery: "0xd242C356c172863531d0a9D7F8ADEa59c49fb074",
      // contract_NFT: "0x611C52b7433e596ad179A530CB31fDdE3745c0Fb",
      // lock_addr: "0x7F0F47EA3C01E9E2C7220634451572f8C10FAE4E",
      // erc20_addr: "0x96D1125c356b4dF0Bad5b84b1ba043Fb621b21a3",
      url: 'https://rpc.tomochain.com',
      // url: "https://rpc.tomochain.likewallet.io",
      // url: 'https://wss.tomochain.likewallet.io',
      // url: 'http://192.46.225.147:8545',
      hdPath: "m/44'/60'/0'/0/0",
      contractLottery: contractAddr.lottery_addr,
      contract_NFT: contractAddr.nft_addr,
      lock_addr: contractAddr.lock_addr,
      erc20_addr: contractAddr.erc20_addr,
      abiNFT: abi.abiNFT,
      abiContract: abi.abiContract,
      abiLottery: abi.abiLottery,
      abiLock: abi.abiLock,
      withdrawAmount: "",
      withdrawAmountLike: "",
      stakingAmount: "",
      withdrawAmountWei: "",
      totalGetLike: "",
      rateLike: 0,
      ifwithdraw: false,
      historyIncome: [],
      fields: [
        // A column that needs custom formatting
        { key: "date", label: "วันที่" },
        // A regular column
        { key: "income", label: "รางวัล(ประมาณ)" },
        { key: "apr", label: "% วันนี้" },

        // A regular column
      ],
      pagePermission: "LOADING",
      phone: "",
      email: "",
    
    };
  },
  mounted() {
    this.messageLoading = "กำลังเตรียมพร้อม";
    this.$bvModal.show("bv-modal-loading");
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.messageLoading = "กำลังล็อคอิน";
        user.getIdToken().then((idToken) => {
          this.axios
            .post("https://new.likepoint.io/signInWithTokenByWeb", {
              token: idToken,
            })
            .then(async (response) => {
              this.messageLoading = "กำลังตั้งค่า";
              // let token = JSON.parse(response);
              console.log(response);
              // console.log(token);
              var finalData = response.data.seed.replace(/\\/g, "");
              this.mnemonic = atob(finalData);

              const user = firebase.auth().currentUser;
              if (user !== null) {
                this.phone = user.phoneNumber;
                this.email = user.email;
                this.initState();
              }
            });
        });

        // console.log(user);
        // ...
      } else {
        // User is signed out
        // ...
      }
    });
  },
  methods: {
    reload() {
      getBalance(this.mnemonic).then((balance) => {
        this.walletBalance = Math.floor(balance.balance);

        console.log(`wallet balance ${this.walletBalance}`);
      });
      getTotalLock(this.mnemonic).then((totallock) => {
        this.totalLockReward = totallock;
        console.log(`totalLock ${this.totalLockReward}`);
        getReward(this.mnemonic).then((reward) => {
          this.rewardToday = reward;
          console.log(`reward to day ${this.rewardToday}`);
          getLatestLock().then((history) => {
            this.apr = history;
            // (1+0.5/12)^12-1= 5.116
            this.apy = (Math.pow(1 + this.apr / 100 / 365, 365) - 1) * 100;
            // ((1+0.13455/365)^365-1)*100
          });
        });
      });
      getLCMP(this.mnemonic).then((yourLCMP) => {
        this.LCMP = yourLCMP / 10e17;
      });
      getVault(this.mnemonic).then((yourVault) => {
        this.Vault = yourVault;
      });
      getRate(this.mnemonic).then((rateExchange) => {
        this.rateLike = rateExchange;
      });
    },
    calculateHandle() {
      if (this.withdrawAmount > 0) {
        this.ifwithdraw = true;
      } else {
        this.ifwithdraw = false;
      }

      this.totalGetLike = this.rateLike * this.withdrawAmount;
    },
    calculateHandleLike() {
      if (this.withdrawAmountLike > 0) {
        this.ifwithdraw = true;
      } else {
        this.ifwithdraw = false;
      }
      this.totalGetLike = this.withdrawAmountLike;
    },
    initState() {
      // const db = firebase.firestore();
     getAddress(this.mnemonic).then((address) => {
       console.log(address);
      this.axios
                .post("https://new.likepoint.io/getTierBUfromRDS", {
                  address: address,
                })
                .then(async (response) => {
                  if(response.data.status == 200){
                    this.pagePermission ="OPEN"
                  }else{
                    this.pagePermission ="CLOSE";
                  } 
                }).catch((error) => {
                    this.pagePermission ="CLOSE";
                    console.log("Error getting documents: ", error);
                });
     });            
      this.messageLoading = "กำลังโหลดข้อมูล";
      getBalance(this.mnemonic).then((balance) => {
        this.walletBalance = Math.floor(balance.balance);

        console.log(`wallet balance ${this.walletBalance}`);
      });
      getTotalLock(this.mnemonic).then((totallock) => {
        this.totalLockReward = totallock;
        console.log(`totalLock ${this.totalLockReward}`);
        getReward(this.mnemonic).then((reward) => {
          this.rewardToday = reward;
          console.log(`reward to day ${this.rewardToday}`);
          getLatestLock().then((history) => {
            this.apr = history;

            // (1+0.5/12)^12-1= 5.116
            this.apy = (Math.pow(1 + this.apr / 100 / 365, 365) - 1) * 100;
            this.messageLoading = "เสร็จสิ้น";
            this.$bvModal.hide("bv-modal-loading");
          });
        });
      });
      getLCMP(this.mnemonic).then((yourLCMP) => {
        this.LCMP = yourLCMP / 10e17;
      });
      getVault(this.mnemonic).then((yourVault) => {
        this.Vault = yourVault;
        getHistoryAPRPercent().then((dataHistory) => {
          console.log(dataHistory);
          let likeInit = this.Vault;
          let income = 0;
          for (let i = 0; i < dataHistory.length; i++) {
            income = likeInit * dataHistory[i].apr;
            likeInit -= income;
            let set = {
              income: income,
              date: dataHistory[i].date,
              apr: (dataHistory[i].apr * 100 * 365).toFixed(2),
            };
            this.historyIncome.push(set);
          }

          console.log(this.historyIncome);
        });
      });
      checkApprove(this.mnemonic, contractAddr.lbank_addr).then((approve) => {
        this.depositApprove = approve;
      });
      getRate(this.mnemonic).then((rateExchange) => {
        this.rateLike = rateExchange;
      });

      let vm = this;
      setInterval(async function (vmthis = vm) {
        console.log(vmthis.level);
        vmthis.reload();
        // console.log('drawingPhase ' + this.drawingPhase);
      }, 15000);
    },
    allLike() {
      this.stakingAmount = this.walletBalance;
    },
    allLock() {
      getLCMP(this.mnemonic).then((yourLCMP) => {
        this.withdrawAmount = yourLCMP / 10e17;
        this.withdrawAmountWei = yourLCMP;
        this.calculateHandle();
      });
    },
    allLockLike() {
      getLCMP(this.mnemonic).then((yourLCMP) => {
        this.withdrawAmountLike = (yourLCMP / 10e17) * this.rateLike;
        this.withdrawAmountWei = yourLCMP;
        this.calculateHandleLike();
      });
    },
    approve() {
      this.messageLoading = "กำลังอนุมัติ";
      this.$bvModal.show("bv-modal-loading");

      callApprove(this.mnemonic, contractAddr.lbank_addr).then((url) => {
        this.messageLoading = '<a href="' + url + '">ตรวจสอบธุรกรรม</a>';
        const sleep = (milliseconds) => {
          return new Promise((resolve) => setTimeout(resolve, milliseconds));
        };
        sleep(3000).then((start) => {
          console.log(start);
          this.initState();
        });
      });
    },
    deposit() {
      this.messageLoading = "กำลังฝาก";
      this.$bvModal.show("bv-modal-loading");
      getAddress(this.mnemonic).then((address) => {
        getAllLock(address).then((lock) => {
          console.log(lock);
          // if (
          //   parseInt(lock.toString()) +
          //     parseInt(this.stakingAmount.toString()) >=
          //   parseInt(this.limitLock.toString())
          // ) {
          //   alert(
          //     "ขณะนี้คุณล็อคอยู่ทั้งหมด " +
          //       Number(this.lock).toLocaleString() +
          //       " LIKE ซึ่ง" +
          //       "คุณสามารถล็อคได้สูงสุด " +
          //       this.limitLock.toLocaleString() +
          //       " LIKE ไม่สามารถล็อคได้เพิ่มแล้ว โปรดติดตามการอัพเดทครั้งถัดไป"
          //   );
          // } else {
            callDeposit(
              this.mnemonic,
              Math.floor(this.stakingAmount).toString()
            ).then((url) => {
              this.messageLoading =
                '<a href="' + url + '" target="_blank">ตรวจสอบธุรกรรม</a>';
              const sleep = (milliseconds) => {
                return new Promise((resolve) =>
                  setTimeout(resolve, milliseconds)
                );
              };
              sleep(3000).then((start) => {
                this.stakingAmount = "";
                console.log(start);
                this.initState();
              });
            }).catch((error) => {
                       this.messageLoading ='เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง';
                    console.log("Error getting documents: ", error);
                });
          // }
        });
      });
    },
    withdraw() {
      this.messageLoading = "กำลังถอน";
      this.$bvModal.show("bv-modal-loading");
      if (this.withdrawAmountWei == "") {
        this.withdrawAmountWei = ethers.utils.parseUnits(this.withdrawAmount);
      }
      callWithdraw(this.mnemonic, this.withdrawAmountWei.toString()).then(
        (url) => {
          this.messageLoading =
            '<a href="' + url + '" target="_blank">ตรวจสอบธุรกรรม</a>';
          const sleep = (milliseconds) => {
            return new Promise((resolve) => setTimeout(resolve, milliseconds));
          };
          sleep(3000).then((start) => {
            this.withdrawAmount = "";
            console.log(start);
            this.initState();
          });
        }
      ).catch((error) => {
                       this.messageLoading ='เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง';
                    console.log("Error getting documents: ", error);
                });
    },
    withdrawLike() {
      this.messageLoading = "กำลังถอน";
      this.$bvModal.show("bv-modal-loading");
      if (this.withdrawAmountWei == "") {
        this.withdrawAmountWei = ethers.utils.parseUnits(
          (this.withdrawAmountLike / this.rateLike).toString()
        );
        console.log("rate " + this.withdrawAmountWei / this.rateLike);
      }
      callWithdraw(this.mnemonic, this.withdrawAmountWei.toString()).then(
        (url) => {
          this.messageLoading =
            '<a href="' + url + '" target="_blank">ตรวจสอบธุรกรรม</a>';
          const sleep = (milliseconds) => {
            return new Promise((resolve) => setTimeout(resolve, milliseconds));
          };
          sleep(3000).then((start) => {
            this.withdrawAmountLike = "";
            console.log(start);
            this.initState();
          });
        }
      ).catch((error) => {
                       this.messageLoading ='เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง';
                    console.log("Error getting documents: ", error);
                });
    },
  },
};
</script>
