<template>
 
  <div class="container">
    <b-modal id="bv-modal-loading" centered :hide-footer=true :hide-header=true>
      <p class="my-4" style="text-align: center;"><img src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1624075920logo.png" class="rotate" width="100" height="100" /></p>
      <p style="text-align: center;" v-html="messageLoading"></p>
    </b-modal>   



    <b-modal id="bv-modal-name" centered :hide-footer=true :hide-header=true>
      <p style="text-align: center;" v-html="ownerName"></p>
    </b-modal>   


    <div><button type='button' @click="showLock()">Lock&Earn</button> <button type='button' @click="showCompound()">AutoCompound</button></div>

    <div v-if='isLock'>
      <b-table small :fields="fields" :items="likeRewards" responsive="sm">
        <!-- A virtual column -->
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <!-- A custom formatted column -->
        <template #cell(sender)="data">
          <b class="text-info">{{ data.value.substring(0,6) + '....' + data.value.substring(data.value.length-6) }}</b>&nbsp; &nbsp; &nbsp; 
          <b-badge href="#" variant="info"  @click="getName(data.value)">ตรวจสอบ</b-badge>
        </template>

        <!-- A virtual composite column -->
        <template #cell(amount)="data">
          {{ data.value.toLocaleString() }} LIKE
        </template>


      </b-table>
    </div>

    <div v-if='isCompound'>
      <b-table small :fields="fields" :items="likeCompound" responsive="sm">
        <!-- A virtual column -->
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <!-- A custom formatted column -->
        <template #cell(sender)="data">
            <b class="text-info">{{ data.value.substring(0,6) + '....' + data.value.substring(data.value.length-6) }}</b>&nbsp; &nbsp; &nbsp;
            <b-badge href="#" variant="info"  @click="getName(data.value)">ตรวจสอบ</b-badge>
        </template>

        <!-- A virtual composite column -->
        <template #cell(amount)="data">
          {{ data.value.toLocaleString() }} LIKE
        </template>
 


      </b-table>
    </div>
  </div>
</template>

<style>
.mycustom {
    position: relative;
}
.mycustom input[type=text] {
    border: none;
    width: 100%;
    padding-right: 123px;
}
.mycustom .input-group-prepend {
    position: absolute;
    right: 4px;
    top: 4px;
    bottom: 4px;z-index:9;
}
.maxButton {
  border-radius: 20px !important;
  background-color:#D8DADA !important;
  border: none !important;
}

  .button-size {
    width: 150px;
  }
  .margin-topMini {
    margin-top: 1.5em;
  }
  .above-top {
    margin-top: 1.5em;
    padding-right: 1em;
    padding-left: 1em;
    width: 100%;

  }
  .rewards-show {
     margin-top: 0.1em;
    font-weight: 400;
    font-size: 14px;   
  }
  .rewardtitle {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-weight: bold;
    font-size: 20px;
  }
  .radius-curve{
    border-radius: 25px !important;
   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
   border: none !important;
  }
  .radius-curve-input{
    border-radius: 20px !important;
   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
 
  }
  .rotate {
    animation: rotation 8s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

</style>


<script>
  import firebase from "firebase/app";
  import getRank from "../utils/getRanking";
  import searchName from "../utils/getName";

  export default {
    data () {
      return {
        likeRewards: '',
        likeCompound: '',
        isLock: true,
        isCompound: false,
        messageLoading:'',
        tokenId:'',
        ownerName: '',
        fields: [
        { key: 'index', label:'ลำดับ'} ,
          // A column that needs custom formatting
          { key: 'sender', label:'ผู้ถือ'} ,
          // A regular column
          { key: 'amount', label:'จำนวน'} ,
  
          // A regular column
        ],
      }
    },
    mounted() {
      
      this.messageLoading = 'กำลังโหลดข้อมูล โปรดรอซักครู่';
      this.$bvModal.show('bv-modal-loading');
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((idToken) => {
           getRank().then(data =>{
            this.likeRewards = data[0].sort(function(a, b) {
              return +b.amount - +a.amount;
            });
            this.likeCompound = data[1].sort(function(a, b) {
              return +b.amount - +a.amount;
            });
            console.log(this.likeRewards);
            console.log(this.likeCompound);
             this.$bvModal.hide('bv-modal-loading');
           });
           this.tokenId = idToken;
          console.log(idToken);
        });
   
        // console.log(user);
        // ...
      } else {
        // User is signed out
        // ...
      }
    });
    },
    methods: {
    showLock() {
      this.isLock = true;
      this.isCompound = false;
    },
    showCompound() {
      this.isLock = false;
      this.isCompound = true;
    },
    getName(address) {
      searchName(address, this.tokenId).then(name=>{
        this.ownerName = name;
        this.$bvModal.show('bv-modal-name');
      });
    }
  },
  computed: {
    formattedValue: {
      get: function() {
        return this.value;
      },
      set: function(newValue) {
        if (newValue.length > 2) {
          newValue = newValue.replace(".", "");
          this.value =
            newValue.substr(0, newValue.length - 2) +
            "." +
            newValue.substr(newValue.length - 2);
        } else {
          this.value = newValue;
        }
      }
    }
  }
}
</script>