import Vue from 'vue';
import Router from 'vue-router';
import Login from '../components/Login'
// import Register from '../components/Register'
import Dashboard from '../components/Dashboard'
import Home from '../components/Home'
import TermsConditions from '../components/TermsConditions'
import PrivacyPolicy from '../components/PrivacyPolicy'
import AppAuthen from '../components/AppAuthen'
import Lottery from '../components/Lottery'
import AutoCompound from '../components/AutoCompound'
import LockAndEarn from '../components/LockAndEarn'
import LPCU from '../components/LPCU'
import LotteryV2 from '../components/LotteryV2'
import RANK from '../components/Ranking'
import Blogs from '../components/Blogs'
import BlogDetail from '../components/BlogDetail'
import DeleteUser from '../components/DeleteUser.vue'


Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/terms-and-conditions',
            name: 'terms-and-conditions',
            component: TermsConditions
        },
        {
            path: '/privacy-policy',
            name: 'privacy-policy',
            component: PrivacyPolicy
        },
        {
            path: '/blog',
            name: 'blog',
            component: Blogs
        },
        {
            path: '/blogs/:id?',
            name: 'blogs',
            component: BlogDetail
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            component: Dashboard
        },
        {
            path: '/appauthen/:token/:path',
            name: 'appauthen',
            component: AppAuthen,
            props: {default: true, sidebar: false},
            meta: {hideNavigation: true}
        },
        {
            path: '/lottery',
            name: 'lottery',
            component: Lottery,
            props: {default: true, sidebar: false},
            meta: {hideNavigation: true}
        },
        {
            path: '/lotteryV2',
            name: 'lotteryV2',
            component: LotteryV2,
            props: {default: true, sidebar: false},
            meta: {hideNavigation: true}
        },
        {
            path: '/autocompound',
            name: 'autocompound',
            component: AutoCompound,
            props: {default: true, sidebar: false},
            meta: {hideNavigation: true}
        },
        {
            path: '/lockandearn',
            name: 'lockandearn',
            component: LockAndEarn,
            props: {default: true, sidebar: false},
            meta: {hideNavigation: true}
        },
        {
            path: '/LPCU',
            name: 'LPCU',
            component: LPCU,
            props: {default: true, sidebar: false},
            meta: {hideNavigation: true}
        }, {
            path: '/rank',
            name: 'rank',
            component: RANK,
            props: {default: true, sidebar: false},
            meta: {hideNavigation: true}
        },
        {
            path: '/delete-account-likewallet',
            name: 'delete-account-likewallet',
            component: DeleteUser,
        },
    ]
});

export default router