'strict'

// const urlRPC = "https://eth-kovan.alchemyapi.io/v2/ffcj9cKD-j_9HUgbtrCWclsHn0lip8Jo";
const urlRPC = "https://rpc.tomochain.com";
const explorer = "https://scan.tomochain.com/";
const hdPath = "m/44'/60'/0'/0/0";


module.exports = {
	urlRPC,
	hdPath,
	explorer
}