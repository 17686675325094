<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <b-row>
          <b-col md="12">
            <div style="text-align: end;">
              <b-form-select style="width: 90px;" v-model="lang" @change="onChangeLang()" class="mb-3">
                <b-form-select-option value="EN">EN</b-form-select-option>
                <b-form-select-option value="TH">TH</b-form-select-option>
              </b-form-select>
            </div>
          </b-col>
        </b-row>
      <div v-if="lang === 'EN'">
        <UsageAgreementEN />
      </div>
        <div v-if="lang === 'TH'">
          <UsageAgreementTH />
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.center {
  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
}

</style>
<script>
import UsageAgreementEN from "@/components/UsageAgreementEN";
import UsageAgreementTH from "@/components/UsageAgreementTH";

export default {
  data() {
    return {
      lang: "EN"
    }
  },
  components: {
    UsageAgreementEN,
    UsageAgreementTH
  },
  mounted() {
    this.checkLang();
  },
  methods: {
    checkLang() {
      let langLocal =  localStorage.getItem('lang');
      if(langLocal === null) {
        localStorage.setItem('lang', 'EN');
      }else {
        this.lang = langLocal;
      }
    },
    onChangeLang() {
      localStorage.setItem('lang', this.lang);
    }
  }
};
</script>