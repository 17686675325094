
import axios from 'axios'

const getRanking = function() {
return new Promise((resolve, reject) => {
	axios.post("https://new.likepoint.io/getRanking", {})
		.then(response => {
			if(response.data.statusCode === 200) {
				resolve(response.data.result);
			}else{
				resolve(0);
			}

			}).catch(e=>{
			console.log(e);
			reject();
	});
});
}

export default getRanking