<template>
  <div class="container">
    <b-modal
      id="bv-modal-loading"
      centered
      :hide-footer="true"
      :hide-header="true"
    >
      <p class="my-4" style="text-align: center">
        <img
          src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1624075920logo.png"
          class="rotate"
          width="100"
          height="100"
        />
      </p>
      <p style="text-align: center" v-html="messageLoading"></p>
    </b-modal>
    <div class="row justify-content-center">
      <div class="above-top">
        <b-card
          no-body
          class="overflow-hidden radius-curve"
          style="max-width: 540px"
        >
          <b-row no-gutters>
            <b-col md="12">
              <b-card-body
                class="align-items-center d-flex justify-content-center"
              >
                <b-card-text>
                  <b-row>
                    <b-col md="12">
                      <div class="rewardtitle">LPCU Dashboard</div>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col md="12">
                      <div class="rewards-show">
                        LIKE ที่ใช้ได้ :
                        {{ Number(walletBalance).toLocaleString() }}
                      </div></b-col
                    >
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <div class="rewards-show">
                        รางวัลวันนี้ : ~ {{ Number(apr).toLocaleString() }} %
                      </div></b-col
                    >
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <div class="rewards-show">
                        รางวัลสะสม : ~ {{ Number(apy).toLocaleString() }} %
                      </div></b-col
                    >
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <div class="rewards-show">
                        LPCU : {{ Number(lpcu).toLocaleString() }}
                      </div></b-col
                    >
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <div class="rewards-show">
                        LPCU ของคุณ :
                        {{ Number(balanceLPCU).toLocaleString() }} LIKE
                      </div></b-col
                    >
                  </b-row>
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="above-top">
        <b-card
          no-body
          class="overflow-hidden radius-curve"
          style="max-width: 540px"
        >
          <b-row no-gutters>
            <b-col md="12">
              <b-card-body>
                <b-card-text>
                  <b-row>
                    <b-col sm="4"
                      ><img
                        style="height: 1.2em; margin-right: 0.5em"
                        src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1624000804icon1603270944icon--1-.png"
                      /><b style="font-size: 14px"
                        >APR Today ~ {{ Number(apr).toLocaleString() }} %</b
                      >
                    </b-col>
                    <br />
                    <b-col md="12"
                      ><div class="rewards-show">
                        <b style="font-size: 14px">
                          Rate 1 LPCU : {{ Number(sharedRate) }} LIKE</b
                        >
                      </div></b-col
                    >
                  </b-row>
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
  </div>
</template>

<style>
.mycustom {
  position: relative;
}
.mycustom input[type="text"] {
  border: none;
  width: 100%;
  padding-right: 123px;
}
.mycustom .input-group-prepend {
  position: absolute;
  right: 4px;
  top: 4px;
  bottom: 4px;
  z-index: 9;
}
.maxButton {
  border-radius: 20px !important;
  background-color: #d8dada !important;
  border: none !important;
}

.button-size {
  width: 150px;
}
.margin-topMini {
  margin-top: 1.5em;
}
.above-top {
  margin-top: 1.5em;
  padding-right: 1em;
  padding-left: 1em;
  width: 100%;
}
.rewards-show {
  margin-top: 0.1em;
  font-weight: 400;
  font-size: 14px;
}
.rewardtitle {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-weight: bold;
  font-size: 20px;
}
.radius-curve {
  border-radius: 25px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: none !important;
}
.radius-curve-input {
  border-radius: 20px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.rotate {
  animation: rotation 8s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>


<script>
// import { ethers } from "ethers";
import firebase from "firebase/app";
import getLPCU from "../utils/getLPCU";
import getBalance from "../wallet/balance";
import getTotalLock from "../utils/getTotalLock";
import getReward from "../utils/getRewardsToday";
import getLatestLock from "../utils/getLatestLock";

export default {
  data() {
    return {
      mnemonic: "",
      messageLoading: "",
      // url: 'https://eth-kovan.alchemyapi.io/v2/ffcj9cKD-j_9HUgbtrCWclsHn0lip8Jo',
      // contractLottery: "0xd242C356c172863531d0a9D7F8ADEa59c49fb074",
      // contract_NFT: "0x611C52b7433e596ad179A530CB31fDdE3745c0Fb",
      // lock_addr: "0x7F0F47EA3C01E9E2C7220634451572f8C10FAE4E",
      // erc20_addr: "0x96D1125c356b4dF0Bad5b84b1ba043Fb621b21a3",
      url: "https://rpc.tomochain.com",
      // url: "https://rpc.tomochain.likewallet.io",
      // url: 'https://wss.tomochain.likewallet.io',
      // url: 'http://192.46.225.147:8545',
      hdPath: "m/44'/60'/0'/0/0",
      walletBalance: "0",
      balanceLPCU: "0",
      apr: "",
      apy: "",
      lpcu: "0",
      sharedRate: "0",
      phone: "",
      email: "",
    };
  },
  mounted() {
    this.messageLoading = "กำลังเตรียมพร้อม";
    this.$bvModal.show("bv-modal-loading");
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.messageLoading = "กำลังล็อคอิน";
        user.getIdToken().then((idToken) => {
          this.tokenId = idToken;
          this.axios
            .post("https://new.likepoint.io/signInWithTokenByWeb", {
              token: idToken,
            })
            .then(async (response) => {
              this.messageLoading = "กำลังตั้งค่า";
              // let token = JSON.parse(response);
              console.log(response);
              // console.log(token);
              var finalData = response.data.seed.replace(/\\/g, "");
              this.mnemonic = atob(finalData);

              const user = firebase.auth().currentUser;
              if (user !== null) {
                this.phone = user.phoneNumber;
                this.email = user.email;
                this.initState();
              }
            });
        });

        // console.log(user);
        // ...
      } else {
        // User is signed out
        // ...
      }
    });
  },
  methods: {
    initState() {
      this.messageLoading = "กำลังโหลดข้อมูล";
      getBalance(this.mnemonic).then((balance) => {
        this.walletBalance = Math.floor(balance.balance);

        console.log(`wallet balance ${this.walletBalance}`);
      });
      getTotalLock(this.mnemonic).then((totallock) => {
        this.totalLockReward = totallock;
        console.log(`totalLock ${this.totalLockReward}`);
        getReward(this.mnemonic).then((reward) => {
          this.rewardToday = reward;
          console.log(`reward to day ${this.rewardToday}`);
          getLatestLock().then((history) => {
            this.apr = history;
            // (1+0.5/12)^12-1= 5.116
            this.apy = (Math.pow(1 + this.apr / 100 / 365, 365) - 1) * 100;
            // ((1+0.13455/365)^365-1)*100
          });
        });
      });
      getLPCU(this.mnemonic).then((LPCU) => {
        this.sharedRate = LPCU.sharedRate;
        this.lpcu = LPCU.lpcu;
        this.balanceLPCU = LPCU.totalLike;
        this.messageLoading = "เสร็จสิ้น";
        this.$bvModal.hide("bv-modal-loading");
      });
    },
  },
};
</script>