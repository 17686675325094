<template>
  <div class="container">


    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">Login with phone number</div>
          <div class="card-body">
            <div v-if="error" class="alert alert-danger">{{error}}</div>
            <form v-if="!isOTP" action="#" @submit.prevent="sendCode">
          <!--     <div class="form-group row">
                <label for="email" class="col-md-4 col-form-label text-md-right">Email</label>

                <div class="col-md-6">
                  <input
                    id="email"
                    type="email"
                    class="form-control"
                    name="email"
                    value
                    required
                    autofocus
                    v-model="form.email"
                  />
                </div>
              </div> -->
<!-- 
              <div class="form-group row">
                <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>

                <div class="col-md-6">
                  <input
                    id="password"
                    type="password"
                    class="form-control"
                    name="password"
                    required
                    v-model="form.password"
                  />
                </div>
              </div> -->
              <div id="recaptcha-container"></div>
              <div class="form-group row">
                <label for="phone" class="col-md-4 col-form-label text-md-right">phone number</label>

                <div class="col-md-6">
                  <input
                    id="phone"
                    type="phone"
                    class="form-control"
                    name="phone"
                    value
                    required
                    autofocus
                    v-model="phone"
                  />
                </div>
              </div>
              <div class="form-group row mb-0">
                <div class="col-md-8 offset-md-4">
                  <button type="submit" class="btn btn-primary">Login</button>
                </div>
              </div>
            </form>

            <form v-if="isOTP" action="#" @submit.prevent="verifyCode">
    
              <div id="recaptcha-container"></div>
              <div class="form-group row">
                <label for="otpCode" class="col-md-4 col-form-label text-md-right">OTP</label>

                <div class="col-md-6">
                  <input
                    id="otpCode"
                    type="number"
                    class="form-control"
                    name="number"
                    value
                    required
                    autofocus
                    v-model="otpCode"
                  />
                </div>
              </div>
              <div class="form-group row mb-0">
                <div class="col-md-8 offset-md-4">
                  <button type="submit" class="btn btn-primary">Verify</button>
                </div>
              </div>
            </form>            
          </div>
        </div>
      </div>
    </div>


<br>
<br>
<br>
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">Login with email</div>
          <div class="card-body">
            <div v-if="error" class="alert alert-danger">{{error}}</div>
            <form  action="#" @submit.prevent="submit">
              <div class="form-group row">
                <label for="email" class="col-md-4 col-form-label text-md-right">Email</label>

                <div class="col-md-6">
                  <input
                    id="email"
                    type="email"
                    class="form-control"
                    name="email"
                    value
                    required
                    autofocus
                    v-model="form.email"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>

                <div class="col-md-6">
                  <input
                    id="password"
                    type="password"
                    class="form-control"
                    name="password"
                    required
                    v-model="form.password"
                  />
                </div>
              </div>

              <div class="form-group row mb-0">
                <div class="col-md-8 offset-md-4">
                  <button type="submit" class="btn btn-primary">Login</button>
                </div>
              </div>
            </form>

               
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      phone:'',
      appVerifier:'',
      isOTP:false,
      otpCode:'',
      error: null
    };
  },
  mounted() {
      this.created();
  },
  methods: {

    submit() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(data => {
          console.log(data);
          this.$router.replace({ name: "Dashboard" });
        })
        .catch(err => {
          this.error = err.message;
        });
    },
    sendCode(){
      if(this.phone.length != 10){
        alert('Numero invalid !');
      }else{
        //
        let countryCode = '+66' //civ
        let phoneNumber = countryCode + this.phone
        //let nextpage = this
        //
        let appVerifier = this.appVerifier
        let vm = this;
        //
        firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
          .then(function (confirmationResult) {
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult;
            vm.isOTP = true;
            // alert('Continue')
            //nextpage.$router.push({path:'/Verify'})
          }).catch(function (error) {
            console.log(error);
          // Error; SMS not sent
          // ...
          alert('sms sent error')
        });
      }
    },
  //
    verifyCode(){
      if(this.phone.length != 10 || this.otpCode.length != 6){
        alert('Numero ou Format invalide !');
      }else{
        //
        let vm = this
        let code = this.otpCode
        //
        window.confirmationResult.confirm(code).then(function (result) {
          // User signed in successfully.
          var user = result.user;
           console.log(user);
          // ...
          //route to set password !
          vm.$router.replace({ name: 'Dashboard' });
        }).catch(function (error) {
           console.log(error);
          // User couldn't sign in (bad verification code?)
          // ...
        });
      }
    },
    initReCaptcha(){
      setTimeout(()=>{
        console.log('hello man');
        let vm = this
        console.log(vm);
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', 
          {
          'size': 'invisible',
          'callback': function(response) {
             console.log(response);
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // ...
          },
          'expired-callback': function() {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
          }
        });
        //
        this.appVerifier =  window.recaptchaVerifier
      },1000)
    },
    created(){
      this.initReCaptcha()
    }


  }
};
</script>