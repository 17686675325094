'strict'
import Client from "../provider/provider";
import abi from "../abi/abi";
import contract from "../contract/contract";
import { ethers } from "ethers";

const getTotalActiveLock = function(mnemonic) {
return new Promise((resolve, reject) => {
	Client(mnemonic).then(wallet => {
		const c_lock = new ethers.Contract(contract.lock_reward_old_addr, abi.abiLockOld, wallet);
		const Lbank = new ethers.Contract(contract.lbank_addr, abi.abiLBank, wallet);
		c_lock.getActiveLock(contract.erc20_addr).then(totalActiveLock =>{
			Lbank.totalLIKE().then(lockAmount => {

				resolve((totalActiveLock/10e17)+(lockAmount/10e17));
			})			
		}).catch(e =>{
			reject(e);
		});
	});
});
}

export default getTotalActiveLock