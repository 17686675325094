import Client from "../provider/provider";
import abi from "../abi/abi";
import contract from "../contract/contract";
import { ethers } from "ethers";

const getBalance = function(mnemonic) {
return new Promise((resolve, reject) => {
	Client(mnemonic).then(wallet => {
		const c_erc20 = new ethers.Contract(contract.erc20_addr, abi.abiContract, wallet);
		c_erc20.balanceOf(wallet.address).then(balance =>{
			let balanceResult = {
				balance: balance/10e17,
				balanceHex: balance
			}
			resolve(balanceResult);
		}).catch(e =>{
			reject(e);
		});
	});
});
}

export default getBalance