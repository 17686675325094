
  // const lottery_addr =  "0x0cE41b0080C7BD09Bc2fDF58dc12284A0fF7Da2D";
  // const nft_addr = "";
  // const lock_addr = "";
  // const erc20_addr = "0x96D1125c356b4dF0Bad5b84b1ba043Fb621b21a3";
  const lock_reward_old_addr = "0xdc3ec0ff5c014001956979ff59290e57fd30103e";
  const airdrop_addr = "0x26491cf764ee5ED9EA4cf85293C2D0b0c0de8b22";
  const lbank_addr = "0x0a4210edaf885e3ec6880902ca88c33e4b14997b";


//mainnet
   const lottery_addr = "0xD6C05E421B00C2727A4793e342FEd293dE63BFC4";
   // const lottery_addr = "0x79C3BCb389c90d7154ec43d08FD85c248E5Bac1f";
   const  nft_addr = "0x32FD7d7a3C6a461edD983499c760254ebD4EE213";
   // const  nft_addr = "0x07910d623E1f5D26561CE83dB3fc7DE19F3886bc";
   const lock_addr =  "0xf65B80a30453e51303610F7374118430566089FF";
   const erc20_addr = "0xB1B4615508528DE75012E38A0e1c07e0143c0c5D";

   const lpcu_addr = "0x35DBA2f5F61C2DE29948C3B6E79C70f4A066cF3f";



module.exports = {
	lottery_addr,
	nft_addr,
	lock_addr,
	erc20_addr,
	airdrop_addr,
	lock_reward_old_addr,
	lbank_addr,
  lpcu_addr
}