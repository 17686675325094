import Client from "../provider/provider";
import abi from "../abi/abi";
import contract from "../contract/contract";
import { ethers } from "ethers";

const getLPCU = function(mnemonic) {
return new Promise((resolve, reject) => {
	Client(mnemonic).then(wallet => {
		// console.log(wallet.address);
		const contractLPCU = new ethers.Contract(contract.lpcu_addr, abi.abiLPCU, wallet);
		contractLPCU.totalLIKE().then(total=>{
		// console.log(total/10e17);
			contractLPCU.totalSupply().then(supply=>{
			// console.log(supply/10e17);
			contractLPCU.Borrower(wallet.address).then(shared =>{
			// console.log(shared.lpcu/10e17);
			if(shared.lpcu/10e17 === 0){
				let result = {
					"sharedRate" : (total/10e17)/(supply/10e17),
					"lpcu":0,
					"totalLike":0
				}
				resolve(result);
			}else{
				let result = {
					"sharedRate" : (total/10e17)/(supply/10e17),
					"lpcu":shared.lpcu/10e17,
					"totalLike":(total/10e17)/(supply/10e17)*(shared.lpcu/10e17)
				}
				resolve(result);
			}

			}).catch(e =>{
				reject(e);
				console.log(e);
			});          
			})
		})		
	
	});
});
}

export default getLPCU