// import Client from "../provider/provider";
// import abi from "../abi/abi";
// import contract from "../contract/contract";
// import { ethers } from "ethers";
import axios from 'axios'

// const getLatestLock = function(mnemonic) {
// return new Promise((resolve, reject) => {
// 	Client(mnemonic).then(wallet => {
// 		const contract_call = new ethers.Contract(contract.lbank_addr, abi.abiLBank, wallet);
// 			contract_call.round().then(round => {
// 				contract_call.history(round).then(history =>{
// 					let data = {
// 						reward: history.reward/10e17,
// 						totalLOCK: history.totalLOCK/10e17,
// 						totalLIKE: history.totalLIKE/10e17
// 					}
// 					resolve(data);
// 				}).catch(e =>{
// 					reject(e);
// 				});						
// 			})
	
	
// 	});
// });
// }
const getHistoryAPRPercent = function() {
return new Promise((resolve, reject) => {
	axios.post("https://new.likepoint.io/getHistoryRewardsPercentage")
		.then(response => {
			if(response.data.statusCode === 200) {
				resolve(response.data.result);
			}else{
				resolve(0);
			}

			}).catch(e=>{
			console.log(e);
			reject();
	});
});
}

export default getHistoryAPRPercent