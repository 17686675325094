import Client from "../provider/provider";
import abi from "../abi/abi";
import contract from "../contract/contract";
import { ethers } from "ethers";


const checkApprove = function(mnemonic, target) {
return new Promise((resolve, reject) => {
	Client(mnemonic).then(wallet => {
		const contract_call = new ethers.Contract(contract.erc20_addr, abi.abiContract, wallet);
		
			contract_call.allowance(wallet.address, target).then(approve =>{
				if(approve > 0) {
					resolve(true);
				}else{
					resolve(false);
				}
				
			}).catch(e =>{
				reject(e);
			});			
	
	});
});
}

export default checkApprove