import Client from "../provider/provider";
import abi from "../abi/abi";
import contract from "../contract/contract";
import { ethers } from "ethers";

const getReward = function(mnemonic) {
return new Promise((resolve, reject) => {
	Client(mnemonic).then(wallet => {
		const airdrop = new ethers.Contract(contract.airdrop_addr, abi.abiAirdrop, wallet);
		
		airdrop.Round(contract.erc20_addr).then(round => {
			airdrop.balanceOfRound(contract.erc20_addr, round).then(rewards =>{
				resolve(rewards/10e17);
			}).catch(e =>{
				reject(e);
			});			
		})

	});
});
}

export default getReward