
import axios from 'axios'

const getTransaction = function(token, address) {
return new Promise((resolve, reject) => {
	axios.post("https://new.likepoint.io/getHistoryNew", {_token: token, address: address.toLowerCase()})
		.then(response => {
			if(response.data.statusCode === 200) {
				resolve(response.data.result);
			}else{
				resolve(0);
			}

			}).catch(e=>{
			console.log(e);
			reject();
	});
});
}

export default getTransaction