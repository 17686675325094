<template>

  <nav class="navbar navbar-expand-md navbar-light navbar-laravel">
    <div class="container">
      <router-link to="Dashboard" class="navbar-brand">
        <img style="height:1.5em; margin-right: 0.5em;" src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1624000804icon1603270944icon--1-.png">LikeWallet</router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent" v-if="$router.currentRoute.name !== 'terms-and-conditions' && $router.currentRoute.name !== 'home' && $router.currentRoute.name !== 'privacy-policy' && $router.currentRoute.name !== 'blog' && $router.currentRoute.name !== 'blogs' && $router.currentRoute.name !== 'delete-account-likewallet' ">
        <ul class="navbar-nav mr-auto"></ul>
        <ul class="navbar-nav ml-auto">
          <template v-if="user.loggedIn">
            <div class="nav-item bottom-man"> <h4><b-badge pill variant="light">{{user.data.displayName}} </b-badge> </h4></div>
            <li class="nav-item">
                <b-button class="button-resize" variant="light"><a class="signout" @click.prevent="signOut">Sign out</a></b-button>
            </li>
          </template>
          <template v-else>
            <li class="nav-item">
              <router-link to="login" class="nav-link">Login</router-link>
            </li>
            <li class="nav-item">
             
            </li>
          </template>
        </ul>
      </div>


 
    </div>
  </nav>


</template>
<style>

  .signout {
    /*color:#000000 !important;*/
  }
  .button-resize {
    height: 2.5em;
    font-weight: bold;
  }
  .bottom-man {
    margin-top: 0.2em;
    margin-right: 2em;
  }
 
  .navbar-brand {
    /*color:#ffffff !important;*/
    font-weight: 600;
    font-size: 2em;

  }
</style>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase";
export default {
  computed: {
    ...mapGetters({
// map `this.user` to `this.$store.getters.user`
      user: "user"
    })
  },
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: "home"
          });
        });
    }
  }
};
</script>