
import { ethers } from "ethers";
import config from "../config/configwallet";

const Client = function(mnemonic) {
	return new Promise((resolve, reject) => {
		try {
			const provider = new ethers.providers.JsonRpcProvider(config.urlRPC);
			const walletMnemonic = ethers.Wallet.fromMnemonic(mnemonic, config.hdPath);
			const wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(provider);
			resolve(wallet);
		}catch (e) {
			reject(e);
		}

	})

}
export default Client;