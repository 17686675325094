<template>
  <div class="container">
    <b-modal
      id="bv-modal-loading"
      centered
      :hide-footer="true"
      :hide-header="true"
    >
      <p class="my-4" style="text-align: center">
        <img
          src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1624075920logo.png"
          class="rotate"
          width="100"
          height="100"
        />
      </p>
      <p style="text-align: center" v-html="messageLoading"></p>
    </b-modal>
    <div class="row justify-content-center">
      <div class="above-top">
        <b-card
          no-body
          class="overflow-hidden radius-curve"
          style="max-width: 540px"
        >
          <b-row no-gutters>
            <b-col md="12">
              <b-card-body
                class="align-items-center d-flex justify-content-center"
              >
                <b-card-text>
                  <b-row>
                    <b-col md="12">
                      <div class="rewardtitle">Lock and Earn Dashboard</div>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col md="12">
                      <div class="rewards-show">
                        LIKE ในกระเป๋า :
                        {{ Number(walletBalance).toLocaleString() }}
                      </div></b-col
                    >
                  </b-row>

                  <b-row>
                    <b-col md="12">
                      <div class="rewards-show">
                        ยอดที่รับได้
                        {{ Number(claimable).toLocaleString() }} LIKE
                      </div></b-col
                    >
                  </b-row>
                  <!--             <b-row>
                      <b-col md="12">   <div class="rewards-show">ดอกทบต้น ~  {{  Number(apy).toLocaleString() }}  % </div></b-col>
                  </b-row>   
                  <b-row>
                      <b-col md="12">   <div class="rewards-show">LCMP : {{  Number(LCMP).toLocaleString() }}   </div></b-col>
                  </b-row>   
                  <b-row>
                      <b-col md="12">   <div class="rewards-show">Vault : {{  Number(Vault).toLocaleString() }}  LIKE</div></b-col>
                  </b-row>    -->
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="above-top">
        <b-card
          no-body
          class="overflow-hidden radius-curve"
          style="max-width: 540px"
        >
          <b-row no-gutters>
            <b-col md="12">
              <b-card-body>
                <b-card-text>
                  <b-row class="margin-topMini">
                    <b-col
                      class="align-items-center d-flex justify-content-center"
                    >
                      <b-button
                        v-if="isClaim"
                        class="button-size"
                        pill
                        variant="primary"
                        @click="claimPoint()"
                        >รับรางวัล</b-button
                      >
                      <b-button
                        v-if="!isClaim"
                        class="button-size"
                        pill
                        variant="success"
                        >รับรางวัลไปแล้ว</b-button
                      >
                    </b-col>
                  </b-row>
                  <b-row v-if="isClaim" class="margin-topMini">
                    <b-col
                      class="align-items-center d-flex justify-content-center"
                    >
                      <p>คุณจะได้รับ {{ claimable }} LIKE</p>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="above-top">
        <b-card
          no-body
          class="overflow-hidden radius-curve"
          style="max-width: 540px"
        >
          <b-row no-gutters>
            <b-col md="12">
              <b-list-group>
                <b-list-group-item
                  button
                  v-for="todo in history"
                  :key="todo.sortDate"
                >
                  <span v-if="todo.paymentMethod == 'bank'"
                    >ถอนเงิน {{ todo.baht }} บาท สถานะ
                    <span v-if="todo.status == '3'">สำเร็จ</span>
                    <span v-if="todo.status != '3'">รอดำเนินการ</span>
                  </span>

                  <span v-if="todo.paymentMethod == 'sendLike'">
                    <span v-if="todo.title == 'sent'">
                      <b>ส่งถึง</b>
                      <b-badge variant="info">{{ todo.to }}</b-badge>
                      <b-badge variant="primary">{{ todo.baht }} LIKE</b-badge>
                    </span>
                    <span v-if="todo.title == 'recv'">
                      <b>รับจาก</b>
                      <b-badge variant="info">{{ todo.to }}</b-badge>
                      <b-badge variant="primary">{{ todo.baht }} LIKE</b-badge>
                    </span>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
  </div>
</template>

<style>
.mycustom {
  position: relative;
}
.mycustom input[type="text"] {
  border: none;
  width: 100%;
  padding-right: 123px;
}
.mycustom .input-group-prepend {
  position: absolute;
  right: 4px;
  top: 4px;
  bottom: 4px;
  z-index: 9;
}
.maxButton {
  border-radius: 20px !important;
  background-color: #d8dada !important;
  border: none !important;
}

.button-size {
  width: 150px;
}
.margin-topMini {
  margin-top: 1.5em;
}
.above-top {
  margin-top: 1.5em;
  padding-right: 1em;
  padding-left: 1em;
  width: 100%;
}
.rewards-show {
  margin-top: 0.1em;
  font-weight: 400;
  font-size: 14px;
}
.rewardtitle {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-weight: bold;
  font-size: 20px;
}
.radius-curve {
  border-radius: 25px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: none !important;
}
.radius-curve-input {
  border-radius: 20px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.rotate {
  animation: rotation 8s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>


<script>
// import { ethers } from "ethers";
import firebase from "firebase/app";
import abi from "../abi/abi";
import contractAddr from "../contract/contract";
import getBalance from "../wallet/balance";
import getClaim from "../utils/getClaim";
import getRoundEarn from "../utils/getRoundEarn";
import checkRewards from "../utils/checkRewards";
import claimRewards from "../utils/claimRewards";
import getTransaction from "../utils/getTransaction";
import getAddress from "../utils/getAddress";

export default {
  data() {
    return {
      mnemonic: "",
      walletBalance: "0",
      totalLockReward: "0",
      rewardToday: "0",
      apr: "",
      apy: "",
      messageLoading: "",
      Vault: "",
      LCMP: "",
      depositApprove: false,
      // url: 'https://eth-kovan.alchemyapi.io/v2/ffcj9cKD-j_9HUgbtrCWclsHn0lip8Jo',
      // hdPath: "m/44'/60'/0'/0/0",
      // contractLottery: "0xd242C356c172863531d0a9D7F8ADEa59c49fb074",
      // contract_NFT: "0x611C52b7433e596ad179A530CB31fDdE3745c0Fb",
      // lock_addr: "0x7F0F47EA3C01E9E2C7220634451572f8C10FAE4E",
      // erc20_addr: "0x96D1125c356b4dF0Bad5b84b1ba043Fb621b21a3",
      url: "https://rpc.tomochain.com",
      // url: 'https://rpc.tomochain.likewallet.io',
      // url: 'https://wss.tomochain.likewallet.io',
      // url: 'http://192.46.225.147:8545',
      hdPath: "m/44'/60'/0'/0/0",
      contractLottery: contractAddr.lottery_addr,
      contract_NFT: contractAddr.nft_addr,
      lock_addr: contractAddr.lock_addr,
      erc20_addr: contractAddr.erc20_addr,
      abiNFT: abi.abiNFT,
      abiContract: abi.abiContract,
      abiLottery: abi.abiLottery,
      abiLock: abi.abiLock,
      withdrawAmount: "",
      stakingAmount: "",
      withdrawAmountWei: "",
      totalGetLike: "",
      rateLike: 0,
      ifwithdraw: false,
      claimable: 0,
      lastTimeClaim: 0,
      isClaim: false,
      tokenId: "",
      history: [],
      OwnerAddress: "",
    };
  },
  mounted() {
    this.messageLoading = "กำลังเตรียมพร้อม";
    this.$bvModal.show("bv-modal-loading");
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.messageLoading = "กำลังล็อคอิน";
        user.getIdToken().then((idToken) => {
          this.tokenId = idToken;
          this.axios
            .post("https://new.likepoint.io/signInWithTokenByWeb", {
              token: idToken,
            })
            .then((response) => {
              this.messageLoading = "กำลังตั้งค่า";
              // let token = JSON.parse(response);
              console.log(response);
              // console.log(token);
              var finalData = response.data.seed.replace(/\\/g, "");
              this.mnemonic = atob(finalData);

              this.initState();
            });
        });

        // console.log(user);
        // ...
      } else {
        // User is signed out
        // ...
      }
    });
  },
  methods: {
    reload() {
      getRoundEarn(this.mnemonic).then((lastTime) => {
        console.log("last is " + lastTime);
        console.log(lastTime);
        getBalance(this.mnemonic).then((balance) => {
          this.walletBalance = Math.floor(balance.balance);

          console.log(`wallet balance ${this.walletBalance}`);
        });

        getClaim(this.mnemonic).then((yourLCMP) => {
          checkRewards(this.mnemonic).then((rewards) => {
            console.log("rewards " + rewards / 10e17);
            if (yourLCMP[1] >= lastTime && rewards / 10e17 <= 0) {
              this.claimable = "รับรางวัลไปแล้ว";
              this.isClaim = false;
              this.claimable = 0;
            } else {
              this.claimable = rewards / 10e17;
              this.isClaim = true;
            }
          });
        });
      });
    },
    transaction() {
      getAddress(this.mnemonic).then((address) => {
        this.OwnerAddress = address;
        getTransaction(this.tokenId, address).then((his) => {
          console.log(his);
          this.history = his;
        });
      });
    },
    calculateHandle() {
      if (this.withdrawAmount > 0) {
        this.ifwithdraw = true;
      } else {
        this.ifwithdraw = false;
      }

      this.totalGetLike = this.rateLike * this.withdrawAmount;
    },
    initState() {
      this.messageLoading = "กำลังโหลดข้อมูล";

      getRoundEarn(this.mnemonic).then((lastTime) => {
        console.log("last is " + lastTime);
        console.log(lastTime);
        getBalance(this.mnemonic).then((balance) => {
          this.walletBalance = Math.floor(balance.balance);

          console.log(`wallet balance ${this.walletBalance}`);
        });

        getClaim(this.mnemonic).then((yourLCMP) => {
          checkRewards(this.mnemonic).then((rewards) => {
            console.log("rewards " + rewards / 10e17);
            if (yourLCMP[1] >= lastTime && rewards / 10e17 <= 0) {
              this.claimable = "รับรางวัลไปแล้ว";
              this.isClaim = false;
              this.claimable = 0;
            } else {
              this.claimable = rewards / 10e17;
              this.isClaim = true;
            }
            this.transaction();
            this.messageLoading = "เสร็จสิ้น";
            this.$bvModal.hide("bv-modal-loading");
          });
        });
      });

      let vm = this;
      setInterval(async function (vmthis = vm) {
        vmthis.reload();
        // console.log('drawingPhase ' + this.drawingPhase);
      }, 30000);
    },
    claimPoint() {
      this.$bvModal.show("bv-modal-loading");
      this.messageLoading = "กำลังโหลดข้อมูล";
      claimRewards(this.mnemonic).then((tx) => {
        console.log(tx);
        this.claimable = "รับรางวัลไปแล้ว";
        this.isClaim = false;
        this.claimable = 0;
        this.messageLoading = "เสร็จสิ้น";
        this.$bvModal.hide("bv-modal-loading");
      });
    },
  },
};
</script>