<template>

  <div>
    <navbar></navbar>

    <main class="py-4">
      <router-view></router-view>
    </main>
  </div>
</template>
<script>
import navbar from "./components/Navbar";
export default {
  components: {
    navbar
  }
};
</script>


<!-- <template>
  <div id="app">

    <img alt="Vue logo" src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1603270944icon.png" width="20%">
    <HelloWorld msg="LikeWallet"/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
 -->