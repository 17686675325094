<template>
 
  <div class="container">
      <b-modal id="bv-modal-loading" centered :hide-footer=true :hide-header=true>
        <p class="my-4" style="text-align: center;"><img src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1624075920logo.png" class="rotate" width="100" height="100" /></p>
        <p style="text-align: center;">{{ messageLoading }}</p>
      </b-modal>    

     <marquee><h2>ปิด Season 1 แล้วพบกันใหม่</h2></marquee>
    <div class="row justify-content-center">
      <div class="col-md-12">
       <b-card-group deck>
          <b-card
            border-variant="primary"
            header-bg-variant="primary"
            header-text-variant="white"
            align="center"
            class="radius-curve"
          >


            <b-card-text>ครั้งที่ # {{ Number(roundPrev).toLocaleString() }} 

              <countdown :end-time="nextRound" :autoStart=false ref="vac2">
                <template
                  v-slot:process="anyYouWantedScopName">
                   <b-badge variant="primary"> <span>{{ `เริ่ม:   ${anyYouWantedScopName.timeObj.ceil.d-1} วัน : ${anyYouWantedScopName.timeObj.ceil.h%24-1} ชั่วโมง : ${anyYouWantedScopName.timeObj.ceil.m%60} นาที : ${anyYouWantedScopName.timeObj.ceil.s%60} วินาที` }} </span></b-badge>
                  </template>
                <template
                  v-slot:finish>
                    <span>เริ่มแล้ว </span>
                </template>
              </countdown>
            </b-card-text>
            <b-card-text>เลขผู้ชนะ [{{ rawWinner1 }} {{ rawWinner2 }} {{ rawWinner3 }} {{ rawWinner4 }}]</b-card-text>
            <b-card-text>รางวัลทั้งหมด {{ Number(totalAmount).toLocaleString() }} LIKE</b-card-text>
            <b-card-text>ตรง 4 ตัว จำนวน {{ matchfourCur }} ตั๋ว รางวัล {{ Number(totalAmount*allocationfirst/100).toLocaleString() }} LIKE</b-card-text>
            <b-card-text>ตรง 3 ตัว จำนวน {{ matchthreeCur }} ตั๋ว รางวัล {{ Number(totalAmount*allocationsec/100).toLocaleString() }} LIKE </b-card-text>
            <b-card-text>ตรง 2 ตัว จำนวน {{ matchtwoCur }} ตั๋ว รางวัล {{ Number(totalAmount*allocationthird/100).toLocaleString() }} LIKE</b-card-text>
          
          </b-card>
        </b-card-group>

      </div>

    </div> 
    <br>
    <br>
    <div class="row justify-content-center">
      <div class="col-md-4" style="margin-top:2em;">
        <div class="card radius-curve" >
        
    
          <div class="card-body">
            <div class="title-card"><b>ข้อมูลของคุณ</b></div>
            <div v-if="user" class="alert alert-success" role="alert">Like ของคุณ {{ Number(balanceLike).toLocaleString() }} Like</div>
            <div v-if="user" >
            <div v-for="lockData in lockDetails.option" :key="lockData.id" class="alert alert-warning" role="alert" >Like ถูกล็อค ในออฟชั่น {{lockData.id+1}} จำนวน {{ Number(lockData.amount).toLocaleString() }}  <b-button v-if="lockData.status==='yes'" @click="withdraw(lockData.id);" >ถอน</b-button></div>
            </div>
            <div v-if="user" class="alert alert-info" role="alert">จำนวนตั๋วที่ได้รับ {{ Number(balanceTicket).toLocaleString() }} </div>
           
          </div>

          
        </div>
      </div>

      <div class="col-md-4" style="margin-top:2em;">
        <div class="card radius-curve">
         

          <div class="card-body">
            <div class="title-card"><b>ตัวเลือกการล็อค</b></div>
          <!--   <select v-model="lockOptionModel">
              <option v-for="option in lockOption.option" :value="option" :key="option">{{ option }}</option>
            </select> -->
            <div class="row justify-content-center">
              <div class="col-md-6">
                <div v-for="option in lockOption.option" :key="option.id">
                  <input :value="option.id" type="radio" v-model="lockOptionModel"> 
                  <label for="option"> ออฟชั่น {{option.id+1}} </label>
                </div>
              </div>
              <div class="col-md-4">
                  
              <b-form  v-if="approved">
                <b-form-group
                  id="input-group-1"
                  label=""
                  label-for="input-1"
                  description="ใส่จำนวนที่จะล็อค"
                >
                  <b-form-input
                    id="input-1"
                    v-model="amountLock"
                    type="text"
                    placeholder="ใส่จำนวน"
                    required
                  ></b-form-input>
                </b-form-group>
                </b-form>               
                  <b-button v-if="!approved" block  v-on:click="approve" variant="outline-primary">อนุมัติ   <b-spinner v-if="spinnerApprove" variant="primary" label="Spinning"></b-spinner></b-button>
                  <b-button v-if="approved" block  v-on:click="deposit" variant="outline-primary">ฝาก <b-spinner v-if="spinnerDeposit" variant="primary" label="Spinning"></b-spinner></b-button>  
                 <!--  <b-button v-if="approved" pill v-on:click="disapprove" variant="outline-primary">approve0 <b-spinner v-if="spinnerDeposit" variant="primary" label="Spinning"></b-spinner></b-button> -->
              </div>
            </div>
          </div>

          
        </div>
      </div>


<div>

  <b-modal id="bv-modal-example" hide-footer>

    <div class="d-block text-center">
      <h4>{{ messageBox }}</h4>
    </div>
    <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example')">ปิด</b-button>
  </b-modal>
</div>

      <div class="col-md-4" style="margin-top:2em;">
        <div class="card radius-curve">
        

          <div class="card-body">
            <div class="title-card"><b>สุ่มตัวเลข</b></div>
          <!--   <select v-model="lockOptionModel">
              <option v-for="option in lockOption.option" :value="option" :key="option">{{ option }}</option>
            </select> -->
            <div class="row justify-content-center">
              <div class="col-md-12">
               ครั้งที่ {{ RoundLotto }}
               คุณมีตั๋ว {{ Number(balanceTicket).toLocaleString() }} ใบ คงเหลือ {{ Number(leftTicket).toLocaleString() }} ใบ

              <b-form  v-if="approved">
                <b-form-group
                  id="input-group-1"
                  label=""
                  label-for="input-1"
                  description="ใส่จำนวนที่จะซื้อ"
                >
                  <b-form-input
                    id="input-1"
                    v-model="amountBuy"
                    type="text"
                    placeholder="ใส่จำนวน"
                    required
                  ></b-form-input>
                </b-form-group>
                </b-form>    
                <div v-if="!drawingPhase">              
               <b-button v-if="!approvedBuy" block  v-on:click="approveBuy" variant="outline-primary">อนุมัติการสุ่ม <b-spinner v-if="spinnerBuy" variant="primary" label="Spinning"></b-spinner></b-button> 
               <b-button v-if="approvedBuy" block  v-on:click="buyticket" variant="outline-primary">สุ่มเลขท้าย <b-spinner v-if="spinnerBuy" variant="primary" label="Spinning"></b-spinner></b-button>
               </div>
               <div v-else>
                  <b-button block  variant="outline-info">ยังไม่ถึงเวลา</b-button>
               </div>
               <br>
             
               <br>
              <div class="alert alert-primary text-center" role="alert">
                <h4><b-badge variant="info">เลขรอบนี้ของคุณ </b-badge></h4> 
                <b-button v-if="multiClaim"  v-on:click="multipleClaimButton" variant="primary"> รับรางวัล <b-spinner v-if="spinnerClaim" variant="primary" label="Spinning"></b-spinner></b-button>

              </div>
              <div v-for="numb in yourlotto" :key="numb.tokendId"  class="alert alert-success" role="alert">

                <h5 v-if="numb.reward > 0">{{ numb.number }} {{ numb.reward }} LIKE</h5>
                <h5 v-else>{{ numb.number }} 

                </h5>
              </div>                
              </div>
            </div>
          </div>
        </div>
      </div>              
    </div>

    <div class="row justify-content-center" style="margin-top:2em;">
      <div class="col-md-12">
       <b-card-group deck>
          <b-card
            border-variant="primary"
            header-bg-variant="primary"
            header-text-variant="white"
            align="center"
            class="radius-curve"
          >
           <!--  <b-card-text>ครั้งที่ # {{ Number(RoundLotto-1).toLocaleString() }}</b-card-text>
            <b-card-text>เลขผู้ชนะ [{{ winnerNum1 }} {{ winnerNum2 }} {{ winnerNum3 }} {{ winnerNum4 }}]</b-card-text>
            <b-card-text>รางวัลทั้งหมด {{ Number(totalPrev).toLocaleString() }} LIKE</b-card-text>
            <b-card-text>ตรง 4 ตัว จำนวน {{ matchfour }} ตั๋ว รางวัล {{ Number(totalPrev*allocationfirst/100).toLocaleString() }} LIKE</b-card-text>
            <b-card-text>ตรง 3 ตัว จำนวน {{ matchthree }} ตั๋ว รางวัล {{ Number(totalPrev*allocationsec/100).toLocaleString() }} LIKE </b-card-text>
            <b-card-text>ตรง 2 ตัว จำนวน {{ matchtwo }} ตั๋ว รางวัล {{ Number(totalPrev*allocationthird/100).toLocaleString() }} LIKE</b-card-text>
           -->
           <div class="title-card"><b>ย้อนหลัง</b></div>
            <b-card-text>เลือกรอบที่ต้องการ # {{ selectRound }}
               <b-form-select v-model="selectRound"
                 @change="changeRound($event)"
               >

                  <option v-for="option in optionsRound" v-bind:key="option.text" v-bind:value="option.value">
                    {{ option.text }}
                  </option>
                </b-form-select>
                
              
            </b-card-text>
            <b-card-text>เลขผู้ชนะ [{{ winnerNum1 }} {{ winnerNum2 }} {{ winnerNum3 }} {{ winnerNum4 }}]</b-card-text>
            <b-card-text>รางวัลทั้งหมด {{ Number(totalPrev).toLocaleString() }} LIKE</b-card-text>
            <b-card-text>ตรง 4 ตัว จำนวน {{ matchfour }} ตั๋ว รางวัล {{ Number(totalPrev*allocationfirst/100).toLocaleString() }} LIKE</b-card-text>
            <b-card-text>ตรง 3 ตัว จำนวน {{ matchthree }} ตั๋ว รางวัล {{ Number(totalPrev*allocationsec/100).toLocaleString() }} LIKE </b-card-text>
            <b-card-text>ตรง 2 ตัว จำนวน {{ matchtwo }} ตั๋ว รางวัล {{ Number(totalPrev*allocationthird/100).toLocaleString() }} LIKE</b-card-text>
              <div v-if="!reloadLotte" class="textB11" style="text-align: center">กำลังโหลดข้อมูล...</div>
               <div v-else>
               <div v-for="numb in reData(yourlottoRound)" :key="numb.tokendId"  role="alert">
                <b-row> <div v-for="(item,index) in numb" :key="index"  class="alert alert-success" role="alert">
                <!-- <h5 v-if="numb.reward > 0">{{ numb.number }} รางวัล {{ numb.reward/10e17 }} LIKE</h5> -->
              {{ item.number }}
              </div>   
              </b-row>
              </div> 
              </div>            
          </b-card>
        </b-card-group>

      </div>

    </div>     
  </div>
</template>
<style>
    .radius-curve{
    border-radius: 25px !important;
   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
   border: none !important;
  }
  .title-card {
    margin-bottom: 1em;
    font-weight: 600;

  }
</style>
<script>
import { mapGetters } from "vuex";
// import  Web3  from "web3";
import { ethers } from "ethers";
import firebase from "firebase/app";
import abi from "../abi/abi";
import contractAddr from "../contract/contract";
import _ from 'underscore';
export default { 
  data() {
    return {
    mnemonic: '',
    // url: 'https://eth-kovan.alchemyapi.io/v2/ffcj9cKD-j_9HUgbtrCWclsHn0lip8Jo',
    // hdPath: "m/44'/60'/0'/0/0",
    // contractLottery: "0xd242C356c172863531d0a9D7F8ADEa59c49fb074",
    // contract_NFT: "0x611C52b7433e596ad179A530CB31fDdE3745c0Fb",
    // lock_addr: "0x7F0F47EA3C01E9E2C7220634451572f8C10FAE4E",
    // erc20_addr: "0x96D1125c356b4dF0Bad5b84b1ba043Fb621b21a3",    
    url: 'https://rpc.tomochain.com',
    // url: 'https://rpc.tomochain.likewallet.io',
    // url: 'https://wss.tomochain.likewallet.io',
    // url: 'http://192.46.225.147:8545',
    hdPath: "m/44'/60'/0'/0/0",
    contractLottery: contractAddr.lottery_addr,
    contract_NFT: contractAddr.nft_addr,
    lock_addr: contractAddr.lock_addr,
    erc20_addr: contractAddr.erc20_addr,
    abiNFT: abi.abiNFT,
    abiContract: abi.abiContract,
    abiLottery: abi.abiLottery,
    abiLock: abi.abiLock,
    balanceLike: 0,
    balanceLockLike: 0,
    balanceTicket: 0,
    lockOption: {
      option: []
    },
    lockDetails: {
      option: []
    },
    messageLoading:'',
    lockOptionModel: null,
    approved: false,
    checkingApprove: null,
    checkingDeposit: null,
    spinnerApprove: false,
    spinnerDeposit: false,
    amountLock: 0,
    amountBuy: 0,
    spinnerBuy: false,
    RoundLotto: 0,
    yourlotto: [],
    yourlottoRound: [],
    approvedBuy: false,
    checkingApproveBuy: null,
    leftTicket: 0,
    winnerNum1: '..loading',
    winnerNum2: '..loanding',
    winnerNum3: '..loanding',
    winnerNum4: '..loanding',
    totalAmount: 0,
    matchfour: 0,
    matchthree: 0,
    matchtwo: 0,
    matchfourCur: 0,
    matchthreeCur: 0,
    matchtwoCur: 0,    
    prizefour: 0,
    prizethree: 0,
    prizetwo: 0,
    totalPrev: 0,
    allocationfirst: 0,
    allocationsec: 0,
    allocationthird: 0,
    rawWinner1: '0',
    rawWinner2: '0',
    rawWinner3: '0',
    rawWinner4: '0',
    drawingPhase: false,
    multiClaim: false,
    tokenList: [],
    spinnerClaim: false,
    nextRound: 0,
    boxTwo:'',
    messageBox:'',
    titleBox:'',
    optionsRound: [],
    selectRound:'',
    reloadLotte :false
    }
  },

  mounted() {
      this.$bvModal.show('bv-modal-loading');
      this.messageLoading = 'กำลังเตรียมพร้อม';
    const db = firebase.firestore();
    // /lotto/round/timer/next
    db.collection('lotto').doc('round').collection('timer').doc('next').get().then(timer => {

      console.log(this.nextRound);
      // this.nextRound = (parseInt(timer.data().time)*1000) - new Date().getTime();
      this.nextRound = parseInt(timer.data().time)*1000
      console.log(this.nextRound);
      const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
      }        
      sleep(1000).then(start => {
        console.log(start);
        this.$refs.vac2.startCountdown(true);
      })      
      
    });
    this.messageLoading = 'กำลังเข้าระบบ';
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.messageLoading = 'กำลังล็อคอิน';
        user.getIdToken().then((idToken) => {
          this.axios.post('https://new.likepoint.io/signInWithTokenByWeb', {
            token: idToken           
          })
          .then((response) => {
            this.messageLoading = 'กำลังเตรียมกระเป๋า';
            // let token = JSON.parse(response);
            console.log(response);
            // console.log(token);
            var finalData = response.data.seed.replace(/\\/g, "");
            this.mnemonic = atob(finalData);
          
            this.initState();
          });   
          
        });
   
        // console.log(user);
        // ...
      } else {
        // User is signed out
        // ...
      }
    });

    

  },  
  methods: {
  randomIntFromInterval(min, max) { // min and max included 
    console.log('interval');
      return Math.floor(Math.random() * (max - min + 1) + min);
  },    
  changeRound(event) {
    if(this.selectRound !== undefined) {
      console.log(event);
      
      let provider = new ethers.providers.JsonRpcProvider(this.url);
      // let hdPath = "m/44'/60'/0'/0/0";
      let walletMnemonic = ethers.Wallet.fromMnemonic(this.mnemonic, this.hdPath);

      let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(provider);
      console.log(wallet.address);
      // const c_erc20 = new ethers.Contract(this.erc20_addr, this.abiContract, wallet);
      // const c_lock = new ethers.Contract(this.lock_addr, this.abiLock, wallet);
      const c_NFT = new ethers.Contract(this.contract_NFT, this.abiNFT, wallet);
      const lottery = new ethers.Contract(this.contractLottery, this.abiLottery, wallet); 
       
      this.lottoRound(lottery, this.selectRound);
      this.lottoNumber(wallet, c_NFT, lottery, this.selectRound);   
    }

  },
  reData(items){
   console.log("reData"+JSON.stringify(_.chunk(items, 3)));
  return _.chunk(items, 3);
  },
  lottoNumber(wallet, c_NFT, lottery, round) {
    console.log('lottoNumber');
           c_NFT.balanceOf(wallet.address).then(async(data)=>{
                   // console.log(data);
                   this.yourlottoRound = [];
                   
                   for(let i=0;i<parseInt(data.toString());i++){
                     c_NFT.tokenOfOwnerByIndex(wallet.address, i).then(x =>{
                          c_NFT.getLotteryIssueIndex(x).then((lottoIndex, index = x) =>{

                          if(parseInt(lottoIndex.toString()) === parseInt(round.toString())) {
                                     console.log(lottoIndex);
                                     console.log(round);                           
                              c_NFT.getLotteryNumbers(index).then(async (number, tokenIdIndex = index) =>{
                                    // console.log('lottory number ' + number);
                                    lottery.getRewardView(tokenIdIndex).then((rewards, tokenNumber=tokenIdIndex) => {
                                      this.reloadLotte =false;
                                      let numberSet;
                                      // console.log('waiting rewards' + tokenNumber +" rewards" + rewards);
                                          numberSet = {
                                            number: number,
                                            tokendId: tokenNumber.toString(),
                                            reward: rewards
                                          };    
                                          // dataArrey3.push(numberSet);
                                          // if(dataArrey3.length == 3){
                                          //     this.yourlottoRound.push(numberSet); 
                                          //     dataArrey3 = [];
                                          // }else{
                                              
                                          // }
                                        this.yourlottoRound.push(numberSet); 
                                        this.reloadLotte = true;
                                        console.log('yourLottoRound');
                                        console.log(this.yourlottoRound);
                                    })
                              });                    
                             }
                          })                                  
                       });   
                   }

                    this.messageLoading = 'เสร็จสิ้น'; 
                    console.log("yourlottoRound"+JSON.stringify(this.yourlottoRound));
                  })   
  },
  multipleClaimButton: async function(event) {

    console.log(this.tokenList);
    this.spinnerClaim = true;
    let provider = new ethers.providers.JsonRpcProvider(this.url);
    // let hdPath = "m/44'/60'/0'/0/0";
    let walletMnemonic = ethers.Wallet.fromMnemonic(this.mnemonic, this.hdPath);

    let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(provider);
    console.log(wallet.address);
    // const c_erc20 = new ethers.Contract(this.erc20_addr, this.abiContract, wallet);
    // const c_lock = new ethers.Contract(this.lock_addr, this.abiLock, wallet);
    // const c_NFT = new ethers.Contract(this.contract_NFT, this.abiNFT, wallet);
    const lottery = new ethers.Contract(this.contractLottery, this.abiLottery, wallet); 
      console.log(event);

    lottery.multiClaim(this.tokenList, {gasPrice: 10e9, gasLimit: 1000000}).then(tx=>{
      console.log(tx);
      // const inter = new ethers.utils.Interface(this.abiLottery);
      // const decodeTransaction = inter.parseTransaction({ data: tx.data, value: tx.value});
      // console.log(decodeTransaction);
      this.spinnerClaim = false;
      alert('claim สำเร็จ');
    })
   
  },
  withdraw: function(id) {
    console.log(`id withdraw is ${id}`);
       this.messageLoading = 'กำลังถอนโปรดรอ';
    this.$bvModal.show('bv-modal-loading');
  
    let provider = new ethers.providers.JsonRpcProvider(this.url);
    // let hdPath = "m/44'/60'/0'/0/0";
    let walletMnemonic = ethers.Wallet.fromMnemonic(this.mnemonic, this.hdPath);

    let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(provider);
    console.log(wallet.address);
    // const c_erc20 = new ethers.Contract(this.erc20_addr, this.abiContract, wallet);
    const c_lock = new ethers.Contract(this.lock_addr, this.abiLock, wallet);
    // const c_NFT = new ethers.Contract(this.contract_NFT, this.abiNFT, wallet);
    // const lottery = new ethers.Contract(this.contractLottery, this.abiLottery, wallet);
    c_lock.timelockhold(wallet.address, parseInt(id)).then(balance =>{
      console.log('withdraw ' + balance.amount);
      c_lock.withdrawLockup(balance.amount, parseInt(id)).then(withdraw =>{
        console.log(withdraw);
          const sleep = (milliseconds) => {
            return new Promise(resolve => setTimeout(resolve, milliseconds))
          }  

          sleep(4000).then(data=>{
            console.log(data);
              this.$bvModal.hide('bv-modal-loading');         
               this.reloadLottory();
          })        
        console.log('withdraw success');
      }); 
    })
    
  
  },
  buyticket: function(event) {
  
   this.messageLoading = 'กำลังเริ่มซื้อตั๋ว';
    this.$bvModal.show('bv-modal-loading');
  

    console.log(event);
    this.spinnerBuy = true;
    let provider = new ethers.providers.JsonRpcProvider(this.url);
    // let hdPath = "m/44'/60'/0'/0/0";
    let walletMnemonic = ethers.Wallet.fromMnemonic(this.mnemonic, this.hdPath);

    let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(provider);
    console.log(wallet.address);
    const c_erc20 = new ethers.Contract(this.erc20_addr, this.abiContract, wallet);
    const c_lock = new ethers.Contract(this.lock_addr, this.abiLock, wallet);
    const c_NFT = new ethers.Contract(this.contract_NFT, this.abiNFT, wallet);
    const lottery = new ethers.Contract(this.contractLottery, this.abiLottery, wallet); 

    let tricketNumber = [];
    let ticketUser = 0;
    lottery.issueIndex().then(async (issueIndex)=>{
       this.messageLoading = 'กำลังเช็ครอบ';
    c_lock.getAmountLottery(wallet.address).then(getTicket =>{
       this.messageLoading = 'กำลังเช็คตั๋ว';
      console.log('55555555');
      console.log(getTicket.toString());
      console.log(issueIndex);
      if(parseInt(getTicket.toString()) > 0) {
    
        lottery.countTicket(wallet.address, issueIndex).then(ticket =>{
          this.messageLoading = 'กำลังนับตั๋ว';
          console.log(parseInt(ticket.leftTicket.toString()));
          if(parseInt(ticket.leftTicket.toString()) > 0) {
            ticketUser = parseInt(ticket.leftTicket.toString());
          }else{
            if(parseInt(ticket.initTicket.toString()) > 0) {
              ticketUser = 0;
            }else{
              ticketUser = parseInt(getTicket.toString());
            }
            
          }

          if(ticketUser > 0) {

           c_erc20.balanceOf(wallet.address).then(walletBalance=>{
            if(walletBalance/10e17 > parseInt(ticketUser.toString())){
              this.messageLoading = 'กำลังโหลดข้อมูล';
            for(let i=0;i<parseInt(ticketUser.toString());i++){

              let number1 = this.randomIntFromInterval(1,9);
              let number2 = this.randomIntFromInterval(1,9);
              let number3 = this.randomIntFromInterval(1,9);
              let number4 = this.randomIntFromInterval(1,9);
              let x = [number1, number2, number3, number4];
              console.log(x);
              tricketNumber.push(x);
            }
            console.log(tricketNumber);
            lottery.multiBuyLock(ethers.utils.parseEther("1"), tricketNumber, {gasLimit: 30000000}).then(tx=>{
              console.log(tx);
             
          
                
                  this.messageLoading = 'กำลังเตรียมพร้อม';
   
                  const sleep = (milliseconds) => {
                    return new Promise(resolve => setTimeout(resolve, milliseconds))
                  }        

                  sleep(4000).then(da=>{
                    console.log(da);


    
                        c_NFT.balanceOf(wallet.address).then(async(data)=>{
                           // console.log(data);
                           this.yourlotto = [];
                           let drawing = await lottery.drawingPhase();
                           for(let i=0;i<parseInt(data.toString());i++){
                             c_NFT.tokenOfOwnerByIndex(wallet.address, i).then(x =>{
                              console.log('tokenByIndex' + x);
                                c_NFT.getLotteryIssueIndex(x).then((lottoIndex, index = x) =>{
                                  console.log('lottoindex '+lottoIndex);
                                  console.log('Roundlotto ' + issueIndex);
                                    console.log('match Index' + index);
                                    c_NFT.getLotteryNumbers(index).then(async (number, tokenIdIndex = index) =>{
                                        lottery.countTicket(wallet.address, issueIndex).then(left=>{
                                          this.leftTicket = left.leftTicket;
                                          this.spinnerBuy = false;
                                        })    
                                          
                           if(parseInt(lottoIndex.toString()) < parseInt(issueIndex.toString()) || parseInt(lottoIndex.toString()) == parseInt(issueIndex.toString()) && drawing == true || drawing == true){
                   
                                          // console.log('lottory number ' + number);
                                          lottery.getRewardView(tokenIdIndex).then((rewards, tokenNumber=tokenIdIndex) => {
                                            let numberSet;
                                            // console.log('waiting rewards' + tokenNumber +" rewards" + rewards);

                                            if(rewards/10e17 > 0){

                                              c_NFT.getClaimStatus(tokenNumber).then(claimed =>{
                                                if(claimed){
                                                  rewards = 0;
                                                }
                                                if(rewards > 0) {
                                                  console.log('multi true');
                                                  this.multiClaim = true;
                                                  this.tokenList.push(parseInt(tokenNumber.toString()));
                                                }
                                                numberSet = {
                                                  number: number,
                                                  tokendId: tokenNumber.toString(),
                                                  reward: rewards
                                                };
                                              })
                                              console.log(number);  
                                              this.$bvModal.hide('bv-modal-loading');
                                            }else{
                                              numberSet = {
                                                number: number,
                                                tokendId: tokenNumber.toString(),
                                                reward: 0
                                              };
                                               this.$bvModal.hide('bv-modal-loading');
                                            }    
                                            if(parseInt(lottoIndex) == parseInt(issueIndex)){
         
                                              this.yourlotto.push(numberSet);   
                                               this.$bvModal.hide('bv-modal-loading');
                                            }                                     
                                          })
                                        }else{

                                              let numberSet = {
                                                number: number,
                                                tokendId: tokenIdIndex.toString(),
                                                reward: 0
                                              };
                                             
                                            if(parseInt(lottoIndex) == parseInt(issueIndex)){

                                             this.yourlotto.push(numberSet);  
                                             
                                            }                                     
                                                   
                                            this.$bvModal.hide('bv-modal-loading');                      
                                        } 


                                    });                    
                                  
                                   
                                })
                                
                               }); 
                              
                           }
                            this.messageLoading = 'เสร็จสิ้น'; 
                             this.$bvModal.hide('bv-modal-loading');
                             this.spinnerBuy = false;

                          })   
                  
                  })
              

            })    
                    
           
            }else{
              //alert('คุณต้องมี Like ขั้นต่ำในกระเป๋า มากกว่า' + this.amountBuy + ' LIKE');

               this.messageLoading = 'คุณต้องมี LIKE ติดกระเป๋า..';
                 this.spinnerBuy = false;
            }
            });
          }else{

            this.messageLoading = 'คุณทายไปแล้ว..';
              this.spinnerBuy = false;
          }
        })
      }else{
        console.log('erorr')
        this.messageLoading = 'คุณไม่มีตั๋ว';
      
    
          this.spinnerBuy = false;
      }
    })
  });
 

  },
  deposit: function(event) {
    console.log(event);
    this.messageLoading = 'กำลังโหลด..';  
     this.$bvModal.show('bv-modal-loading');

    this.spinnerDeposit = true;
    let provider = new ethers.providers.JsonRpcProvider(this.url);
    // let hdPath = "m/44'/60'/0'/0/0";
    let walletMnemonic = ethers.Wallet.fromMnemonic(this.mnemonic, this.hdPath);

    let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(provider);
    console.log(wallet.address);
    // const c_erc20 = new ethers.Contract(this.erc20_addr, this.abiContract, wallet);
    const c_lock = new ethers.Contract(this.lock_addr, this.abiLock, wallet);
    // const c_NFT = new ethers.Contract(this.contract_NFT, this.abiNFT, wallet);
    // const lottery = new ethers.Contract(this.contractLottery, this.abiLottery, wallet);     
    // if(confirm('คุณต้องการล็อค '+ this.amountLock +' LIKE ?')){
        console.log('ok raw');
        c_lock.depositLockup(ethers.utils.parseEther(this.amountLock.toString()), this.lockOptionModel).then(hash =>{
          console.log(hash);
          const sleep = (milliseconds) => {
            return new Promise(resolve => setTimeout(resolve, milliseconds))
          }        
          sleep(4000).then(da=>{
            console.log(da);
 
                     
            this.reloadLottory();
            this.spinnerDeposit = false;
         this.messageLoading = 'เสร็จสิ้น';  
          this.$bvModal.hide('bv-modal-loading');

          })         
        })
      // }else {
      //   this.spinnerDeposit = false;
      // }

  },

  approve: function(event) {
    console.log(event);
    this.messageLoading = 'กำลังโหลด..';  
     this.$bvModal.show('bv-modal-loading');
    this.spinnerApprove = true;
    let provider = new ethers.providers.JsonRpcProvider(this.url);
    // let hdPath = "m/44'/60'/0'/0/0";
    let walletMnemonic = ethers.Wallet.fromMnemonic(this.mnemonic, this.hdPath);

    let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(provider);
    console.log(wallet.address);
    const c_erc20 = new ethers.Contract(this.erc20_addr, this.abiContract, wallet);
    // const c_lock = new ethers.Contract(this.lock_addr, this.abiLock, wallet);
    // const c_NFT = new ethers.Contract(this.contract_NFT, this.abiNFT, wallet);
    // const lottery = new ethers.Contract(this.contractLottery, this.abiLottery, wallet); 

    c_erc20.populateTransaction.approve(this.lock_addr, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff').then(rawTransaction =>{
      console.log(rawTransaction);
      // if(confirm('is approve locklike?')){
        console.log('ok raw');
        c_erc20.approve(this.lock_addr, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff').then(data =>{
          console.log(data);
              // this.messageLoading = 'เสร็จสิ้น';  
              // this.$bvModal.hide('bv-modal-loading');
          this.checkingApprove = setInterval(() => {
                this.checkApprove();
              }, 6000)          
        })
      // }else {
      //   this.spinnerApprove = false;
      // }
      
    })
  },
  approveBuy: function(event) {
      console.log(event);
      this.spinnerBuy = true;
      this.messageLoading = 'กำลังโหลด..';  
       this.$bvModal.show('bv-modal-loading');
      let provider = new ethers.providers.JsonRpcProvider(this.url);
      // let hdPath = "m/44'/60'/0'/0/0";
      let walletMnemonic = ethers.Wallet.fromMnemonic(this.mnemonic, this.hdPath);

      let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(provider);
      console.log(wallet.address);
      const c_erc20 = new ethers.Contract(this.erc20_addr, this.abiContract, wallet);
      // const c_lock = new ethers.Contract(this.lock_addr, this.abiLock, wallet);
      // const c_NFT = new ethers.Contract(this.contract_NFT, this.abiNFT, wallet);
      // const lottery = new ethers.Contract(this.contractLottery, this.abiLottery, wallet); 

      c_erc20.populateTransaction.approve(this.contractLottery, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff').then(rawTransaction =>{
        console.log(rawTransaction);
        
        // if(confirm('is approve lotto?')){
          console.log('ok raw');
          c_erc20.approve(this.contractLottery, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff').then(data =>{
            console.log(data);
            this.messageLoading = 'เสร็จสิ้น'
           
            this.checkingApproveBuy = setInterval(() => {
                  this.checkApproveBuy();
                }, 6000)          
          })
        // }else {
        //   this.spinnerBuy = false;
        // }
        
      })
  },  
  checkApprove: function(event) {
    console.log(event);
    let provider = new ethers.providers.JsonRpcProvider(this.url);
    // let hdPath = "m/44'/60'/0'/0/0";
    let walletMnemonic = ethers.Wallet.fromMnemonic(this.mnemonic, this.hdPath);

    let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(provider);
    console.log(wallet.address);
    const c_erc20 = new ethers.Contract(this.erc20_addr, this.abiContract, wallet);

    c_erc20.allowance(wallet.address, this.lock_addr).then(allowance =>{
      if(allowance > 0) {
        this.approved = true;
        this.spinnerApprove = false;
        this.$bvModal.hide('bv-modal-loading');
        clearInterval(this.checkingApprove)
      }
    })
  },
  checkApproveBuy: function(event) {
    console.log(event);
    let provider = new ethers.providers.JsonRpcProvider(this.url);
    // let hdPath = "m/44'/60'/0'/0/0";
    let walletMnemonic = ethers.Wallet.fromMnemonic(this.mnemonic, this.hdPath);

    let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(provider);
    console.log(wallet.address);
    const c_erc20 = new ethers.Contract(this.erc20_addr, this.abiContract, wallet);

    c_erc20.allowance(wallet.address, this.contractLottery).then(allowance =>{
      if(allowance > 0) {
        this.approveBuy = true;
        this.spinnerBuy = false;
        this.approvedBuy = true;
        this.$bvModal.hide('bv-modal-loading');
        clearInterval(this.checkingApproveBuy)
      }
    })
  },  
  reloadLottory: async function(event) {
    console.log(event);
    let provider = new ethers.providers.JsonRpcProvider(this.url);
    // let hdPath = "m/44'/60'/0'/0/0";
    let walletMnemonic = ethers.Wallet.fromMnemonic(this.mnemonic, this.hdPath);

    let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(provider);
    console.log(wallet.address);
    // const c_erc20 = new ethers.Contract(this.erc20_addr, this.abiContract, wallet);
    const c_lock = new ethers.Contract(this.lock_addr, this.abiLock, wallet);
    // const c_NFT = new ethers.Contract(this.contract_NFT, this.abiNFT, wallet);
    // const lottery = new ethers.Contract(this.contractLottery, this.abiLottery, wallet); 

    c_lock.getAmountLottery(wallet.address).then(ticket =>{
      console.log(ticket.toString());
      this.balanceTicket = ticket;

    })
      let option = await c_lock.maxOption()
     
      let balance = 0;
    
      for(let i=0;i<parseInt(option.toString());i++){
        console.log(option);
        let pointX = await c_lock.timelockhold(wallet.address, i);
        balance += pointX.amount/10e17;
        this.lockDetails.option = [];
        console.log(pointX);
           let dateTime;
            if(parseInt(pointX.expire.toString()) > 0) {
               dateTime = new Date(1000*parseInt(pointX.expire.toString())).toString()
               let date = new Date();
               let dateNow = date.getTime()/1000;
               let status = 'no';
               if(dateNow >= parseInt(pointX.expire.toString())){
                status = 'yes';
               }
              let lockdata = {
                status: status,
                expire:dateTime,
                amount: pointX.amount/10e17,
                id: i
              }

              console.log(lockdata);
              if(lockdata.amount > 0) {
                this.lockDetails.option.push(lockdata);   
              }
               
              console.log(this.lockDetails);        
            }
      }
      this.balanceLockLike = balance;

  },
  lottoRound(lottery, d) {
    
     lottery.historyNumbers(d, 0).then(win1 =>{
        this.winnerNum1 = win1
       })
            lottery.historyNumbers(d, 1).then(win2 =>{
        this.winnerNum2 = win2
       })
                 lottery.historyNumbers(d, 2).then(win3 =>{
        this.winnerNum3 = win3
       })
                      lottery.historyNumbers(d, 3).then(win4 =>{
        this.winnerNum4 = win4
       })
        
      console.log('prev round');
      
       lottery.historyAmount(d, 0).then(to1 =>{
        this.totalPrev = to1/10e17;
       });
       lottery.historyAmount(d, 1).then(to2 =>{
        this.matchfour = to2/10e17;
       });
       lottery.historyAmount(d, 2).then(to3 =>{
        this.matchthree = to3/10e17;
       });
       lottery.historyAmount(d, 3).then(to4 =>{
        this.matchtwo = to4/10e17;
       });
     

  },
  initState() {
    //เรียกรอบล่าสุด
    // this.selectRound = this.RoundLotto; 
    this.messageLoading = 'กำลังโหลดข้อมูล';
    // let mnemonic = 'lock direct tray ensure net price pattern original scout region deer history';
    // let url = 'https://eth-kovan.alchemyapi.io/v2/ffcj9cKD-j_9HUgbtrCWclsHn0lip8Jo';
    let provider = new ethers.providers.JsonRpcProvider(this.url);
    // let hdPath = "m/44'/60'/0'/0/0";
    let walletMnemonic = ethers.Wallet.fromMnemonic(this.mnemonic, this.hdPath);
     this.messageLoading = 'กำลังเชื่อมต่อ';
    let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(provider);
    console.log(wallet.address);
    const c_erc20 = new ethers.Contract(this.erc20_addr, this.abiContract, wallet);
    const c_lock = new ethers.Contract(this.lock_addr, this.abiLock, wallet);
    const c_NFT = new ethers.Contract(this.contract_NFT, this.abiNFT, wallet);
    const lottery = new ethers.Contract(this.contractLottery, this.abiLottery, wallet); 
    console.log(c_erc20);   
    console.log(c_lock);   
    console.log(c_NFT);   
    console.log(lottery); 
    setInterval(async function(){
       lottery.drawingPhase().then(draw =>{
        this.drawingPhase  = draw
       });
       // console.log('drawingPhase ' + this.drawingPhase);
    }, 3000);
     this.messageLoading = 'กำลังโหลดกระเป๋า';
    c_erc20.balanceOf(wallet.address).then(balance=>{
      // console.log(balance/10e17);
      this.balanceLike = balance/10e17;
    });

    c_erc20.allowance(wallet.address, this.lock_addr).then(allowance=>{
      if(allowance > 0) {
        this.approved = true;
      }
    });

    c_erc20.allowance(wallet.address, this.contractLottery).then(allowance=>{
      if(allowance > 0) {
        this.approvedBuy = true;
      }
    });
     this.messageLoading = 'กำลังตรวจสอบ';
    lottery.issueIndex().then(async (d)=>{
     this.roundPrev = (d-1);
     console.log('issueIndex ' + (d-1));
    this.lottoRound(lottery, d-1);
    
     lottery.allocation(0).then(lot1=>{
        this.allocationfirst = lot1;
     });
      lottery.allocation(1).then(lot2=>{
        this.allocationsec = lot2;
     });
       lottery.allocation(2).then(lot3=>{
        this.allocationthird = lot3;
     });
     this.RoundLotto = d.toString();
     console.log('round up' + d.toString());
     for(let x=0;x<parseInt(d.toString());x++)
     {
      let options = {
        text: x,
        value: x
      }
      this.optionsRound.push(options);

   
      if(parseInt((x+1).toString()) == parseInt(d.toString())) {
       
        this.selectRound = d.toString();
      }
     }
     console.log('optionsRound ' + this.optionsRound);
      console.log('start historyNumbers');
       // lottery.historyNumbers(d, 0).then(his1=>{
       //  this.rawWinner1 = his1;
       // })
       // lottery.historyNumbers(d, 1).then(his2=>{
       //  this.rawWinner2 = his2;
       // })
       // lottery.historyNumbers(d, 2).then(his3=>{
       //  this.rawWinner3 = his3;
       // })
       // lottery.historyNumbers(d, 3).then(his4=>{
       //  this.rawWinner4 = his4;
       // })
      let drawing = await lottery.drawingPhase();
      this.drawingPhase  = drawing
        // lottery.historyAmount(d, 1).then(histA1 => {
        //   this.matchfourCur = histA1/10e17;
        // })
        //         lottery.historyAmount(d, 2).then(histA2 => {
        //   this.matchthreeCur = histA2/10e17;
        // })
        //                 lottery.historyAmount(d, 3).then(histA3 => {
        //   this.matchtwoCur = histA3/10e17;
        // })         
      // console.log('historyPrev ' + this.totalPrev + this.matchfour + this.matchthree + this.matchtwo);
     
    })
    c_lock.getOptionLength().then(options =>{
      for(let x=0;x<options;x++){
        console.log(x);
        c_lock.option(x).then(listOption =>{
          let lockData = {
            id: x,
            value: listOption.toString(),
            day: listOption/86400
          }
          this.lockOption.option.push(lockData);
          console.log(this.lockOption);
        })
      }
    })



    c_lock.getAmountLottery(wallet.address).then(ticket =>{
      console.log(ticket.toString());
      this.balanceTicket = ticket;

    })

    getLock(this);
    async function getLock(vm) {
      c_lock.maxOption().then(option => {
        let balance = 0;
        for(let i=0;i<parseInt(option.toString());i++){
          console.log(option);
          // let pointX = await c_lock.timelockhold(wallet.address, i);
          // balance += pointX.amount/10e17;
          // console.log(pointX);
          c_lock.timelockhold(wallet.address, i).then(value => {
            balance += value.amount/10e17;
            vm.balanceLockLike = balance;
            let dateTime;
            if(parseInt(value.expire.toString()) > 0) {
               dateTime = new Date(1000*parseInt(value.expire.toString())).toString()
               let date = new Date();
               let dateNow = date.getTime()/1000;
               let status = 'no';
               if(dateNow >= parseInt(value.expire.toString())){
                status = 'yes';
               }
              let lockdata = {
                status: status,
                expire:dateTime,
                amount: value.amount/10e17,
                id: i
              }

              console.log(lockdata);
              if(lockdata.amount > 0) {
                 vm.lockDetails.option.push(lockdata);  
              }
              console.log(vm.lockDetails);        
            }
          })
        }
        // vm.balanceLockLike = balance;
      });
    }

            lottery.issueIndex().then(async (issueIndex)=>{
                c_NFT.balanceOf(wallet.address).then(async(data)=>{
                   // console.log(data);
                   this.yourlotto = [];
                   let drawing = await lottery.drawingPhase();
                   for(let i=0;i<parseInt(data.toString());i++){
                     c_NFT.tokenOfOwnerByIndex(wallet.address, i).then(x =>{
                      // console.log('tokenByIndex' + x);
                        c_NFT.getLotteryIssueIndex(x).then((lottoIndex, index = x) =>{
                          // console.log('lottoindex '+lottoIndex);
                          // console.log('Roundlotto ' + issueIndex);
                            // console.log('match Index' + index);
                            c_NFT.getLotteryNumbers(index).then(async (number, tokenIdIndex = index) =>{
                                lottery.countTicket(wallet.address, issueIndex).then(left=>{
                                  this.leftTicket = left.leftTicket;
                                  this.spinnerBuy = false;
                                })    
                                
                                  if(parseInt(lottoIndex.toString()) < parseInt(issueIndex.toString()) || parseInt(lottoIndex.toString()) == parseInt(issueIndex.toString()) && drawing == true || drawing == true){
                   
                                  // console.log('lottory number ' + number);
                                  lottery.getRewardView(tokenIdIndex).then((rewards, tokenNumber=tokenIdIndex) => {
                                    let numberSet;
                                    console.log('waiting rewards' + tokenNumber +" rewards" + rewards);

                                    if(rewards/10e17 > 0){

                                      c_NFT.getClaimStatus(tokenNumber).then(claimed =>{
                                        if(claimed){
                                          rewards = 0;
                                        }
                                        if(rewards > 0) {
                                          console.log('multi true');
                                          this.multiClaim = true;
                                          this.tokenList.push(parseInt(tokenNumber.toString()));
                                        }
                                        numberSet = {
                                          number: number,
                                          tokendId: tokenNumber.toString(),
                                          reward: rewards
                                        };
                                      })
                                      this.$bvModal.hide('bv-modal-loading');
                                      console.log(number);  
                                     
                                    }else{
                                      numberSet = {
                                        number: number,
                                        tokendId: tokenNumber.toString(),
                                        reward: 0
                                      };
                                         this.$bvModal.hide('bv-modal-loading');
                                    }    
                                    console.log(numberSet);
                                    if(parseInt(lottoIndex) == parseInt(issueIndex)){
                                     this.yourlotto.push(numberSet);   
                                    }                                  
                                    this.$bvModal.hide('bv-modal-loading');   
                                  })
                                }else{
                                    this.$bvModal.hide('bv-modal-loading');
                                      let numberSet = {
                                        number: number,
                                        tokendId: tokenIdIndex.toString(),
                                        reward: 0
                                      };
                                     console.log(numberSet);
                                    if(parseInt(lottoIndex) == parseInt(issueIndex)){
                                     this.yourlotto.push(numberSet);   
                                    }                                     
                                                                 
                                } 


                            });                    
                          
                           
                        })
                        
                       }); 
                      
                   }
                     this.$bvModal.hide('bv-modal-loading');   
                  })   
            }); 
  
  lottery.totalAmount().then(amount => {
    this.totalAmount = (amount/10e17).toString()
  })
  }
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    })
  }
};


</script>