import Client from "../provider/provider";
import abi from "../abi/abi";
import contract from "../contract/contract";
import { ethers } from "ethers";

const getLCMP = function(mnemonic) {
return new Promise((resolve, reject) => {
	Client(mnemonic).then(wallet => {
		const contract_call = new ethers.Contract(contract.lbank_addr, abi.abiLBank, wallet);
		
			contract_call.balanceOf(wallet.address).then(shared =>{
				resolve(shared);
			}).catch(e =>{
				reject(e);
			});			
	
	});
});
}

export default getLCMP