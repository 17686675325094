import Client from "../provider/provider";
import abi from "../abi/abi";
import contract from "../contract/contract";
import { ethers } from "ethers";

const getRoundEarn = function(mnemonic) {
return new Promise((resolve, reject) => {
	Client(mnemonic).then(wallet => {
		const contract_call = new ethers.Contract(contract.airdrop_addr, abi.abiAirdrop, wallet);
		
				contract_call.Round(contract.erc20_addr).then(round=>{
					console.log(round);
					resolve(round);
				}).catch(e =>{
				reject(e);
			});			
	
	});
});
}

export default getRoundEarn