import Client from "../provider/provider";
import abi from "../abi/abi";
import contract from "../contract/contract";
import { ethers } from "ethers";

const getRate = function(mnemonic) {
return new Promise((resolve, reject) => {
	Client(mnemonic).then(wallet => {
		const contract_call = new ethers.Contract(contract.lbank_addr, abi.abiLBank, wallet);
		
				contract_call.totalSupply().then(totalSupply=>{
					contract_call.totalLIKE().then(totalLIKE => {
						const supply = totalSupply/10e17;
						const like = totalLIKE/10e17;
						resolve(like/supply);
					})
				}).catch(e =>{
				reject(e);
			});			
	
	});
});
}

export default getRate