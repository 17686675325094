<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <b-row>
          <b-col md="12" class="space-top-30">
            <h1 class="font-weight-bold">นโยบายความเป็นส่วนตัว</h1>
          </b-col>
          <b-col md="12">
            <p>
              ที่ CypherMines Corporation (“บริษัท”) หนึ่งในลำดับความสำคัญหลักของเราคือความเป็นส่วนตัวของผู้ใช้ Likepoint (“บริการ”) ของเรา นโยบายความเป็นส่วนตัวนี้พร้อมกับ
              <router-link to="/terms-and-conditions">ข้อกำหนดและเงื่อนไขการให้บริการ</router-link>
              ของแอปพลิเคชั่นนี้และเอกสารอื่น ๆ ที่เรากล่าวถึงในนโยบายนี้มีจุดมุ่งหมายเพื่อแจ้งให้คุณทราบว่าข้อมูลส่วนบุคคลใดของคุณที่เรารวบรวม ใช้ เปิดเผย แบ่งปัน หรือดำเนินการอื่น ๆ เมื่อคุณใช้แอปพลิเคชันนี้ โปรดอ่านนโยบายนี้อย่างละเอียดเพื่อทำความเข้าใจมุมมองและแนวปฏิบัติของเราเกี่ยวกับวิธีที่เราปกป้องข้อมูลส่วนบุคคลของคุณ
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">ข้อมูลส่วนบุคคล</h5>
          </b-col>

          <b-col md="12">
            <p>
              ข้อมูลส่วนบุคคลคือข้อมูลที่เกี่ยวข้องกับบุคคลธรรมดาและสามารถใช้เพื่อระบุบุคคลนั้นได้ เช่น ชื่อ ที่อยู่อีเมล์ หมายเลขโทรศัพท์ ชื่อผู้ใช้โซเชียลมีเดีย ข้อมูลอุปกรณ์ของคุณ และธุรกรรม <br/>
              ซึ่งหมายความว่าเมื่อคุณใช้บริการของเรา เราจะรวบรวมและ/หรือเข้าถึงข้อมูลบางอย่างเกี่ยวกับคุณซึ่งสามารถระบุตัวคุณได้โดยตรง (ซึ่งในกรณีนี้คือ ชื่อและหมายเลขโทรศัพท์) หรืออาจรวบรวมข้อมูลทางอ้อมของคุณ <br/>
              ข้อมูลและ/หรือข้อมูลส่วนบุคคลที่อาจถูกรวบรวม จัดเก็บ ประมวลผล ถ่ายโอน ใช้และเปิดเผยโดย LikeWallet รวมถึงแต่ไม่จำกัดเพียงข้อมูลและ/หรือข้อมูลที่สามารถนำมาใช้ด้วยตนเองหรือกับข้อมูลอื่น ๆ โดยตรงหรือโดยอ้อม ระบุ ติดต่อ หรือค้นหาบุคคลใดบุคคลหนึ่ง หรือเพื่อระบุบุคคลในบริบท
            </p>
          </b-col>

          <b-col md="12">
            <p>
              โดยการใช้บริการจะถือว่าคุณได้อ่านและยอมรับนโยบายความเป็นส่วนตัวนี้
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">พื้นฐานของการรวบรวม การใช้ และการเปิดเผยข้อมูลส่วนบุคคลของคุณและวัตถุประสงค์ของการประมวลผลดังกล่าว</h5>
          </b-col>

          <b-col md="12">
            <p>
              ประเภทของข้อมูลส่วนบุคคลที่เก็บรวบรวม
            </p>
          </b-col>

          <b-col md="12">
            <p class="space-left-30">• ชื่อ, นามสกุล</p>
            <p class="space-left-30">• หมายเลขโทรศัพท์</p>
            <p class="space-left-30">• เอกสารประจำตัวออกโดยส่วนราชการ (ตามขั้นตอน KYC)</p>
            <p class="space-left-30">• ชื่อผู้ใช้โซเชียลมีเดีย (ไม่บังคับ)</p>
            <p class="space-left-30">• ที่อยู่อีเมล (ไม่บังคับ)</p>
            <p class="space-left-30">• ข้อมูลบัญชีธนาคาร (ไม่บังคับ)</p>
          </b-col>


          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">การใช้ข้อมูล</h5>
          </b-col>

          <b-col md="12">
            <p>
              บริษัทอาจใช้ข้อมูลส่วนบุคคลของคุณเพื่อให้บริการที่จำเป็นแก่คุณ เพื่อยืนยันคุณและติดต่อคุณเมื่อจำเป็น โดยจะใช้ชื่อ นามสกุล และหมายเลขโทรศัพท์
            </p>
            <p>
              บริษัทอาจเพียงเข้าถึงข้อมูลโทรศัพท์ของคุณเพื่อรองรับบริการที่สะดวกสบายยิ่งขึ้น บริษัทอาจใช้ข้อมูลส่วนบุคคลที่ให้ไว้เพื่อช่วยในการบริหารจัดการ สนับสนุน และปรับปรุงธุรกิจตามวิธีการดังต่อไปนี้
            </p>
          </b-col>

          <b-col md="12">
            <p>
              1. เพื่อจัดหา บำรุงรักษา ส่งมอบ ปรับปรุงบริการของบริษัท และรับฟังความคิดเห็นของคุณเกี่ยวกับบริการของบริษัท
            </p>
            <p>
              2. เพื่อส่งข้อมูลเกี่ยวกับสินค้าและบริการ
            </p>
            <p>
              3. เพื่อดำเนินการตามวัตถุประสงค์อื่นใดที่มีการรวบรวมข้อมูล
            </p>
            <p>
              4.เพื่อปฏิบัติตามกฎหมาย ข้อบังคับ กฎ หลักปฏิบัติ หรือแนวปฏิบัติที่ออกโดยหน่วยงานหรือหน่วยงานกำกับดูแล
            </p>
            <p>
              หากคุณต้องการหยุดรับข้อมูลตามข้างต้น คุณสามารถเลือกไม่รับได้ตลอดเวลาโดยติดต่อเราตามที่อยู่อีเมลที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้ได้ตลอดเวลา การเข้าถึงข้อมูลส่วนบุคคลของผู้ใช้โดยพนักงานของบริษัทและคนกลางบุคคลที่สาม จะถูกจำกัดให้อยู่ในขั้นต่ำสุดที่จำเป็นสำหรับบริษัทในการดำเนินธุรกิจของเราและการให้บริการของบริษัท
            </p>
          </b-col>


          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">การอนุญาตให้ดำเนินการข้อมูลและการประมวลผลข้อมูล</h5>
          </b-col>

          <b-col md="12">
            <p class="space-left-30">
              • เราขออนุญาตเข้าถึงกล้องและการจัดเก็บ เพื่อจับภาพของผู้ใช้และเลือกภาพจากแกลเลอรีอุปกรณ์เพื่อประสบการณ์การใช้งานที่ดียิ่งขึ้น
            </p>
            <p class="space-left-30">
              • เราใช้หมายเลขโทรศัพท์ที่ผู้ใช้ลงทะเบียนเพื่อวัตถุประสงค์ในการตรวจสอบผู้ใช้ด้วยรหัสผ่านแบบใช้ครั้งเดียว (รหัสความปลอดภัย) *รหัสผ่านแบบครั้งเดียวจะถูกส่งโดย Nexmo Inc.
            </p>
            <p class="space-left-30">
              • เราจัดเก็บข้อมูลของคุณใน Firebase ซึ่งเป็นที่เก็บข้อมูลบนคลาวด์
            </p>
            <p class="space-left-30">
              • ผู้ให้บริการ KYC ของเรา Sumsub (Sum and Substance Ltd.) รวบรวมและประมวลผลข้อมูลประมวลผลข้อมูลส่วนบุคคลภายใต้มาตรา 28 ของ EU GDPR และ UK GDPR
            </p>
            <p class="space-left-30">
              • ในฐานะผู้ใช้ เมื่อคุณลงทะเบียนด้วยหมายเลขโทรศัพท์ หมายเลขโทรศัพท์ที่ลงทะเบียนนี้จะถูกจัดเก็บเป็นชื่อผู้ใช้แอปพลิเคชันของคุณ เราไม่ส่งต่อข้อมูลนี้ไปยังบุคคลที่สาม ยกเว้นในขอบเขตที่จำเป็นเพื่อให้คำสั่งนั้นๆเสร็จสมบูรณ์
            </p>
            <p class="space-left-30">
              • ในส่วนโปรไฟล์ Facebook ID และ Line ID เป็นข้อมูลเสริมที่เราอาจใช้เป็นช่องทางการติดต่อเพิ่มเติมกับผู้ใช้
              <span>เราอาจส่งข้อมูลกิจกรรม LikeWallet ที่คุณอาจสนใจไปยังช่องทางการส่งข้อความที่คุณลงทะเบียนไว้ คุณสามารถเลือกไม่รับเอกสารทางการตลาดในอนาคตได้ตลอดเวลา คุณสามารถยกเลิกการสมัครโดยทำตามคำแนะนำที่มีอยู่ในข้อความที่คุณได้รับ ในกรณีที่คุณเลือกที่จะไม่รับข้อความเหล่านี้ เราขอสงวนสิทธิ์ในการส่งการแจ้งเตือนบางอย่างที่จำเป็นไปยังบริการที่เรามีไว้ เราไม่แนะนำอย่างยิ่งให้ยกเลิกการรับการสื่อสารเหล่านั้น เนื่องจากเป็นสิ่งสำคัญสำหรับผู้ใช้ที่ต้องทราบ</span>
            </p>

            <p class="space-left-30">
              • ในระบบปฏิบัติการบางระบบ LikeWallet อาจเข้าถึงรายชื่อผู้ติดต่ออุปกรณ์ของผู้ใช้เมื่อได้รับอนุญาตจากผู้ใช้ เราไม่จัดเก็บ/รวบรวมข้อมูลจากรายชื่อผู้ติดต่อในอุปกรณ์ของคุณ ฟังก์ชั่นนี้มีไว้เพื่อแสดงหมายเลขโทรศัพท์ของผู้ใช้ LikeWallet ที่ลงทะเบียนแล้วซึ่งจัดเก็บไว้ในรายชื่อผู้ติดต่อในอุปกรณ์ของคุณเพื่อความสะดวกของผู้ใช้
            </p>
            <p class="space-left-30">
              • สุดท้ายนี้ เราไม่เคยใช้หรือแบ่งปันข้อมูลที่สามารถระบุตัวบุคคลได้ที่เราให้ไว้ทางออนไลน์ในลักษณะที่ไม่เกี่ยวข้องกับที่อธิบายไว้ข้างต้น เรายังให้โอกาสคุณในการเลือกไม่รับหรือห้ามการใช้งานที่ไม่เกี่ยวข้องดังกล่าวด้วย
            </p>
          </b-col>


          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">การเปิดเผยข้อมูลส่วนบุคคลของคุณต่อบุคคลที่สาม</h5>
          </b-col>

          <b-col md="12">
            <p>
              เพื่อการประมวลผลตามวัตถุประสงค์ภายใต้พื้นฐานทางกฎหมายที่กล่าวข้างต้น บริษัทอาจรวบรวมและ/หรือใช้ ส่ง ถ่ายโอน ประมวลผล และ/หรือเปิดเผยข้อมูลส่วนบุคคลแก่บุคคลดังต่อไปนี้
            </p>
            <p class="space-left-30">
              • นิติบุคคลที่มีอำนาจควบคุมบริษัทหรืออยู่ภายใต้การควบคุมของบริษัท รวมถึงบริษัทอื่นที่อยู่ในการควบคุมของบริษัท หรือนิติบุคคลอื่นหรือบุคคลที่บริษัทเป็นคู่สัญญา หรือมีความสัมพันธ์ทางกฎหมายกับบริษัท รวมถึงผู้ให้บริการรายอื่นที่มีอำนาจในการตัดสินใจดำเนินการใด ๆ ที่เกี่ยวข้องกับข้อมูลส่วนบุคคลของคุณทั้งภายในและภายนอกประเทศ เช่น ผู้ให้บริการภายนอก ผู้ให้บริการคลาวด์ หรือพันธมิตรทางธุรกิจอื่น ๆ ของบริษัท ทั้งนี้บริษัทจะรับรองว่าผู้ให้บริการเหล่านั้นจะประมวลผลข้อมูลส่วนบุคคลของคุณตามนโยบายความเป็นส่วนตัวนี้และกฎหมายที่ใช้บังคับ
            </p>
            <p class="space-left-30">
              • ผู้ตรวจสอบบัญชี ผู้ตรวจสอบภายนอกของบริษัท หน่วยงานของรัฐ ผู้รับโอนสิทธิเรียกร้อง และบุคคลหรือนิติบุคคลอื่นใดที่บริษัทจำเป็นต้องโอนข้อมูลส่วนบุคคลตามความจำเป็นเพื่อให้เป็นไปตามกฎหมาย
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">ยินยอม</h5>
          </b-col>

          <b-col md="12">
            <p>
              ข้อมูลส่วนบุคคลของคุณอาจถูกประมวลผล ก็ต่อเมื่อคุณให้ความยินยอม หรือการประมวลผลมีความจำเป็นต่อการให้บริการของบริษัทที่คุณร้องขอ หรือเพื่อการปฏิบัติตามภาระผูกพันทางกฎหมาย เช่น ในกรณีของกฎหมายต่อต้านการฟอกเงินและการเงินของผู้ก่อการร้าย
              (AML/TF) หรือตามประโยชน์สาธารณะหรือบุคคลที่สามที่มีการเปิดเผยข้อมูลส่วนบุคคลด้วย เว้นแต่ในกรณีที่ผลประโยชน์ดังกล่าวถูกแทนที่โดยผลประโยชน์ในการปกป้องสิทธิและเสรีภาพขั้นพื้นฐานที่เกิดขึ้นจากสิทธิความเป็นส่วนตัว
              ในฐานะบุคคล คุณอาจใช้สิทธิ์ในการเข้าถึงข้อมูลส่วนบุคคลของคุณที่บริษัทเก็บไว้โดยติดต่อเราตามช่องทางที่นะบุไว้ด้านล่าง
            </p>
          </b-col>


          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">สิทธิที่จะถูกลืม</h5>
          </b-col>

          <b-col md="12">
            <p>
              คุณมีสิทธิที่จะแจ้งให้บริษัทลบข้อมูลส่วนบุคคลของคุณ ซึ่งบริษัทจะดำเนินการตามร้องขอ ในกระบวนการที่จำเป็น รวมถึงการแจ้งให้ผู้ประมวลผลข้อมูลบุคคลที่สามปฏิบัติตามคำขอ เว้นแต่ข้อมูลส่วนบุคคลของคุณจำเป็นต้องได้รับการเก็บรักษาไว้เพื่อให้เป็นไปตามภาระผูกพันทางกฎหมายหรือคำสั่งศาล
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">ความปลอดภัย</h5>
          </b-col>

          <b-col md="12">
            <p>
              บริษัทรับทราบและตระหนักถึงความสำคัญของข้อมูลส่วนบุคคลของคุณ บริษัทจึงได้ปรับปรุงและพัฒนาระบบการรักษาความปลอดภัยของข้อมูลส่วนบุคคลของคุณให้สอดคล้องกับกฎหมายและมีความทันสมัยด้านความปลอดภัยตามมาตรฐานสากลอยู่เสมอ บริษัทจะพยายามอย่างเต็มที่เพื่อให้สอดคล้องกับนโยบายความเป็นส่วนตัวนี้ และจะกระตุ้นให้บุคลากรของบริษัท รวมถึงผู้ประมวลผลข้อมูลที่มีสิทธิในการเข้าถึงข้อมูลส่วนบุคคลหรือมีหน้าที่ทางกฎหมาย ให้รักษาและเคารพความปลอดภัยของข้อมูลส่วนบุคคลของคุณ
              ในกรณีที่บริษัทมีความจำเป็นต้องถ่ายโอนข้อมูลส่วนบุคคลของคุณไปยังต่างประเทศ บริษัทจะใช้มาตรฐานตามกฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลกำหนด นอกจากนี้บริษัทจะไม่ถ่ายโอนข้อมูลส่วนบุคคลของคุณไปยังประเทศปลายทางที่ไม่มีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลเพียงพอตามหลักเกณฑ์ที่กฎหมายกำหนด เว้นแต่คุณจะยินยอมให้บริษัทส่งหรือโอนข้อมูลส่วนบุคคลของคุณไปยังประเทศปลายทางดังกล่าว . อย่างไรก็ตาม ในกรณีที่การจารกรรมทางคอมพิวเตอร์ส่งผลให้ข้อมูลส่วนบุคคลของคุณถูกขโมย ไม่ว่าจะโดยวิธีการเจาะ ขโมย คัดลอก หรือทำลายฐานข้อมูล ทำลายรหัสส่วนตัว (password mining) หรือวิธีการอื่นใด โดยมิได้เป็นความผิดของบริษัทในกรณีดังกล่าว บริษัทมีสิทธิปฏิเสธความรับผิดอันเป็นผลจากการกระทำดังกล่าว
            </p>
          </b-col>


          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">ความเป็นส่วนตัวของผู้เยาว์</h5>
          </b-col>

          <b-col md="12">
            <p>
              LikeWallet มิได้ให้บริการแก่เด็กที่มีอายุต่ำกว่า 20 ปี เราไม่ได้รวบรวมข้อมูลเกี่ยวกับเด็กที่มีอายุต่ำกว่า 20 ปีโดยเจตนาหรือจงใจ
            </p>
          </b-col>


          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">ภาษา</h5>
          </b-col>

          <b-col md="12">
            <p>
              การแปลนโยบายความเป็นส่วนตัวนี้ ไม่ว่าจะเป็นภาษาใดๆ ก็ตาม มีขึ้นเพื่อความสะดวกของคุณเท่านั้น โดยไม่มีเจตนาที่จะเปลี่ยนแปลงนโยบายความเป็นส่วนตัวนี้ ในกรณีที่มีความแตกต่างระหว่างฉบับภาษาอังกฤษและฉบับภาษาอื่น ๆ ให้ถือฉบับภาษาอังกฤษเป็นหลัก
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">การสนับสนุนของเจ้าของข้อมูล</h5>
          </b-col>

          <b-col md="12">
            <p>
              บริษัทจะให้การบริการผู้ใช้แก่ผู้ใช้ผ่านการแชทในแอปพลิเคชั่นกับตัวแทนของบริษัท ในการเข้าสู่บัญชีและช่องทางแชทของผู้ใช้ ผู้ใช้จะต้องเข้าสู่ระบบ LikeWallet ด้วยหมายเลขโทรศัพท์ที่ลงทะเบียนซึ่งรวบรวมไว้ในฐานข้อมูลของเรา
            </p>
          </b-col>


          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">การเก็บรักษาข้อมูล</h5>
          </b-col>

          <b-col md="12">
            <p>
              บริษัทจะเก็บรักษาข้อมูลส่วนบุคคลของคุณและของผู้รับผลประโยชน์จากบริการของบริษัทในระยะเวลาที่เหมาะสมหรือตราบเท่าที่กฎหมายกำหนด บริษัทจะเก็บข้อมูลของคุณไว้นานเท่าที่จำเป็นสำหรับการให้บริการของบริษัท
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">การตอบสนองต่อการละเมิดข้อมูลส่วนบุคคล</h5>
          </b-col>

          <b-col md="12">
            <p>
              เมื่อบริษัททราบถึงการละเมิดข้อมูลส่วนบุคคลที่ต้องสงสัยหรือเกิดขึ้นจริง บริษัทจะดำเนินการสอบสวนภายในและใช้มาตรการแก้ไขที่เหมาะสมในเวลาที่เหมาะสม ในกรณีที่มีความเสี่ยงต่อสิทธิและเสรีภาพของเจ้าของข้อมูล บริษัทจะแจ้งให้หน่วยงานกำกับดูแลที่เกี่ยวข้องทราบโดยไม่ชักช้า และหากเป็นไปได้ ภายใน 72 ชั่วโมงนับจากเมื่อทราบการละเมิดดังกล่าว เจ้าของข้อมูลจะได้รับแจ้งเมื่อการละเมิดข้อมูลส่วนบุคคลอยู่ในระดับ “สูง”
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">กฎหมายที่บังคับใช้</h5>
          </b-col>

          <b-col md="12">
            <p>
              โดยการใช้บริการหรือการให้ข้อมูลแก่บริษัท คุณยินยอมให้มีการประมวลผลและถ่ายโอนข้อมูลตามกฎหมายหมู่เกาะเคย์แมน
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">ความถูกต้อง</h5>
          </b-col>

          <b-col md="12">
            <p>
              เอกสารนี้ได้รับการปรับปรุงเมื่อวันที่ 3 ตุลาคม 2023 และมีผลตั้งแต่วันนั้น
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">สิทธิของคุณ</h5>
          </b-col>

          <b-col md="12">
            <p>
              คุณมีสิทธิ์ร้องขอการเข้าถึงหรือลบข้อมูลส่วนบุคคลใด ๆ ของคุณที่เราอาจมี คุณสามารถส่งคำขอได้โดยติดต่อเจ้าหน้าที่คุ้มครองข้อมูลของเราตามช่องทางที่ระบุไว้ด้านล่าง ซึ่งคุณอาจต้องให้ข้อมูลเพิ่มเติมเพื่อการตรวจสอบตามคำขอของคุณ
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</h5>
          </b-col>

          <b-col md="12">
            <p>
              บริษัทอาจปรับปรุงนโยบายความเป็นส่วนตัวนี้เป็นครั้งคราวตามดุลยพินิจของบริษัท และเพื่อตอบสนองต่อการเปลี่ยนแปลงด้านกฎหมาย เทคนิค และ/หรือการพัฒนาธุรกิจ นโยบายความเป็นส่วนตัวฉบับแก้ไขจะมีผลทันทีเมื่อบริษัทเผยแพร่ และเราจะแทนที่นโยบายก่อนหน้าด้วยฉบับปรับปรุง เมื่อเราอัปเดตนโยบายความเป็นส่วนตัวของเรา เราจะดำเนินการที่เหมาะสมและแจ้งให้คุณทราบตามประกาศความเป็นส่วนตัวที่สอดคล้องกับการเปลี่ยนแปลงดังกล่าว
              อย่างไรก็ตาม คุณรับทราบว่าเป็นความรับผิดชอบของคุณแต่เพียงผู้เดียวในการตรวจสอบหน้า "นโยบายความเป็นส่วนตัว" เป็นครั้งคราวเพื่อรับทราบถึงการเปลี่ยนแปลงใด ๆ ที่อาจเกิดขึ้น
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">บทสุดท้าย</h5>
          </b-col>

          <b-col md="12">
            <p>
              คุณสามารถขอให้บริษัทเข้าถึงข้อมูลและข้อมูลส่วนบุคคลของคุณได้ตลอดเวลา บริษัทจะต้องรับผิดชอบและสามารถแสดงให้เห็นถึงการปฏิบัติตามหลักการที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้ บริษัทอาจใช้ดุลยพินิจในการมอบหมายหรือโอนนโยบายความเป็นส่วนตัวนี้และข้อมูลที่เกี่ยวข้องของคุณไปยังบุคคลหรือนิติบุคคลใดๆ ที่ได้มาหรือรวมกิจการกับเราโดยไม่มีข้อจำกัด
              การเรียกร้อง คำถาม ความคิดเห็น และการร้องขอใด ๆ เกี่ยวกับนโยบายความเป็นส่วนตัวนี้ควรส่งถึงบริษัทโดยติดต่อเจ้าหน้าที่ของเราตามช่องทางที่ระบุไว้ด้านล่าง
            </p>
          </b-col>

          <b-col md="12" class="space-top-30">
            <h5 class="font-weight-bold">ติดต่อ</h5>
          </b-col>

          <b-col md="12">
            <p>
              หากท่านมีคำถาม ข้อร้องเรียน ขอการเข้าถึง หรือสอบถามเกี่ยวกับนโยบายความเป็นส่วนตัวนี้ และ/หรือต้องการตรวจสอบข้อมูลส่วนบุคคลใด ๆ ของท่านที่บริษัทครอบครอง คุณสามารถติดต่อฝ่ายสนับสนุนลูกค้า LikeWallet ได้ตลอด 24 ชั่วโมงผ่านการแชทในแอพ LikeWallet หรือที่ info@cyphermines.io
            </p>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<style>
.space-top-30 {
  margin-top: 30px;
}

.space-left-30 {
  margin-left: 30px;
}
</style>

<script>
export default {
  name: "PrivacyPolicyTH"
}
</script>