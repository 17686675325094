<template>
  <div class="container">
    <div>
      <h1>{{doc.question}}</h1>
      <p><span v-html="doc.answer"></span></p>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";

export default {
  name: "BlogDetail",
  data() {
    return {
      doc : {}
    }
  },
  mounted() {
    firebase.firestore().collection("likewallet_blog").doc(this.$route.query.id).onSnapshot(async snapshot => {
      this.doc = snapshot.data();
    });
  }
}
</script>
