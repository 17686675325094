<template>
  <div class="jumbotron jumbotron-fluid">
    <div class="container-fluid">
      <b-modal
        id="bv-modal-loading"
        centered
        :hide-footer="true"
        :hide-header="true"
      >
        <p class="my-4" style="text-align: center">
          <img
            src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1624075920logo.png"
            class="rotate"
            width="100"
            height="100"
          />
        </p>
        <p style="text-align: center">{{ messageLoading }}</p>
      </b-modal>
      <b-modal
        id="bv-modal-show-number"
        centered
        :hide-footer="true"
        :hide-header="false"
      >
        <!-- <div
          v-for="numb in yourlotto"
          :key="numb.tokendId"
          class="alert"
          role="alert"
          > -->
        <!-- <h5 v-if="numb.reward > 0">
          {{ numb.number }} {{ numb.reward }} LIKE
          </h5> -->
        <div v-if="!reloadPrevNow" class="textB11" style="text-align: center">
          กำลังโหลดข้อมูล...
        </div>
        <div v-else>
          <div
            v-for="numb in reData(yourlottoRound)"
            :key="numb.tokendId"
            role="alert"
          >
            <b-row class="row justify-content-center">
              <div v-for="(item, index) in numb" :key="index" role="alert">
                <!-- <h5 v-if="numb.reward > 0">{{ numb.number }} รางวัล {{ numb.reward/10e17 }} LIKE</h5> -->
                <div
                  v-if="item.length <= 0"
                  class="textB"
                  style="text-align: center"
                >
                  ยังไม่มีการท้ายเลข
                </div>
                <div v-else>
                  <b-col md="4">
                    <div class="textB">{{ item.number.join("") }}</div></b-col
                  >
                </div>
              </div>
            </b-row>
          </div>
        </div>
        <!-- <div v-if="!reloadPrevNow" class="textB11" style="text-align: center">กำลังโหลดข้อมูล...</div>
               <div v-else>
               <div v-for="numb in reData(yourlottoRound)" :key="numb.tokendId"  role="alert">
                <b-row> <div v-for="(item,index) in numb" :key="index"  class="alert alert-success" role="alert"> -->
        <!-- <h5 v-if="numb.reward > 0">{{ numb.number }} รางวัล {{ numb.reward/10e17 }} LIKE</h5> -->
        <!-- {{ item.number }} -->
        <!-- </div>   
              </b-row>
              </div> 
              </div>  -->
      </b-modal>
      <b-modal
        id="bv-modal-show-disable-buy-ticket"
        centered
        :hide-footer="true"
        :hide-header="true"
      >
        <div class="textB" style="text-align: center">
          ท่านสามารถทำการสุ่มตัวเลขกิจกรรม<br />
          ได้ที่ฟังก์ชั่น Lock to Win<br />
          บน Application LikeWallet
        </div>
      </b-modal>
      <div>
        <div class="cover"></div>

        <b-col>
          <b-row>
            <b-col>
              <b-img
                src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1625543339Group%2034348.png"
                width="100.37"
                fluid
                alt="Responsive image"
              ></b-img
            ></b-col>

            <!-- <b-col>2 of 3</b-col> -->
            <b-col>
              <p class="textB ex1" style="text-align: right">
                หมายเลขที่ถูกรางวัล
                <br />
                <button type="button" class="btn-radius-white">
                  <div class="textB11">รอบที่เพิ่งออก</div>
                </button>
              </p></b-col
            >
          </b-row>
          <div id="p40"></div>

          <!-- เลขที่ออกปัจจุบัน -->
          <b-row>
            <b-col> </b-col>
            <div class="circle black">{{ winnerNum1 }}</div>
            <div class="circle black">{{ winnerNum2 }}</div>
            <div class="circle black">{{ winnerNum3 }}</div>
            <div class="circle black">{{ winnerNum4 }}</div>
            <b-col> </b-col>
          </b-row>

          <!-- จำนวนรอบ -->
          <b-thead class="table-borderless"
            ><b-tr>
              <b-td class="textB" style="text-align: left">ครั้งที่</b-td>
              <b-td class="textB" style="text-align: center"
                >&nbsp;&nbsp;&nbsp;&nbsp;</b-td
              >
              <b-td class="textB" style="text-align: right">
                #{{ Number(RoundLotto - 1).toLocaleString() }}
                <!-- <countdown :end-time="nextRound" :autoStart=false ref="vac2">
                <template
                  v-slot:process="anyYouWantedScopName">
                   <b-badge variant="primary"> <span>{{ `เริ่ม:   ${anyYouWantedScopName.timeObj.ceil.d-1} วัน : ${anyYouWantedScopName.timeObj.ceil.h%24-1} ชั่วโมง : ${anyYouWantedScopName.timeObj.ceil.m%60} นาที : ${anyYouWantedScopName.timeObj.ceil.s%60} วินาที` }} </span></b-badge>
                  </template>
                <template
                  v-slot:finish>
                    <span>เริ่มแล้ว </span>
                </template>
              </countdown> -->
              </b-td>
            </b-tr>
          </b-thead>
          <b-row>
            <b-col
              ><p class="textB" style="text-align: left">
                รางวัลทั้งหมด
              </p></b-col
            >
            <b-col
              ><p class="textB" style="text-align: right">
                {{ Number(totalPrev).toLocaleString() }} LIKE
              </p></b-col
            >
          </b-row>

          <div id="thin"></div>

          <!-- <b-row>
            <b-col
              ><p class="textB" style="text-align: left">จำนวนเลขตรง</p></b-col
            >
            <b-col
              ><p class="textB" style="text-align: center">
                จำนวนผู้ถูกรางวัล
              </p></b-col
            >
            <b-col
              ><p class="textB" style="text-align: right">
                รางวัล (LIKE)
              </p></b-col
            >
          </b-row>
          <b-row>
            <b-col><p class="textB" style="text-align: left">4 ตัว</p></b-col>
            <b-col><p class="textB" style="text-align: center">0</p></b-col>
            <b-col
              ><p class="textB" style="text-align: right">500,000.00</p></b-col
            >
          </b-row>
          <b-row>
            <b-col><p class="textB" style="text-align: left">3 ตัว</p></b-col>
            <b-col><p class="textB" style="text-align: center">2</p></b-col>
            <b-col
              ><p class="textB" style="text-align: right">
                26,065.667.09
              </p></b-col
            >
          </b-row>

          <b-row>
            <b-col><p class="textB" style="text-align: left">2 ตัว</p></b-col>
            <b-col><p class="textB" style="text-align: center">0</p></b-col>
            <b-col
              ><p class="textB" style="text-align: right">
                15,039.400.254
              </p></b-col
            > -->
          <!-- </b-row> -->
          <b-table-simple responsive>
            <b-thead class="table-borderless">
              <b-tr>
                <b-td class="textB" style="width: 30.33%; text-align: left"
                  >จำนวนเลขตรง</b-td
                >
                <b-td class="textB" style="width: 36.33%; text-align: center"
                  >จำนวนผู้ถูกรางวัล</b-td
                >
                <b-td class="textB" style="width: 30.33%; text-align: right"
                  >รางวัล (LIKE)</b-td
                >
              </b-tr>
            </b-thead>
            <b-tbody class="table-borderless">
              <b-tr>
                <b-td class="textB" style="text-align: left">4 ตัว</b-td>
                <b-td class="textB" style="text-align: center">{{
                  matchfour
                }}</b-td>
                <b-td class="textB" style="text-align: right"
                  >{{
                    Number((totalPrev * allocationfirst) / 100).toLocaleString()
                  }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td class="textB" style="text-align: left">3 ตัว</b-td>
                <b-td class="textB" style="text-align: center">{{
                  matchthree
                }}</b-td>
                <b-td class="textB" style="text-align: right"
                  >{{
                    Number((totalPrev * allocationsec) / 100).toLocaleString()
                  }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td class="textB" style="text-align: left">2 ตัว</b-td>
                <b-td class="textB" style="text-align: center">{{
                  matchtwo
                }}</b-td>
                <b-td class="textB" style="text-align: right"
                  >{{
                    Number((totalPrev * allocationthird) / 100).toLocaleString()
                  }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div id="thin"></div>
          <br />
          <b-row>
            <b-col
              ><p class="textB" style="text-align: left">ตั๋วเลขของคุณ</p>
              <p class="textB" style="text-align: left">
                {{ Number(yourlottoRound.length).toLocaleString() }}
              </p></b-col
            >
            <b-col>
              <p class="textB" style="text-align: right">
                <!-- @click="changeRound($event)" -->
                <button v-b-modal.bv-modal-show-number class="button button100">
                  ดูเลขล็อตโต้ของคุณ
                </button>
              </p></b-col
            >
          </b-row>
          <b-row v-if="multiClaim">
            <b-col
              ><p class="textB" style="text-align: center">
                ยินดีด้วย คุณชนะรางวัล!
              </p>
            </b-col>

            <b-row>
              <b-col>
                <p class="textB" style="text-align: center">
                  <button
                    class="button2 button20"
                    v-on:click="multipleClaimButton"
                  >
                    เคลมรางวัลของคุณ
                  </button>
                </p></b-col
              >
            </b-row>
          </b-row>
          <!-- <b-spinner v-if="spinnerClaim" variant="primary" label="Spinning"></b-spinner> -->
        </b-col>
      </div>
      <div id="thin"></div>
      <br />
      <b-table-simple responsive>
        <b-tbody class="table-borderless">
          <b-tr>
            <b-td>หมายเลขชนะรางวัลรอบที่ผ่านมา</b-td>
            <!-- <b-td class="textW" style="text-align: right">ดูทั้งหมด</b-td> -->
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div v-if="!reload" class="textB11" style="text-align: center">
        กำลังโหลดข้อมูล...
      </div>
      <div v-else>
        <VueSlickCarousel v-bind="settings" v-if="reload">
          <template v-for="(option, index) in optionsRound">
            <div v-bind:key="index">
              <div>
                <b-col style="text-align: center">
                  <div class="textB11">#{{ option.text }}</div>
                  <b-row class="margin15">
                    <div class="circle circleGrey">{{ option.history[0] }}</div>
                    <div class="circle circleGrey">{{ option.history[1] }}</div>
                    <div class="circle circleGrey">{{ option.history[2] }}</div>
                    <div class="circle circleGrey">{{ option.history[3] }}</div>
                  </b-row>
                </b-col>
              </div>
            </div>
          </template>
        </VueSlickCarousel>
      </div>
      <br />
      <b-row>
        <b-col
          ><p class="textB" style="text-align: center">
            ครั้งที่ #{{ RoundLotto }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          ><center>
            <button class="button3 button100">
              <p class="textB11" style="text-align: center">กติกา</p>
            </button>
          </center>
        </b-col>
      </b-row>
      <br />
      <countdown :end-time="nextRound" :autoStart="false" ref="vac2">
        <template v-slot:process="anyYouWantedScopName">
          <b-table-simple responsive>
            <b-tbody class="table-borderless">
              <b-tr>
                <b-td>เวลาประกาศรางวัล:</b-td>
                <b-td class="textB" style="text-align: right">
                  <span>{{ `${annonceTime}` }} </span></b-td
                >
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-table-simple responsive>
            <b-tbody class="table-borderless">
              <b-tr>
                <b-td>เหลือเวลาอีก: </b-td>
                <b-td class="textB" style="text-align: right">
                  <span v-if="anyYouWantedScopName.timeObj.ceil.h % 24 == false"
                    >{{
                      `  ${anyYouWantedScopName.timeObj.ceil.d - 1} วัน : ${
                        (anyYouWantedScopName.timeObj.ceil.h % 24) + 23
                      } ชั่วโมง : ${
                        anyYouWantedScopName.timeObj.ceil.m % 60
                      } นาที : ${
                        anyYouWantedScopName.timeObj.ceil.s % 60
                      } วินาที`
                    }}
                  </span>
                  <span v-else
                    >{{
                      `  ${anyYouWantedScopName.timeObj.ceil.d - 1} วัน : ${
                        (anyYouWantedScopName.timeObj.ceil.h % 24) - 1
                      } ชั่วโมง : ${
                        anyYouWantedScopName.timeObj.ceil.m % 60
                      } นาที : ${
                        anyYouWantedScopName.timeObj.ceil.s % 60
                      } วินาที`
                    }}
                  </span>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </template>
      </countdown>
      <!-- <b-table-simple responsive>
        <b-thead class="table-borderless">
          <b-tr>
            <b-td class="textB" style="text-align: left">รางวัลที่</b-td>
            <b-td class="textB" style="text-align: center">จำนวนเลขตรง</b-td>
            <b-td class="textB" style="text-align: right">จะได้รับ (LIKE)</b-td>
          </b-tr>
        </b-thead>
        <b-tbody class="table-borderless">
          <b-tr>
            <b-td class="textB" style="text-align: left">รางวัลที่ 1</b-td>
            <b-td class="textB" style="text-align: center">4 ตัว</b-td>
            <b-td class="textB" style="text-align: right">{{ Number(totalAmount*allocationfirst/100).toLocaleString() }} </b-td>
          </b-tr>
          <b-tr>
            <b-td class="textB" style="text-align: left">รางวัลที่ 2</b-td>
            <b-td class="textB" style="text-align: center">3 ตัว</b-td>
            <b-td class="textB" style="text-align: right">{{ Number(totalAmount*allocationsec/100).toLocaleString() }} </b-td>
          </b-tr>
          <b-tr>
            <b-td class="textB" style="text-align: left">รางวัลที่ 3</b-td>
            <b-td class="textB" style="text-align: center">2 ตัว</b-td>
            <b-td class="textB" style="text-align: right">{{ Number(totalAmount*allocationthird/100).toLocaleString() }} </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple> -->
      <b-table-simple responsive>
        <b-thead class="table-borderless">
          <b-tr>
            <b-td class="textB" style="width: 30.33%; text-align: left"
              >รางวัลที่</b-td
            >
            <b-td class="textB" style="width: 36.33%; text-align: center"
              >จำนวนเลขตรง</b-td
            >
            <b-td class="textB" style="width: 30.33%; text-align: right"
              >จะได้รับ (LIKE)</b-td
            >
          </b-tr>
        </b-thead>
        <b-tbody class="table-borderless">
          <b-tr>
            <b-td class="textB" style="text-align: left">รางวัลที่ 1</b-td>
            <b-td class="textB" style="text-align: center">4 ตัว</b-td>
            <b-td class="textB" style="text-align: right"
              >{{
                Number((totalAmount * allocationfirst) / 100).toLocaleString()
              }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="textB" style="text-align: left">รางวัลที่ 2</b-td>
            <b-td class="textB" style="text-align: center">3 ตัว</b-td>
            <b-td class="textB" style="text-align: right"
              >{{
                Number((totalAmount * allocationsec) / 100).toLocaleString()
              }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="textB" style="text-align: left">รางวัลที่ 3</b-td>
            <b-td class="textB" style="text-align: center">2 ตัว</b-td>
            <b-td class="textB" style="text-align: right"
              >{{
                Number((totalAmount * allocationthird) / 100).toLocaleString()
              }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div class="row justify-content-center">
        <div class="col-sm-4 col-md-8" style="margin-top: 2em">
          <div class="card cardRadius cardBlack">
            <div class="card-body">
              <b-table-simple responsive>
                <b-tbody class="table-borderless">
                  <b-tr>
                    <b-td class="textW">จำนวนตั๋วของคุณ</b-td>
                    <b-td class="textW" style="text-align: right">
                      {{ Number(balanceTicket).toLocaleString() }}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <div
                v-if="yourlotto.length <= 0"
                class="textB"
                style="text-align: center"
              >
                ยังไม่มีการท้ายเลข
              </div>

              <div v-if="!drawingPhase">
                <b-row v-if="!approvedBuy">
                  <b-col> </b-col>
                  <button
                    v-on:click="approveBuy"
                    class="button5 button100 textB"
                  >
                    อนุมัติการสุ่ม
                  </button>
                  <b-col> </b-col>
                </b-row>
                <b-row v-if="approvedBuy" style="text-align: center">
                  <b-col>
                    <button
                      v-b-modal.bv-modal-show-disable-buy-ticket
                      class="button5 button100 textB"
                    >
                      ยืนยัน & สร้างเลขล็อตโต้ของฉัน
                    </button></b-col
                  >
                </b-row>
              </div>
              <b-row v-else>
                <b-col> </b-col>
                <button class="button5 button100 textB">ยังไม่ถึงเวลา</button>
                <b-col> </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!reloadRoundNow"
        class="textB11"
        style="text-align: center"
      ></div>
      <div v-else>
        <div class="row justify-content-center">
          <div class="col-sm-4 col-md-8" style="margin-top: 2em">
            <div class="card cardRadius cardGrey">
              <div class="card-body">
                <div
                  v-if="!reloadRoundNow"
                  class="textB11"
                  style="text-align: center"
                >
                  กำลังโหลดข้อมูล...
                </div>
                <div v-else>
                  <div
                    v-for="numb in reData(yourlotto)"
                    :key="numb.tokendId"
                    role="alert"
                  >
                    <b-row class="row justify-content-center">
                      <div
                        v-for="(item, index) in numb"
                        :key="index"
                        role="alert"
                      >
                        <!-- <h5 v-if="numb.reward > 0">{{ numb.number }} รางวัล {{ numb.reward/10e17 }} LIKE</h5> -->
                        <div
                          v-if="yourlotto.length <= 0"
                          class="textB"
                          style="text-align: center"
                        >
                          ยังไม่มีการท้ายเลข
                        </div>
                        <b-col>
                          <div class="text15WB">{{ item.number.join("") }}</div>
                        </b-col>
                      </div>
                    </b-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.radius-curve {
  border-radius: 25px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: none !important;
}
.title-card {
  margin-bottom: 1em;
  font-weight: 600;
}
</style>
<script>
import { mapGetters } from "vuex";
// import  Web3  from "web3";
import { ethers } from "ethers";
import firebase from "firebase/app";
import abi from "../abi/abi";
import contractAddr from "../contract/contract";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import _ from "underscore";

export default {
  data() {
    return {
      computed: {},
      settings: {
        arrows: false,
        dots: false,
        focusOnSelect: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
      },
      mnemonic: "",
      // url: 'https://eth-kovan.alchemyapi.io/v2/ffcj9cKD-j_9HUgbtrCWclsHn0lip8Jo',
      // hdPath: "m/44'/60'/0'/0/0",
      // contractLottery: "0xd242C356c172863531d0a9D7F8ADEa59c49fb074",
      // contract_NFT: "0x611C52b7433e596ad179A530CB31fDdE3745c0Fb",
      // lock_addr: "0x7F0F47EA3C01E9E2C7220634451572f8C10FAE4E",
      // erc20_addr: "0x96D1125c356b4dF0Bad5b84b1ba043Fb621b21a3",
      url: "https://rpc.tomochain.com",
      // url: 'https://rpc.tomochain.likewallet.io',
      // url: 'https://wss.tomochain.likewallet.io',
      // url: 'http://192.46.225.147:8545',
      hdPath: "m/44'/60'/0'/0/0",
      contractLottery: contractAddr.lottery_addr,
      contract_NFT: contractAddr.nft_addr,
      lock_addr: contractAddr.lock_addr,
      erc20_addr: contractAddr.erc20_addr,
      abiNFT: abi.abiNFT,
      abiContract: abi.abiContract,
      abiLottery: abi.abiLottery,
      abiLock: abi.abiLock,
      balanceLike: 0,
      balanceLockLike: 0,
      balanceTicket: 0,
      lockOption: {
        option: [],
      },
      lockDetails: {
        option: [],
      },
      messageLoading: "",
      lockOptionModel: null,
      approved: false,
      checkingApprove: null,
      checkingDeposit: null,
      spinnerApprove: false,
      spinnerDeposit: false,
      ticketSelect: 0,
      amountLock: 0,
      amountBuy: 0,
      spinnerBuy: false,
      RoundLotto: 0,
      round: 0,
      yourlotto: [],
      yourlottoRound: [],
      approvedBuy: false,
      checkingApproveBuy: null,
      leftTicket: 0,
      winnerNum1: "0",
      winnerNum2: "0",
      winnerNum3: "0",
      winnerNum4: "0",
      totalAmount: 0,
      matchfour: 0,
      matchthree: 0,
      matchtwo: 0,
      matchfourCur: 0,
      matchthreeCur: 0,
      matchtwoCur: 0,
      prizefour: 0,
      prizethree: 0,
      prizetwo: 0,
      totalPrev: 0,
      allocationfirst: 0,
      allocationsec: 0,
      allocationthird: 0,
      rawWinner1: "0",
      rawWinner2: "0",
      rawWinner3: "0",
      rawWinner4: "0",
      drawingPhase: false,
      multiClaim: false,
      tokenList: [],
      spinnerClaim: false,
      nextRound: 0,
      boxTwo: "",
      messageBox: "",
      titleBox: "",
      optionsRound: [],
      selectRound: "",
      reload: false,
      reloadRoundNow: false,
      reloadPrevNow: false,
      annonceTime: "",
    };
  },
  components: { VueSlickCarousel },
  mounted() {
    this.$bvModal.show("bv-modal-loading");
    this.messageLoading = "กำลังเตรียมพร้อม";
    const db = firebase.firestore();
    // /lotto/round/timer/next
    db.collection("lotto")
      .doc("round")
      .collection("timer")
      .doc("next")
      .get()
      .then(async (timer) => {
        console.log(this.nextRound);
        // this.nextRound = (parseInt(timer.data().time)*1000) - new Date().getTime();
        this.datelotto = Date(timer.data().time * 1000);
        this.nextRound = parseInt(timer.data().time) * 1000;
        let dateAnnonceTime = new Date(timer.data().time * 1000);
        let dateISO = dateAnnonceTime.toISOString().split("T")[0];
        let thaiDate = await this.DateinterToDateENShort(dateISO);
        let timeSplit = dateAnnonceTime.toTimeString().split(" GMT+")[0];
        this.annonceTime = thaiDate + " " + timeSplit;
        console.log("nextRound" + this.nextRound);
        //   this.timeShowlotto = moment( this.datelotto ).format('DD MMM YYYY hh:mm');
        //    this.dayRound = moment( this.datelotto ).format('DD');
        //    const date = new Date(moment( this.datelotto ).format('YYYY'), moment( this.datelotto ).format('MM')-1, moment( this.datelotto ).format('DD'))
        //   this.dayRound = date.toLocaleDateString('th-TH', {
        //       year: 'numeric',
        //       month: 'long',
        //       day: 'numeric',
        //       weekday: 'long',
        //     })
        //  console.log("year"+  this.dayRound);

        console.log(this.nextRound);
        const sleep = (milliseconds) => {
          return new Promise((resolve) => setTimeout(resolve, milliseconds));
        };
        sleep(1000).then((start) => {
          console.log(start);
          this.$refs.vac2.startCountdown(true);
        });
      });
    this.messageLoading = "กำลังเข้าระบบ";
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.messageLoading = "กำลังล็อคอิน";
        user.getIdToken().then((idToken) => {
          this.axios
            .post("https://new.likepoint.io/signInWithTokenByWeb", {
              token: idToken,
            })
            .then((response) => {
              this.messageLoading = "กำลังเตรียมกระเป๋า";
              // let token = JSON.parse(response);
              console.log(response);
              // console.log(token);
              var finalData = response.data.seed.replace(/\\/g, "");
              this.mnemonic = atob(finalData);

              this.initState();
            });
        });

        // console.log(user);
        // ...
      } else {
        // User is signed out
        // ...
      }
    });
  },
  methods: {
    randomIntFromInterval(min, max) {
      // min and max included
      console.log("interval");
      return Math.floor(Math.random() * (max - min + 1) + min);
    },

    changeRound() {
      if (this.selectRound !== undefined) {
        // console.log(event);

        let provider = new ethers.providers.JsonRpcProvider(this.url);
        // let hdPath = "m/44'/60'/0'/0/0";
        let walletMnemonic = ethers.Wallet.fromMnemonic(
          this.mnemonic,
          this.hdPath
        );

        let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(
          provider
        );
        console.log(wallet.address);
        // const c_erc20 = new ethers.Contract(this.erc20_addr, this.abiContract, wallet);
        // const c_lock = new ethers.Contract(this.lock_addr, this.abiLock, wallet);
        const c_NFT = new ethers.Contract(
          this.contract_NFT,
          this.abiNFT,
          wallet
        );
        const lottery = new ethers.Contract(
          this.contractLottery,
          this.abiLottery,
          wallet
        );

        this.lottoRound(lottery, this.selectRound);
        this.lottoNumber(wallet, c_NFT, lottery, this.selectRound);
      }
    },
    lottoNumber(wallet, c_NFT, lottery, round) {
      console.log("lottoNumber");
      c_NFT.balanceOf(wallet.address).then(async (data) => {
        // console.log(data);
        this.yourlottoRound = [];
        this.reloadPrevNow = false;
        for (let i = 0; i < parseInt(data.toString()); i++) {
          c_NFT.tokenOfOwnerByIndex(wallet.address, i).then((x) => {
            c_NFT.getLotteryIssueIndex(x).then((lottoIndex, index = x) => {
              if (
                parseInt(lottoIndex.toString()) === parseInt(round.toString())
              ) {
                console.log(lottoIndex);
                console.log(round);
                c_NFT
                  .getLotteryNumbers(index)
                  .then(async (number, tokenIdIndex = index) => {
                    // console.log('lottory number ' + number);
                    lottery
                      .getRewardView(tokenIdIndex)
                      .then((rewards, tokenNumber = tokenIdIndex) => {
                        let numberSet;
                        // console.log('waiting rewards' + tokenNumber +" rewards" + rewards);
                        numberSet = {
                          number: number,
                          tokendId: tokenNumber.toString(),
                          reward: rewards,
                        };
                        this.yourlottoRound.push(numberSet);
                        this.reloadPrevNow = true;

                        console.log(
                          "yourLottoRound" + JSON.stringify(this.yourlottoRound)
                        );
                      });
                  });
              }
            });
          });
        }
        this.reloadPrevNow = true;
        this.messageLoading = "เสร็จสิ้น";
      });
    },
    multipleClaimButton: async function (event) {
      this.$bvModal.show("bv-modal-loading");
      console.log(this.tokenList);
      this.messageLoading = "กำลังดำเนินการ...";
      // this.spinnerClaim = true;
      let provider = new ethers.providers.JsonRpcProvider(this.url);
      // let hdPath = "m/44'/60'/0'/0/0";
      let walletMnemonic = ethers.Wallet.fromMnemonic(
        this.mnemonic,
        this.hdPath
      );

      let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(
        provider
      );
      console.log(wallet.address);
      // const c_erc20 = new ethers.Contract(this.erc20_addr, this.abiContract, wallet);
      // const c_lock = new ethers.Contract(this.lock_addr, this.abiLock, wallet);
      // const c_NFT = new ethers.Contract(this.contract_NFT, this.abiNFT, wallet);
      const lottery = new ethers.Contract(
        this.contractLottery,
        this.abiLottery,
        wallet
      );
      console.log(event);

      lottery
        .multiClaim(this.tokenList, { gasPrice: 10e9, gasLimit: 1000000 })
        .then((tx) => {
          console.log(tx);
          // const inter = new ethers.utils.Interface(this.abiLottery);
          // const decodeTransaction = inter.parseTransaction({ data: tx.data, value: tx.value});
          // console.log(decodeTransaction);
          // this.spinnerClaim = false;
          this.messageLoading = "claim สำเร็จ";
          // alert("claim สำเร็จ");
        });
    },
    withdraw: function (id) {
      console.log(`id withdraw is ${id}`);
      let provider = new ethers.providers.JsonRpcProvider(this.url);
      // let hdPath = "m/44'/60'/0'/0/0";
      let walletMnemonic = ethers.Wallet.fromMnemonic(
        this.mnemonic,
        this.hdPath
      );

      let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(
        provider
      );
      console.log(wallet.address);
      // const c_erc20 = new ethers.Contract(this.erc20_addr, this.abiContract, wallet);
      const c_lock = new ethers.Contract(this.lock_addr, this.abiLock, wallet);
      // const c_NFT = new ethers.Contract(this.contract_NFT, this.abiNFT, wallet);
      // const lottery = new ethers.Contract(this.contractLottery, this.abiLottery, wallet);
      c_lock.timelockhold(wallet.address, parseInt(id)).then((balance) => {
        console.log("withdraw " + balance.amount);
        c_lock.withdrawLockup(balance.amount, parseInt(id)).then((withdraw) => {
          console.log(withdraw);
          const sleep = (milliseconds) => {
            return new Promise((resolve) => setTimeout(resolve, milliseconds));
          };

          sleep(4000).then((data) => {
            console.log(data);
            this.reloadLottory();
          });
          console.log("withdraw success");
        });
      });
    },
    buyticket: function (event) {
      this.messageLoading = "กำลังเริ่มซื้อตั๋ว";
      this.$bvModal.show("bv-modal-loading");

      console.log(event);
      this.spinnerBuy = true;
      let provider = new ethers.providers.JsonRpcProvider(this.url);
      // let hdPath = "m/44'/60'/0'/0/0";
      let walletMnemonic = ethers.Wallet.fromMnemonic(
        this.mnemonic,
        this.hdPath
      );

      let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(
        provider
      );
      console.log(wallet.address);
      const c_erc20 = new ethers.Contract(
        this.erc20_addr,
        this.abiContract,
        wallet
      );
      const c_lock = new ethers.Contract(this.lock_addr, this.abiLock, wallet);
      const c_NFT = new ethers.Contract(this.contract_NFT, this.abiNFT, wallet);
      const lottery = new ethers.Contract(
        this.contractLottery,
        this.abiLottery,
        wallet
      );

      let tricketNumber = [];
      let ticketUser = 0;
      lottery.issueIndex().then(async (issueIndex) => {
        this.messageLoading = "กำลังเช็ครอบ";
        c_lock.getAmountLottery(wallet.address).then((getTicket) => {
          this.messageLoading = "กำลังเช็คตั๋ว";
          console.log("55555555");
          console.log(getTicket.toString());
          console.log(issueIndex);
          if (parseInt(getTicket.toString()) > 0) {
            lottery.countTicket(wallet.address, issueIndex).then((ticket) => {
              this.messageLoading = "กำลังนับตั๋ว";
              console.log(parseInt(ticket.leftTicket.toString()));
              //นับตั๋วคงเหลือ
              if (parseInt(ticket.leftTicket.toString()) > 0) {
                ticketUser = parseInt(ticket.leftTicket.toString());
              } else {
                if (parseInt(ticket.initTicket.toString()) > 0) {
                  ticketUser = 0;
                } else {
                  ticketUser = parseInt(getTicket.toString());
                }
              }

              if (ticketUser > 0) {
                c_erc20.balanceOf(wallet.address).then((walletBalance) => {
                  console.log(walletBalance / 10e17);
                  console.log(walletBalance / 10e17);
                  if (walletBalance / 10e17 > parseInt(ticketUser.toString())) {
                    this.messageLoading = "กำลังโหลดข้อมูล";
                    for (let i = 0; i < parseInt(ticketUser.toString()); i++) {
                      let number1 = this.randomIntFromInterval(1, 9);
                      let number2 = this.randomIntFromInterval(1, 9);
                      let number3 = this.randomIntFromInterval(1, 9);
                      let number4 = this.randomIntFromInterval(1, 9);
                      let x = [number1, number2, number3, number4];
                      console.log(x);
                      tricketNumber.push(x);
                    }
                    console.log(tricketNumber);
                    lottery
                      .multiBuyLock(
                        ethers.utils.parseEther("1"),
                        tricketNumber,
                        { gasLimit: 30000000 }
                      )
                      .then((tx) => {
                        console.log(tx);
                        this.messageLoading = "กำลังเตรียมพร้อม";

                        const sleep = (milliseconds) => {
                          return new Promise((resolve) =>
                            setTimeout(resolve, milliseconds)
                          );
                        };

                        sleep(4000).then((da) => {
                          console.log(da);

                          c_NFT.balanceOf(wallet.address).then(async (data) => {
                            // console.log(data);
                            this.yourlotto = [];
                            let drawing = await lottery.drawingPhase();
                            for (
                              let i = 0;
                              i < parseInt(data.toString());
                              i++
                            ) {
                              c_NFT
                                .tokenOfOwnerByIndex(wallet.address, i)
                                .then((x) => {
                                  console.log("tokenByIndex" + x);
                                  c_NFT
                                    .getLotteryIssueIndex(x)
                                    .then((lottoIndex, index = x) => {
                                      console.log("lottoindex " + lottoIndex);
                                      console.log("Roundlotto " + issueIndex);
                                      console.log("match Index" + index);
                                      c_NFT
                                        .getLotteryNumbers(index)
                                        .then(
                                          async (
                                            number,
                                            tokenIdIndex = index
                                          ) => {
                                            lottery
                                              .countTicket(
                                                wallet.address,
                                                issueIndex
                                              )
                                              .then((left) => {
                                                this.leftTicket =
                                                  left.leftTicket;
                                                this.spinnerBuy = false;
                                              });

                                            if (
                                              lottoIndex < issueIndex ||
                                              (lottoIndex == issueIndex &&
                                                drawing == true) ||
                                              drawing == true
                                            ) {
                                              // console.log('lottory number ' + number);
                                              lottery
                                                .getRewardView(tokenIdIndex)
                                                .then(
                                                  (
                                                    rewards,
                                                    tokenNumber = tokenIdIndex
                                                  ) => {
                                                    let numberSet;
                                                    // console.log('waiting rewards' + tokenNumber +" rewards" + rewards);

                                                    if (rewards / 10e17 > 0) {
                                                      c_NFT
                                                        .getClaimStatus(
                                                          tokenNumber
                                                        )
                                                        .then((claimed) => {
                                                          if (claimed) {
                                                            rewards = 0;
                                                          }
                                                          if (rewards > 0) {
                                                            console.log(
                                                              "multi true"
                                                            );
                                                            this.multiClaim = true;
                                                            this.tokenList.push(
                                                              parseInt(
                                                                tokenNumber.toString()
                                                              )
                                                            );
                                                          }
                                                          numberSet = {
                                                            number: number,
                                                            tokendId:
                                                              tokenNumber.toString(),
                                                            reward: rewards,
                                                          };
                                                        });
                                                      console.log(number);
                                                      this.$bvModal.hide(
                                                        "bv-modal-loading"
                                                      );
                                                    } else {
                                                      numberSet = {
                                                        number: number,
                                                        tokendId:
                                                          tokenNumber.toString(),
                                                        reward: 0,
                                                      };
                                                      this.$bvModal.hide(
                                                        "bv-modal-loading"
                                                      );
                                                    }
                                                    if (
                                                      parseInt(lottoIndex) ==
                                                      parseInt(issueIndex)
                                                    ) {
                                                      this.yourlotto.push(
                                                        numberSet
                                                      );
                                                      this.$bvModal.hide(
                                                        "bv-modal-loading"
                                                      );
                                                    }
                                                  }
                                                );
                                            } else {
                                              let numberSet = {
                                                number: number,
                                                tokendId:
                                                  tokenIdIndex.toString(),
                                                reward: 0,
                                              };

                                              if (
                                                parseInt(lottoIndex) ==
                                                parseInt(issueIndex)
                                              ) {
                                                this.yourlotto.push(numberSet);
                                              }

                                              this.$bvModal.hide(
                                                "bv-modal-loading"
                                              );
                                            }
                                          }
                                        );
                                    });
                                });
                            }
                            this.reloadRoundNow = true;
                            this.messageLoading = "เสร็จสิ้น";
                            this.$bvModal.hide("bv-modal-loading");
                            this.spinnerBuy = false;
                          });
                        });
                      });
                  } else {
                    //alert('คุณต้องมี Like ขั้นต่ำในกระเป๋า มากกว่า' + this.amountBuy + ' LIKE');

                    this.messageLoading = "คุณต้องมี LIKE ติดกระเป๋า..";
                    this.spinnerBuy = false;
                  }
                });
              } else {
                this.messageLoading = "คุณทายไปแล้ว..";
                this.spinnerBuy = false;
              }
            });
          } else {
            console.log("erorr");
            this.messageLoading = "คุณไม่มีตั๋ว";

            this.spinnerBuy = false;
          }
        });
      });
    },
    deposit: function (event) {
      console.log(event);
      this.messageLoading = "กำลังโหลด..";
      this.$bvModal.show("bv-modal-loading");

      this.spinnerDeposit = true;
      let provider = new ethers.providers.JsonRpcProvider(this.url);
      // let hdPath = "m/44'/60'/0'/0/0";
      let walletMnemonic = ethers.Wallet.fromMnemonic(
        this.mnemonic,
        this.hdPath
      );

      let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(
        provider
      );
      console.log(wallet.address);
      // const c_erc20 = new ethers.Contract(this.erc20_addr, this.abiContract, wallet);
      const c_lock = new ethers.Contract(this.lock_addr, this.abiLock, wallet);
      // const c_NFT = new ethers.Contract(this.contract_NFT, this.abiNFT, wallet);
      // const lottery = new ethers.Contract(this.contractLottery, this.abiLottery, wallet);
      // if(confirm('คุณต้องการล็อค '+ this.amountLock +' LIKE ?')){
      console.log("ok raw");
      c_lock
        .depositLockup(
          ethers.utils.parseEther(this.amountLock.toString()),
          this.lockOptionModel
        )
        .then((hash) => {
          console.log(hash);
          const sleep = (milliseconds) => {
            return new Promise((resolve) => setTimeout(resolve, milliseconds));
          };
          sleep(4000).then((da) => {
            console.log(da);

            this.reloadLottory();
            this.spinnerDeposit = false;
            this.messageLoading = "เสร็จสิ้น";
            this.$bvModal.hide("bv-modal-loading");
          });
        });
      // }else {
      //   this.spinnerDeposit = false;
      // }
    },

    approve: function (event) {
      console.log(event);
      this.messageLoading = "กำลังโหลด..";
      this.$bvModal.show("bv-modal-loading");
      this.spinnerApprove = true;
      let provider = new ethers.providers.JsonRpcProvider(this.url);
      // let hdPath = "m/44'/60'/0'/0/0";
      let walletMnemonic = ethers.Wallet.fromMnemonic(
        this.mnemonic,
        this.hdPath
      );

      let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(
        provider
      );
      console.log(wallet.address);
      const c_erc20 = new ethers.Contract(
        this.erc20_addr,
        this.abiContract,
        wallet
      );
      // const c_lock = new ethers.Contract(this.lock_addr, this.abiLock, wallet);
      // const c_NFT = new ethers.Contract(this.contract_NFT, this.abiNFT, wallet);
      // const lottery = new ethers.Contract(this.contractLottery, this.abiLottery, wallet);

      c_erc20.populateTransaction
        .approve(
          this.lock_addr,
          "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
        )
        .then((rawTransaction) => {
          console.log(rawTransaction);
          // if(confirm('is approve locklike?')){
          console.log("ok raw");
          c_erc20
            .approve(
              this.lock_addr,
              "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
            )
            .then((data) => {
              console.log(data);
              // this.messageLoading = 'เสร็จสิ้น';
              // this.$bvModal.hide('bv-modal-loading');
              this.checkingApprove = setInterval(() => {
                this.checkApprove();
              }, 6000);
            });
          // }else {
          //   this.spinnerApprove = false;
          // }
        });
    },
    approveBuy: function (event) {
      console.log(event);
      this.spinnerBuy = true;
      this.messageLoading = "กำลังโหลด..";
      this.$bvModal.show("bv-modal-loading");
      let provider = new ethers.providers.JsonRpcProvider(this.url);
      // let hdPath = "m/44'/60'/0'/0/0";
      let walletMnemonic = ethers.Wallet.fromMnemonic(
        this.mnemonic,
        this.hdPath
      );

      let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(
        provider
      );
      console.log(wallet.address);
      const c_erc20 = new ethers.Contract(
        this.erc20_addr,
        this.abiContract,
        wallet
      );
      // const c_lock = new ethers.Contract(this.lock_addr, this.abiLock, wallet);
      // const c_NFT = new ethers.Contract(this.contract_NFT, this.abiNFT, wallet);
      // const lottery = new ethers.Contract(this.contractLottery, this.abiLottery, wallet);

      c_erc20.populateTransaction
        .approve(
          this.contractLottery,
          "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
        )
        .then((rawTransaction) => {
          console.log(rawTransaction);

          // if(confirm('is approve lotto?')){
          console.log("ok raw");
          c_erc20
            .approve(
              this.contractLottery,
              "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
            )
            .then((data) => {
              console.log(data);
              this.messageLoading = "เสร็จสิ้น";

              this.checkingApproveBuy = setInterval(() => {
                this.checkApproveBuy();
              }, 6000);
            });
          // }else {
          //   this.spinnerBuy = false;
          // }
        });
    },
    checkApprove: function (event) {
      console.log(event);
      let provider = new ethers.providers.JsonRpcProvider(this.url);
      // let hdPath = "m/44'/60'/0'/0/0";
      let walletMnemonic = ethers.Wallet.fromMnemonic(
        this.mnemonic,
        this.hdPath
      );

      let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(
        provider
      );
      console.log(wallet.address);
      const c_erc20 = new ethers.Contract(
        this.erc20_addr,
        this.abiContract,
        wallet
      );

      c_erc20.allowance(wallet.address, this.lock_addr).then((allowance) => {
        if (allowance > 0) {
          this.approved = true;
          this.spinnerApprove = false;
          this.$bvModal.hide("bv-modal-loading");
          clearInterval(this.checkingApprove);
        }
      });
    },
    checkApproveBuy: function (event) {
      console.log(event);
      let provider = new ethers.providers.JsonRpcProvider(this.url);
      // let hdPath = "m/44'/60'/0'/0/0";
      let walletMnemonic = ethers.Wallet.fromMnemonic(
        this.mnemonic,
        this.hdPath
      );

      let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(
        provider
      );
      console.log(wallet.address);
      const c_erc20 = new ethers.Contract(
        this.erc20_addr,
        this.abiContract,
        wallet
      );

      c_erc20
        .allowance(wallet.address, this.contractLottery)
        .then((allowance) => {
          if (allowance > 0) {
            this.approveBuy = true;
            this.spinnerBuy = false;
            this.approvedBuy = true;
            this.$bvModal.hide("bv-modal-loading");
            clearInterval(this.checkingApproveBuy);
          }
        });
    },
    reloadLottory: async function (event) {
      console.log(event);
      let provider = new ethers.providers.JsonRpcProvider(this.url);
      // let hdPath = "m/44'/60'/0'/0/0";
      let walletMnemonic = ethers.Wallet.fromMnemonic(
        this.mnemonic,
        this.hdPath
      );

      let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(
        provider
      );
      console.log(wallet.address);
      // const c_erc20 = new ethers.Contract(this.erc20_addr, this.abiContract, wallet);
      const c_lock = new ethers.Contract(this.lock_addr, this.abiLock, wallet);
      // const c_NFT = new ethers.Contract(this.contract_NFT, this.abiNFT, wallet);
      // const lottery = new ethers.Contract(this.contractLottery, this.abiLottery, wallet);

      c_lock.getAmountLottery(wallet.address).then((ticket) => {
        console.log(ticket.toString());
        this.balanceTicket = ticket;
      });
      let option = await c_lock.maxOption();

      let balance = 0;

      for (let i = 0; i < parseInt(option.toString()); i++) {
        console.log(option);
        let pointX = await c_lock.timelockhold(wallet.address, i);
        balance += pointX.amount / 10e17;
        this.lockDetails.option = [];
        console.log(pointX);
        let dateTime;
        if (parseInt(pointX.expire.toString()) > 0) {
          dateTime = new Date(
            1000 * parseInt(pointX.expire.toString())
          ).toString();
          let date = new Date();
          let dateNow = date.getTime() / 1000;
          let status = "no";
          if (dateNow >= parseInt(pointX.expire.toString())) {
            status = "yes";
          }
          let lockdata = {
            status: status,
            expire: dateTime,
            amount: pointX.amount / 10e17,
            id: i,
          };

          console.log(lockdata);
          if (lockdata.amount > 0) {
            this.lockDetails.option.push(lockdata);
          }

          console.log(this.lockDetails);
        }
      }
      this.balanceLockLike = balance;
    },
    lottoRound(lottery, d) {
      lottery.historyNumbers(d, 0).then((win1) => {
        this.winnerNum1 = win1;
      });
      lottery.historyNumbers(d, 1).then((win2) => {
        this.winnerNum2 = win2;
      });
      lottery.historyNumbers(d, 2).then((win3) => {
        this.winnerNum3 = win3;
      });
      lottery.historyNumbers(d, 3).then((win4) => {
        this.winnerNum4 = win4;
      });

      console.log("prev round");

      lottery.historyAmount(d, 0).then((to1) => {
        this.totalPrev = to1 / 10e17;
      });
      lottery.historyAmount(d, 1).then((to2) => {
        this.matchfour = to2 / 10e17;
      });
      lottery.historyAmount(d, 2).then((to3) => {
        this.matchthree = to3 / 10e17;
      });
      lottery.historyAmount(d, 3).then((to4) => {
        this.matchtwo = to4 / 10e17;
      });
    },
    amountTicket(amount) {
      this.ticketSelect = amount;
    },
    initState() {
      this.messageLoading = "กำลังโหลดข้อมูล";
      //เรียกรอบล่าสุด
      // let mnemonic = 'lock direct tray ensure net price pattern original scout region deer history';
      // let url = 'https://eth-kovan.alchemyapi.io/v2/ffcj9cKD-j_9HUgbtrCWclsHn0lip8Jo';
      let provider = new ethers.providers.JsonRpcProvider(this.url);
      // let hdPath = "m/44'/60'/0'/0/0";
      let walletMnemonic = ethers.Wallet.fromMnemonic(
        this.mnemonic,
        this.hdPath
      );
      this.messageLoading = "กำลังเชื่อมต่อ";
      let wallet = new ethers.Wallet(walletMnemonic.privateKey).connect(
        provider
      );
      console.log(wallet.address);
      const c_erc20 = new ethers.Contract(
        this.erc20_addr,
        this.abiContract,
        wallet
      );
      const c_lock = new ethers.Contract(this.lock_addr, this.abiLock, wallet);
      const c_NFT = new ethers.Contract(this.contract_NFT, this.abiNFT, wallet);
      const lottery = new ethers.Contract(
        this.contractLottery,
        this.abiLottery,
        wallet
      );
      console.log(c_erc20);
      console.log(c_lock);
      console.log(c_NFT);
      console.log(lottery);

      setInterval(async function () {
        lottery.drawingPhase().then((draw) => {
          this.drawingPhase = draw;
        });
        // console.log('drawingPhase ' + this.drawingPhase);
      }, 3000);
      this.messageLoading = "กำลังโหลดกระเป๋า";
      c_erc20.balanceOf(wallet.address).then((balance) => {
        // console.log(balance/10e17);
        this.balanceLike = balance / 10e17;
      });

      c_erc20.allowance(wallet.address, this.lock_addr).then((allowance) => {
        if (allowance > 0) {
          this.approved = true;
        }
      });

      c_erc20
        .allowance(wallet.address, this.contractLottery)
        .then((allowance) => {
          if (allowance > 0) {
            this.approvedBuy = true;
          }
        });
      this.messageLoading = "กำลังตรวจสอบ";
      lottery.issueIndex().then(async (d) => {
        console.log("issueIndex " + (d - 1));
        this.lottoRound(lottery, d - 1);

        lottery.allocation(0).then((lot1) => {
          this.allocationfirst = lot1;
        });
        lottery.allocation(1).then((lot2) => {
          this.allocationsec = lot2;
        });
        lottery.allocation(2).then((lot3) => {
          this.allocationthird = lot3;
        });

        this.RoundLotto = d.toString();
        this.round = d - 1;
        this.selectRound = this.round;
        // console.log("round up" + d.toString());
        this.optionsRound = [];
        let listData = [];
        this.changeRound();
        for (let x = 0; x < 9; x++) {
          // let sum = 5-1;
          let i = this.round--;
          console.log("round" + x + i);
          //  console.log();

          let history = [];
          await lottery.historyNumbers(i, 0).then(async (Winner1) => {
            this.rawWinner1 = Winner1;
            history.push(this.rawWinner1);
            await lottery.historyNumbers(i, 1).then(async (Winner2) => {
              this.rawWinner2 = Winner2;
              history.push(this.rawWinner2);
              await lottery.historyNumbers(i, 2).then(async (Winner3) => {
                this.rawWinner3 = Winner3;
                history.push(this.rawWinner3);
                await lottery.historyNumbers(i, 3).then(async (Winner4) => {
                  this.rawWinner4 = Winner4;
                  history.push(this.rawWinner4);
                });
              });
            });
          });

          let options = {
            text: i,
            value: i,
            history: history,
          };
          listData.push(options);
          if (parseInt((x + 1).toString()) == parseInt(d.toString())) {
            this.selectRound = d.toString();
          }
        }

        console.log("listData" + listData.length);
        this.optionsRound = listData;
        this.reload = true;
        console.log("optionsRound " + JSON.stringify(this.optionsRound));
        //console.log("XXXX " + JSON.stringify( _.chunk(this.listData,3)) );
        //console.log("start historyNumbers");

        let drawing = await lottery.drawingPhase();
        this.drawingPhase = drawing;

        // lottery.historyAmount(d, 1).then(histA1 => {
        //   this.matchfourCur = histA1/10e17;
        // })
        //         lottery.historyAmount(d, 2).then(histA2 => {
        //   this.matchthreeCur = histA2/10e17;
        // })
        //                 lottery.historyAmount(d, 3).then(histA3 => {
        //   this.matchtwoCur = histA3/10e17;
        // })

        // console.log('historyPrev ' + this.totalPrev + this.matchfour + this.matchthree + this.matchtwo);
      });
      c_lock.getOptionLength().then((options) => {
        for (let x = 0; x < options; x++) {
          console.log(x);
          c_lock.option(x).then((listOption) => {
            let lockData = {
              id: x,
              value: listOption.toString(),
              day: listOption / 86400,
            };
            this.lockOption.option.push(lockData);
            console.log(this.lockOption);
          });
        }
      });

      c_lock.getAmountLottery(wallet.address).then((ticket) => {
        console.log(ticket.toString());
        this.balanceTicket = ticket;
      });

      getLock(this);
      async function getLock(vm) {
        c_lock.maxOption().then((option) => {
          let balance = 0;
          for (let i = 0; i < parseInt(option.toString()); i++) {
            console.log(option);
            // let pointX = await c_lock.timelockhold(wallet.address, i);
            // balance += pointX.amount/10e17;
            // console.log(pointX);
            c_lock.timelockhold(wallet.address, i).then((value) => {
              balance += value.amount / 10e17;
              vm.balanceLockLike = balance;
              let dateTime;
              if (parseInt(value.expire.toString()) > 0) {
                dateTime = new Date(
                  1000 * parseInt(value.expire.toString())
                ).toString();
                let date = new Date();
                let dateNow = date.getTime() / 1000;
                let status = "no";
                if (dateNow >= parseInt(value.expire.toString())) {
                  status = "yes";
                }
                let lockdata = {
                  status: status,
                  expire: dateTime,
                  amount: value.amount / 10e17,
                  id: i,
                };

                console.log(lockdata);
                if (lockdata.amount > 0) {
                  vm.lockDetails.option.push(lockdata);
                }

                console.log(vm.lockDetails);
              }
            });
          }

          // vm.balanceLockLike = balance;
        });
      }

      lottery.issueIndex().then(async (issueIndex) => {
        c_NFT.balanceOf(wallet.address).then(async (data) => {
          // console.log(data);
          this.yourlotto = [];
          let drawing = await lottery.drawingPhase();
          for (let i = 0; i < parseInt(data.toString()); i++) {
            this.reloadRoundNow = false;
            c_NFT.tokenOfOwnerByIndex(wallet.address, i).then((x) => {
              // console.log('tokenByIndex' + x);
              c_NFT.getLotteryIssueIndex(x).then((lottoIndex, index = x) => {
                // console.log('lottoindex '+lottoIndex);
                // console.log('Roundlotto ' + issueIndex);
                // console.log('match Index' + index);
                c_NFT
                  .getLotteryNumbers(index)
                  .then(async (number, tokenIdIndex = index) => {
                    lottery
                      .countTicket(wallet.address, issueIndex)
                      .then((left) => {
                        this.leftTicket = left.leftTicket;
                        this.spinnerBuy = false;
                      });

                    if (
                      lottoIndex < issueIndex ||
                      (lottoIndex == issueIndex && drawing == true) ||
                      drawing == true
                    ) {
                      // console.log('lottory number ' + number);
                      lottery
                        .getRewardView(tokenIdIndex)
                        .then((rewards, tokenNumber = tokenIdIndex) => {
                          let numberSet;
                          console.log(
                            "waiting rewards" +
                              tokenNumber +
                              " rewards" +
                              rewards
                          );

                          if (rewards / 10e17 > 0) {
                            c_NFT
                              .getClaimStatus(tokenNumber)
                              .then((claimed) => {
                                if (claimed) {
                                  rewards = 0;
                                }
                                if (rewards > 0) {
                                  console.log("multi true");
                                  this.multiClaim = true;
                                  this.tokenList.push(
                                    parseInt(tokenNumber.toString())
                                  );
                                }
                                numberSet = {
                                  number: number,
                                  tokendId: tokenNumber.toString(),
                                  reward: rewards,
                                };
                              });
                            this.$bvModal.hide("bv-modal-loading");
                            console.log(number);
                          } else {
                            numberSet = {
                              number: number,
                              tokendId: tokenNumber.toString(),
                              reward: 0,
                            };
                            this.$bvModal.hide("bv-modal-loading");
                          }
                          console.log(numberSet);
                          if (parseInt(lottoIndex) == parseInt(issueIndex)) {
                            this.yourlotto.push(numberSet);
                            this.reloadRoundNow = true;
                          }
                          this.$bvModal.hide("bv-modal-loading");
                        });
                    } else {
                      this.$bvModal.hide("bv-modal-loading");
                      let numberSet = {
                        number: number,
                        tokendId: tokenIdIndex.toString(),
                        reward: 0,
                      };
                      console.log(numberSet);
                      if (parseInt(lottoIndex) == parseInt(issueIndex)) {
                        this.yourlotto.push(numberSet);
                        this.reloadRoundNow = true;
                      }
                    }
                  });
              });
            });
          }
          this.$bvModal.hide("bv-modal-loading");
        });
      });

      lottery.totalAmount().then((amount) => {
        this.totalAmount = (amount / 10e17).toString();
      });
    },
    reData(items) {
      console.log("reData" + JSON.stringify(_.chunk(items, 3)));
      return _.chunk(items, 3);
    },
    async DateinterToDateENShort(date) {
      // แปลงวันที่ จาก 2020-03-02 เป็นแบบนี้ 08 ต.ค. 62
      try {
        var year = (parseInt(date.split("-")[0]) + 543).toString();
        var monthCont = parseInt(date.split("-")[1]) - 1;
        var day = date.split("-")[2];
        var monthShort = [
          "มกราคม",
          "กุมภาพันธ์",
          "มีนาคม",
          "เมษายน",
          "พฤษภาคม",
          "มิถุนายน",
          "กรกฎาคม",
          "สิงหาคม",
          "กันยายน",
          "ตุลาคม",
          "พฤศจิกายน",
          "ธันวาคม",
        ];
        // var monthShort = [
        //   "Jan",
        //   "Feb",
        //   "Mar",
        //   "Apr",
        //   "May",
        //   "Jun",
        //   "Jul",
        //   "Aug",
        //   "Sep",
        //   "Oct",
        //   "Nov",
        //   "Dec",
        // ];
        var month = monthShort[monthCont];
        return day + " " + month + " " + year;
      } catch (error) {
        console.log(error);
        // return reject();
      }
    },
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  },
};
</script>
