import Client from "../provider/provider";

const getAddress = function(mnemonic) {
return new Promise((resolve, reject) => {
	// if(times > 5) {
		Client(mnemonic).then(wallet => {
			resolve(wallet.address);		
		
		}).catch(e=>{
			console.log(e);
			reject();
		});		
	// }else{
	// 	reject('error');
	// }

});
}

export default getAddress