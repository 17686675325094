import Vue from "vue";
import App from "./App.vue";
// import * as firebase from "firebase";
import firebase from 'firebase/app'

import axios from 'axios'
import router from "./routes/index";
import store from "./store";
import VueAxios from 'vue-axios'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import vueAwesomeCountdown from 'vue-awesome-countdown'


require('firebase/firestore');
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/btn.css'
import './assets/css/sliderbar.css'

Vue.use(vueAwesomeCountdown, 'vac') 

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueAxios, axios)

Vue.config.productionTip = false;


const configOptions = {
  apiKey: "AIzaSyC-p2YK544BFMY2AwJVAdBI_iKpTphbuPc",
  authDomain: "newlikewallet.firebaseapp.com",
  databaseURL: "https://newlikewallet.firebaseio.com",
  projectId: "newlikewallet",
  storageBucket: "newlikewallet.appspot.com",
  messagingSenderId: "126028778629",
  appId: "1:126028778629:web:f6931072ed8ed897eaedec",
  measurementId: "G-8VFXMC2811"
};


firebase.initializeApp(configOptions);

firebase.analytics();
firebase.auth().onAuthStateChanged(user => {
  store.dispatch("fetchUser", user);
});



import Slick from 'vue-slick';
new Vue({
  components: { Slick },
  router,
  store,
  data() {
    return {
        slickOptions: {
            slidesToShow: 3,
            // Any other options that can be got from plugin documentation
        },
    };
}, // All slick methods can be used too, example here
methods: {
    next() {
        this.$refs.slick.next();
    },

    prev() {
        this.$refs.slick.prev();
    },

    reInit() {
        // Helpful if you have to deal with v-for to update dynamic lists
        this.$nextTick(() => {
            this.$refs.slick.reSlick();
        });
    },

    // Events listeners
    handleAfterChange(event, slick, currentSlide) {
        console.log('handleAfterChange', event, slick, currentSlide);
    },
    handleBeforeChange(event, slick, currentSlide, nextSlide) {
        console.log('handleBeforeChange', event, slick, currentSlide, nextSlide);
    },
    handleBreakpoint(event, slick, breakpoint) {
        console.log('handleBreakpoint', event, slick, breakpoint);
    },
    handleDestroy(event, slick) {
        console.log('handleDestroy', event, slick);
    },
    handleEdge(event, slick, direction) {
        console.log('handleEdge', event, slick, direction);
    },
    handleInit(event, slick) {
        console.log('handleInit', event, slick);
    },
    handleReInit(event, slick) {
        console.log('handleReInit', event, slick);
    },
    handleSetPosition(event, slick) {
        console.log('handleSetPosition', event, slick);
    },
    handleSwipe(event, slick, direction) {
        console.log('handleSwipe', event, slick, direction);
    },
    handleLazyLoaded(event, slick, image, imageSource) {
        console.log('handleLazyLoaded', event, slick, image, imageSource);
    },
    handleLazeLoadError(event, slick, image, imageSource) {
        console.log('handleLazeLoadError', event, slick, image, imageSource);
    },
},
  render: h => h(App)
}).$mount("#app");