<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">Dashboard</div>

          <div class="card-body">
            <div v-if="user" class="alert alert-success" role="alert">
              You are logged in!
            </div>
            <b-list-group>
              <b-list-group-item>
                <router-link to="autocompound" class="nav-link"
                  >LIKE COMPOUND</router-link
                ></b-list-group-item
              >
              <b-list-group-item
                ><router-link to="lockandearn" class="nav-link"
                  >LOCK & EARN REWARD</router-link
                ></b-list-group-item
              >
              <b-list-group-item
                ><router-link to="LPCU" class="nav-link"
                  >LPCU</router-link
                ></b-list-group-item
              >
              <!-- <b-list-group-item><router-link to="lottery" class="nav-link">Lotto</router-link></b-list-group-item> -->
              <b-list-group-item
                ><router-link to="lotteryV2" class="nav-link"
                  >Lotto Ver.2</router-link
                ></b-list-group-item
              >
              <b-list-group-item
                ><router-link to="rank" class="nav-link"
                  >Ranking Lock</router-link
                ></b-list-group-item
              >
            </b-list-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  },
};
</script>