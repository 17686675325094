<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <h1>Delete account Likewallet</h1>
        <b-card class="text-center">
          <b-row class="my-1">
            <b-col sm="4">
              <label for="input-large"><h4>Telephone Number : </h4></label>
            </b-col>
            <b-col sm="8">
              <b-form-input id="input-large" size="lg" placeholder="Enter your phone number" v-model="phoneNumber"></b-form-input>
            </b-col>

          </b-row>
          <b-row class="my-1">
            <b-col sm="4">
              <label for="input-large"><h4>Account Name : </h4></label>
            </b-col>
            <b-col sm="8">
              <b-form-input id="input-large" size="lg" placeholder="Enter your phone account" v-model="accountName"></b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="4">
              <label for="input-large"><h4>Requset : </h4></label>
            </b-col>
            <b-col sm="8">
              <b-form-select v-model="request">
                <template #first>
                  <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                </template>
                <b-form-select-option value="disable">Disable</b-form-select-option>
                <b-form-select-option value="delete">Delete</b-form-select-option>
              </b-form-select>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="4">
              <label for="input-large"><h4>Please let us know what can we improve. : </h4></label>
            </b-col>
            <b-col sm="8">
              <b-form-textarea id="input-large" rows="3" max-rows="6" size="lg" placeholder="Enter let us know what can we improve" v-model="message"></b-form-textarea>
            </b-col>
          </b-row>
          <b-col sm="12" style="margin-top: 50px;">
            <b-button variant="primary" @click="submitRequest">Submit request</b-button>
          </b-col>
        </b-card>
        <b-modal v-model="modalShow" hide-footer :title="`${request.charAt(0).toUpperCase() + request.slice(1)} Account Likewallet`">
          <div class="d-block text-center">
            <template v-if="btnActionConfirm">
              <h4>
                We will contact you back using the chat channel in LikeWallet to confirm your information and proceed.
              </h4>
            </template>
            <template v-if="btnActionConfirm === false">
              <template v-if="request === 'disable'">
                <h4>
                  Are you sure you want to disable your LikeWallet? This action will prevent you from logging in and using LikeWallet. All of your account data will still be stored on our servers, but you will not be able to access it.
                </h4>
              </template>
              <template v-if="request === 'delete'">
                <h4>
                  Are you sure you want to delete your LikeWallet? This action is irreversible. All of your data, including your LIKE and previlege will be permanently deleted.
                </h4>
              </template>
            </template>
          </div>
          <b-button v-if="btnActionConfirm === false" class="mt-3" variant="primary" block @click="actionConfirm">Confirm</b-button>
          <b-button  v-if="btnActionConfirm === false" class="mt-2" variant="danger" block @click="modalShow = false">Cancel</b-button>
          <b-button  v-if="btnActionConfirm" class="mt-2" variant="danger" block @click="modalShow = false">Close</b-button>
        </b-modal>
      </div>
    </div>
  </div>


</template>

<script>
import axios from "axios";

export default {
  name: 'DeleteUser',
  data() {
    return {
      phoneNumber: "",
      accountName: "",
      request: "",
      message: "",
      modalShow: false,
      btnActionConfirm: false
    }
  },
  props: {
    msg: String
  },
  methods: {
    submitRequest() {
      if(this.phoneNumber === "") {
        alert("Please enter your phone number");
        return;
      } else if (this.accountName === ""){
        alert("Please enter your account name");
        return;
      } else if (this.request === "") {
        alert("Please select request");
        return;
      } else if (this.message === "") {
        alert("Please enter your message");
        return;
      }else  {
        this.modalShow = !this.modalShow
        console.log("submitRequest");
      }
    },
    actionConfirm() {
      this.btnActionConfirm = true;
      this.sendTG();
    },
    sendTG() {
      let message = `${this.request} account Likewallet\nphone number :  ${this.phoneNumber}\naccount name : ${this.accountName}\nmessage : ${this.message}
      @YaYaGangubai @thebestmook
      `;
      axios.post("https://api.telegram.org/bot2061834959:AAEfatFm8fqpPwypxIYylUGRS3d6afbcIYU/sendMessage", {
        "chat_id": "-1001421698064",
        "text": message
      });
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
