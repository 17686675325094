<template>
  <div class="container">
       <b-modal id="bv-modal-loading" centered :hide-footer=true :hide-header=true>
        <p class="my-4" style="text-align: center;"><img src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1624075920logo.png" class="rotate" width="100" height="100" /></p>
        <p style="text-align: center;">{{ messageLoading }}</p>
      </b-modal>    
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import firebase from "firebase/app";

export default {
  data() {
    return {
      token: this.$route.params.token,
      path: this.$route.params.path,
      messageLoading: ''
    }
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    })
  },
  mounted() {
    // this.messageLoading = 'ประกาศปิดปรับปรุง Lock to win ถึงวันศุกร์ที่ 23/06/2021 เวลา 10.00น.';
    this.messageLoading = 'กำลังโหลดข้อมูลโปรดรอ';
    this.$bvModal.show('bv-modal-loading');
    this.initState();

  },  
  methods: {
  initState() {
    // let vm = this.$route.params;
    // console.log(this.token);
    firebase
    .auth()
    .signInWithCustomToken(this.token)
    .then(data => {
      console.log(data);
         this.$bvModal.hide('bv-modal-loading');
      this.$router.replace({ name: this.path });
    })
    .catch(err => {
      console.log(err);
    this.error = err.message;
    });
  }
}  
};
</script>