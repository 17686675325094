<template>
  <div class="container">
    <div>
      <template v-for="row in listBlogs">
        <b-card class="incoming_msg_img" style="padding: 10px" v-bind:key="row.idDoc" :title="row.question">
          <router-link :to="{ path: '/blogs', query: { id: row.idDoc }}">
            อ่านรายละเอียด
          </router-link>
        </b-card>
      </template>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";

export default {
  name: "Blogs",
  data() {
    return {
      listBlogs : []
    }
  },
  mounted() {
    firebase.firestore().collection("likewallet_blog").onSnapshot(async snapshot => {
      this.listBlogs = [];
      await snapshot.docs.forEach(row => {
        let data = row.data();
        data.idDoc = row.id;
        this.listBlogs.push(data);
      });
    });
  }
}
</script>
