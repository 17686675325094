import Client from "../provider/provider";
import abi from "../abi/abi";
import contract from "../contract/contract";
import { ethers } from "ethers";
import config from "../config/configwallet";
// import sleep from "./sleep";

const callWithdraw = function(mnemonic, amount) {
return new Promise((resolve, reject) => {
	
		Client(mnemonic).then(wallet => {
			const contract_call = new ethers.Contract(contract.lbank_addr, abi.abiLBank, wallet);
			
				contract_call.withdraw(amount).then(hash =>{
					console.log(hash);
					const explorerURL = config.explorer+'txs/'+hash.hash; 
					resolve(explorerURL);
				}).catch(e =>{
					console.log(e);

					// sleep(2000).then(loop => {
					// 	console.log(loop);
					// 	return resolve(callWithdraw(mnemonic, amount));
					// });
					reject(e);
				});			
		
		});


});
}

export default callWithdraw