<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <!-- <div class="card-header">Dashboard</div> -->

          <div class="card-body text-center">
            <!-- <div v-if="user" class="alert alert-success" role="alert">{{ user }}</div> -->
			<img alt="Vue logo" src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1603270944icon.png" width="20%">
			<h1>LikeWallet</h1>
            <a v-bind:href="urlLike">  
            <img v-bind:src="imgLike" />
            </a>            
            <a v-bind:href="urliOS">  
            <img v-bind:src="imgiOS" />
            </a>
            <a v-bind:href="urlHuawei">
              <img style="height: 60px;" v-bind:src="imgHuawei" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.center {
	margin: auto;
	width: 50%;
	border: 3px solid green;
	padding: 10px;
}

</style>
<script>
import { mapGetters } from "vuex";
export default {
	data() {
		return {
		urlLike: 'https://play.google.com/store/apps/details?id=likewallet.likewallet&hl=th',
		imgLike: 'https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1603271040icon1603265309google-en.png',
		urliOS: 'https://apps.apple.com/kh/app/likewallet/id1492241404',
		imgiOS: 'https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1603271041icon1603265309apple-en.png',
    urlHuawei: 'https://appgallery.huawei.com/app/C103328775',
    imgHuawei: 'https://linebotkeep-file.s3.ap-southeast-1.amazonaws.com/template_up/24052022-143336-192K1653377615.png'

	}
	},
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    })
  }
};
</script>