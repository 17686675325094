
import axios from 'axios'


const getAllLock = function(address) {
return new Promise((resolve, reject) => {
	axios.post("https://new.likepoint.io/getBalanceFromAddress", {address: address})
		.then(response => {
			if(response.data.status === 200) {
		
				resolve(response.data.lockedBalance);
			}else{
				resolve(0);
			}

			}).catch(e=>{
			console.log(e);
			reject();
	});
});
}

export default getAllLock